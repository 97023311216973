/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Button,
  Image,
  Pressable,
  ActivityIndicator,
  Dimensions
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";
import { Hoverable } from "react-native-web-hover";
import LinearGradient from 'react-native-web-linear-gradient';

//Components
import Background from '../components/general/background.js'
import SEOTitle from '../components/general/seotitle.js'
import BottomNavbar from '../components/navigation/bottomnavbar.js'
import Tabs from '../components/general/tabs.js'

import Scores from './matches/scores.js'

import Match from '../components/matches/match.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      activeView:'Live',
      active_tab:'tour',
      components:{},
      latest:[],
    }
  }

  async componentDidMount(){
    GLOBAL.navigation = this.props.navigation

    //Call API
    var params = {
      mode:'podcasts',
    }

    var data_r = await Functions.tnnsAPI(params)
    var { data } = data_r
    var latest = data?.latest || []

    this.setState({latest, loading:false})

  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  //


  renderContent = () => {
    var { loading } = this.state
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var title = "Tennis Podcasts - Tour analysis, funny moments, tournament previews & recaps - TNNS Live"
    var subtitle = "Latest tennis live scores, results and schedules for Grand Slam, ATP, WTA, Challenger and ITF events."
    return(
      <>
      <SEOTitle title={title}/>
      <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="description" content={subtitle}/>
      </Helmet>
      <Background navigation={this.props.navigation} page="Podcasts"
      title={GLOBAL.displayMode === 'mobile' ? null : 'Latest episodes'}
      // icon={require('../assets/icons/ball.png')}
      >
        {GLOBAL.displayMode === 'mobile' ? this.renderTabs() : null}
        {loading ? this.renderLoading() : this.renderMain()}
      </Background>
      <BottomNavbar/>
      </>
    )
  }

  renderMain = () => {
    var { activeView, latest } = this.state

    return(
      <View style={[{width:'100%', backgroundColor:GLOBAL.style.cardColor, padding:GLOBAL.padding/2, paddingTop:GLOBAL.padding/2}, MainStyles.flexStartCenter]}>

        {latest.map((item, index)=>this.renderArticle(item, index))}
      </View>
    )
  }

  changeMode = (mode) => {
    GLOBAL.navigation.push(mode.id)
  }

  renderTabs = () => {
    var modes = [
      {id:'News', title:'News'},
      {id:'Videos', title:'Videos'},
      {id:'Podcasts', title:'Podcasts'},

    ]
    return <View style={{width:'100%', padding:GLOBAL.padding/2, paddingBottom:0}}><Tabs tabs={modes} onChange={this.changeMode} active_tab={'Podcasts'}/></View>
  }

  renderArticle = (item, index) => {
    var onPress = ()=>window.open(item.url, '_blank')

    var logo = item.image
    // var onPress = ()=>GLOBAL.navigation.push('Tournament', {id:item.id})

    var size = 14

    var aspectRatio = 1

    if(index === -1){
      var width = GLOBAL.column_width - 32
      return(
        <Hoverable style={{width:'100%'}}>
        {({ hovered }) => (
          <Pressable onPress={onPress} style={[{width:'100%', padding:GLOBAL.padding/1.5, marginRight:GLOBAL.padding, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, backgroundColor:hovered ? 'rgba(0,0,0,0.05)' : 'transparent', borderRadius:GLOBAL.borderRadius}, MainStyles.flexStartStart, MainStyles.flexRow]}>

            <Image
            style={{width:'50%', aspectRatio, borderRadius:4, overflow:'hidden', resizeMode:'cover', opacity:1}}
            source={{uri:logo}}
            // source={require('../../assets/icons/news0.png')}
            />

            <View onPress={onPress} style={[{flex:1, paddingVertical:GLOBAL.padding/1.5, paddingBottom:0, marginLeft:0, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, borderRadius:GLOBAL.borderRadius}, MainStyles.flexRow, MainStyles.flexStartStart]}>

              <View style={[{flex:1, paddingHorizontal:GLOBAL.padding}, MainStyles.flexStartStart]}>
                <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:20, textAlign:'left', marginBottom:GLOBAL.padding/4}}>
                {item.title}
                </Text>
                <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:12, flex:1, opacity:0.5, marginTop:GLOBAL.padding/2}} numberOfLines={9}>
                {item?.description}
                </Text>
                <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:12, flex:1, opacity:0.5, marginTop:GLOBAL.padding/2}}>
                {item?.author?.title} · {item?.time}
                </Text>
              </View>

            </View>

          </Pressable>
        )}
        </Hoverable>
      )
    }else{
      return(
        <Hoverable style={{width:'100%'}}>
        {({ hovered }) => (
          <Pressable onPress={onPress} style={[{width:'100%', padding:GLOBAL.padding/1.5, paddingVertical:GLOBAL.padding/1.5, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, backgroundColor:hovered ? 'rgba(0,0,0,0.05)' : 'transparent', borderRadius:GLOBAL.borderRadius}, MainStyles.flexRow, MainStyles.flexStartStart]}>

            {
              !logo ? null :
              <Image
              style={{width:'33%', aspectRatio, borderRadius:4, overflow:'hidden', resizeMode:'cover', opacity:1}}
              source={{uri:logo}}
              // source={require('../../assets/icons/news0.png')}
              />
            }

            <View style={[{flex:1, height:'100%', paddingHorizontal:GLOBAL.padding}, MainStyles.flexStartStart]}>
              <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, lineHeight:22, fontSize:14, textAlign:'left', marginBottom:GLOBAL.padding/4, opacity:1}}>
              {item.title}
              </Text>
              <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:12, flex:1, opacity:0.5, marginTop:GLOBAL.padding/2}} numberOfLines={9}>
              {item?.description}
              </Text>
              <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:12, flex:1, opacity:0.5, marginTop:GLOBAL.padding/2}}>
              {item?.author?.title} · {item?.time}
              </Text>
            </View>

          </Pressable>
        )}
        </Hoverable>
      )
    }
  }

  renderLoading = () => {
    return(
      <View
      style={[{flex:1, width:'100%', padding:GLOBAL.padding}, MainStyles.flexCenter]}
      >
        <ActivityIndicator size='small' color={GLOBAL.style.color_font}/>
      </View>
    )
  }

  render = () => {
    var { loading } = this.state
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
