/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  FlatList,
  Pressable,
  RefreshControl
} from 'react-native';

//Modules

//Components
import Loading from '../../components/general/loading.js'
import MatchList from '../../components/matches/matchlist.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      latest:[],
      loading:true,
      refreshing:false,
      search_term:'',
      active_tab:'streams',
    }
  }

  componentDidMount = async () => {
    clearTimeout(this.refresh_data)
    this.setup()
  }

  componentWillUnmount = async () => {
    clearTimeout(this.refresh_data)
  }

  viewAll = () => {
    GLOBAL.navigation.navigate('Scores')
  }

  refresh = () => {
    this.setState({refreshing:true})
    this.setup()
  }

  changeView = async (data) => {
    var active_tab = data.id
    await this.setState({active_tab, loading:true})
    this.setup()
  }

  setup = async () => {
    clearTimeout(this.refresh_data)
    var { active_tab } = this.state

    var params = {
      mode:'watch'
    }
    var data_r = await Functions.tnnsAPI(params)
    var data = data_r?.data || {}
    var { sections, refresh_time } = data

    this.setState({loading:false, refreshing:false, sections})
    this.refresh_time(refresh_time)
  }

  refresh_time = (refresh_time) => {
    if(refresh_time){
      this.refresh_data = setTimeout(()=>{
        if(!this.state.app_backgrounded){
          this.setup()
        }
      }, refresh_time)
    }
  }

  onAppStateChange = async (appState) => {
    var app_backgrounded = appState === 'background'
    this.setState({appState, app_backgrounded})
    if(appState === 'active'){
      await this.setState({refreshing:true})
      this.setup()
    }
  }

  //

  renderContent = () => {
    var { latest, refreshing } = this.state
    return(
      <MatchList
      data={this.state.sections || []}
      loading={this.state.loading}
      emptyStateButton={{
        title:'View all matches',
        onPress:this.viewAll
      }}
      refreshControl={
        <RefreshControl
          // size={'small'}
          tintColor={GLOBAL.style.color}
          refreshing={this.state.refreshing}
          onRefresh={this.refresh}
        />
      }
      />
    )
  }

  render = () => {
    var { loading } = this.state
    // try{
      return (
        <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
          {loading ? <Loading string='Loading'/> : this.renderContent()}
        </View>
      );
    // }catch(e){
    //   return null
    // }
  }

}
