/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  Image,
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import LinearGradient from 'react-native-web-linear-gradient';

//Components


//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
    }
  }

  async componentDidMount(){
    // GLOBAL.navigation = this.props.navigation
    //Set the theme
    setTimeout(() => {
      this.props.navigation.navigate('Home', {start:true})
    },2000)
  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }


  renderContent = () => {
    return(
      <LinearGradient
      style={[{flex:1, width:'100%', padding:GLOBAL.padding}, MainStyles.flexCenter]}
      colors={GLOBAL.style.gradient}
      start={{x:0,y:0}}
      end={{x:1,y:1}}
      >
        <Image
        style={{width:GLOBAL.displayMode === 'mobile' ? 50 : 76, aspectRatio:76/43, resizeMode:'contain'}}
        source={GLOBAL.darkMode ? require('../images/tennislive.png') : {uri:GLOBAL.logo}}
        />
        <ActivityIndicator size='small' color={GLOBAL.style.color_font}/>
        <Text style={{fontFamily:GLOBAL.fonts.bold, marginTop:GLOBAL.padding/2, fontSize:14}}>
        Loading
        </Text>
      </LinearGradient>
    )
  }


  render() {
    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
