/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  Picker,
  Pressable,
  Image,
  WebView,
  Linking,
  Button,
  Dimensions
} from 'react-native';

//Modules
import {Helmet} from "react-helmet";

//Components
import NavBar from '../components/navigation/navbar.js'
import Background from '../components/general/background.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      id:'test',
      activeView:'Live',
      id:this.props.route?.params?.id || this.props.id,
    }
  }

  async componentDidMount(){

  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  renderContent = () => {
    var title = "TNNS Support"
    return (
      <>
      <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="description" content={"Download the fastest, most comprehensive dedicated tennis live scores app, built by fans for fans."}/>
      </Helmet>
      {this.renderMain()}
      </>
    )
  }

  renderMain = () => {
    var width = Dimensions.get('window').width
    var height = Dimensions.get('window').height
    return(
      <View style={[{flex:1, width:'100%', backgroundColor:GLOBAL.style_dark.backgroundColor}, MainStyles.flexCenter]}>
      <iframe
      src={"https://livestream.com/accounts/5057055/events/"+this.state.id+"/player?width="+width+"&height="+height+"&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false&playsinline=1"}
      width={width}
      height={height} frameborder="0" scrolling="no"
      playsinline
      allow="fullscreen;"
      />
      </View>
    )
  }

  renderButton = (img, url) => {
    return(
      <Pressable onPress={()=>Functions.openURL(url)} style={{width:160, aspectRatio:2, marginHorizontal:10, marginTop:GLOBAL.padding}}>
      <Image style={{height:'100%', width:'100%', resizeMode:'contain'}} source={{uri:img}}/>
      </Pressable>
    )
  }

  renderHighlight = (text, index) => {
    return(
      <Text key={index} style={{marginTop:GLOBAL.padding, fontSize:16, textTransform:'uppercase', letterSpacing:2, fontFamily:GLOBAL.fonts.bold, color:'white'}}>
      {text}
      </Text>
      )
  }

  render() {
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
