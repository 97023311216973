/* @flow */

import React, { PureComponent } from 'react';
import {
  View, ScrollView
} from 'react-native';

//Components
import ScoresBanner from '../components/widgets/scores.js'
import Scores from '../components/widgets/allscores.js'
import Tournaments from '../components/widgets/tournaments.js'

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

import { initAmplitudeInstance, logAmplitudeEvent } from '../AmplitudeUtils';

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      id:this.props.route.params?.id,
      scroll:this.props.route.params?.scroll,
    }
  }

  async componentDidMount(){
    GLOBAL.style = GLOBAL.style_light
    // console.log(this.props.route.params)
    // await initAmplitudeInstance().then(() => {
    //   // alert(this.props.route?.params?.host)
    //   // logAmplitudeEvent('Web - Widget View', {widget:this.props?.route?.params?.id, host:this.props.route?.params?.host})
    // });

    Functions.googleAnalytics('widget_view', {widget:this.props?.route?.params?.id, host:this.props.route?.params?.host})
  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  renderContent = () => {
    var { id, scroll } = this.state
    var render = null
    if(id === 'scores'){render = <ScoresBanner external theme={this.props.route?.params?.host} style={this.props.route?.params?.style}/>}
    if(id === 'allscores'){render = <Scores external theme={this.props.route?.params?.host} style={this.props.route?.params?.style}/>}
    if(id === 'tournaments'){render = <Tournaments external theme={this.props.route?.params?.host} style={this.props.route?.params?.style}/>}
    if(scroll){
      return(
        <ScrollView contentContainerStyle={[{width:'100%', backgroundColor:'white'}, MainStyles.flexCenter]} style={{width:'100%', flex:1, backgroundColor:'white'}}>
        {render}
        </ScrollView>
      )
    }else{
      return(
        <View style={[{flex:1, backgroundColor:'white'}, MainStyles.flexCenter]}>
        {render}
        </View>
      )
    }
  }

  render() {
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
