import * as React from 'react';
import { Helmet } from "react-helmet";

import Main from './Main.js'

export default function WebApp() {
  return <Main/>

  // return (
  //         <div className="application" style={{flex:1}}>
  //             <Helmet>
  //                 <meta charSet="utf-8" />
  //                 <title>TNNS</title>
  //             </Helmet>
  //             <Main/>
  //         </div>
  //     );
}

// function WebApp() {
//   return (
//     <>
//       <Helmet>
//         <title>TNNS</title>
//         <meta name="description" content="" />
//         <meta name="theme-color" content="#008f68" />
//       </Helmet>
//       <Main/>
//     </>
//   );
// }

// export default WebApp;
