/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  Picker,
  Pressable,
  Image,
  WebView,
  Linking,
  Button
} from 'react-native';

//Modules
import {Helmet} from "react-helmet";

//Components
import NavBar from '../components/navigation/navbar.js'
import Background from '../components/general/background.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      id:'test',
      tournament:GLOBAL.tournamentsObj[this.props.route.params?.id] || null,
      activeView:'Live'
    }
  }

  async componentDidMount(){

  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  contact = () => {
    var url = "https://oa4l98lgyn7.typeform.com/to/HDu357lG"
    // Functions.openURL(url)
    window.open(url, "_self")
  }

  renderContent = () => {
    var title = "TNNS Support"
    return (
      <>
      <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="description" content={"Download the fastest, most comprehensive dedicated tennis live scores app, built by fans for fans."}/>
      </Helmet>
      {this.renderMain()}
      </>
    )
  }

  renderMain = () => {
    return(
      <View style={[{flex:1, width:'100%', backgroundColor:GLOBAL.style_dark.backgroundColor}, MainStyles.flexCenter]}>
        <Image
        style={{width:200, aspectRatio:2, resizeMode:'contain', marginBottom:20}}
        source={require('../images/tennislive.png')}
        />
        <Text style={{fontSize:16, fontFamily:GLOBAL.fonts.regular, color:'white', opacity:0.67, maxWidth:400, textAlign:'center'}}>
        We're currently in the process of upgrading to our new website.  The new site will be live in the next 72 hours.
        </Text>

        <Text style={{fontSize:16, fontFamily:GLOBAL.fonts.bold, color:'white', opacity:1, marginTop:64, letterSpacing:2, textTransform:"uppercase"}}>
        Download the TNNS app
        </Text>

        <View style={[MainStyles.flexRow, MainStyles.flexCenter]}>
          {this.renderButton("https://static.wixstatic.com/media/b3cd0e_a3a1c49c6484410e81059376c1ba87cb~mv2.png/v1/fill/w_162,h_50,al_c,q_95/appstorebutton.webp", "https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724")}
          {this.renderButton("https://static.wixstatic.com/media/b3cd0e_d948f00a7a61417d9d759a061b4a7ca9~mv2.png/v1/fill/w_165,h_50,al_c,q_95/googleplay.webp", "https://play.google.com/store/apps/details?id=com.tennisrn")}
        </View>

        <Pressable style={[{marginTop:120, width:200, height:48, borderRadius:4, backgroundColor:'rgba(255,255,255,0.2)'}, MainStyles.flexCenter]} onPress={this.contact}>
          <Text style={{fontSize:16, fontFamily:GLOBAL.fonts.bold, color:'white', opacity:1, letterSpacing:2, textTransform:"uppercase"}}>
          Contact us
          </Text>
        </Pressable>
      </View>
    )
  }

  renderButton = (img, url) => {
    return(
      <Pressable onPress={()=>Functions.openURL(url)} style={{width:160, aspectRatio:2, marginHorizontal:10, marginTop:GLOBAL.padding}}>
        <Image style={{height:'100%', width:'100%', resizeMode:'contain'}} source={{uri:img}}/>
      </Pressable>
    )
  }

  renderHighlight = (text, index) => {
    return(
      <Text key={index} style={{marginTop:GLOBAL.padding, fontSize:16, textTransform:'uppercase', letterSpacing:2, fontFamily:GLOBAL.fonts.bold, color:'white'}}>
      {text}
      </Text>
      )
  }

  render() {
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
