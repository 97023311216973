/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Image,
  Pressable,
  Text,
} from 'react-native';

//Modules
// import CountDown from 'react-native-countdown-component';

//Components
import Icon from '../general/icon.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      data:this.props.data,
    }
  }

  async componentDidMount(){

  }

  async componentDidUpdate(prevProps){
    if(prevProps.data !== this.props.data){
      this.setState({data:this.props.data})
    }
  }

  async componentWillUnmount(){

  }

  open = () => {
    var { data, onPressWatch } = this.props
    if(data.id === 'highlights' && GLOBAL.showVideo && !GLOBAL.external_host){
      GLOBAL.showVideo(data.url)
    }else if(data.id === 'live_stream' && onPressWatch){
      onPressWatch()
    }else{
      Functions.openVideo(data)
    }

  }

  onFinish = () => {
    if(this.props.onFinish){
      this.props.onFinish()
    }
  }

  renderInside = () => {
    var { data } = this.state
    var render = null

    if(data.id === 'highlights'){render = this.renderVideo('play', 'Highlights')}
    else if(data.id === 'live_stream'){render = this.renderVideo('play', 'Watch Live')}
    else if(data.id === 'live_commentary'){render = this.renderVideo('microphone', data.title || 'Live Commentary')}
    // else if(data.id === 'countdown'){render = this.renderCountdown(data.value)}

    return(
      <View style={{paddingHorizontal:GLOBAL.padding / 1.5}}>
      {render}
      </View>
      )
  }

  renderVideo = (icon, string) => {
    return(
      <View style={[MainStyles.flexRow, MainStyles.flexCenter]}>
        <Icon icon={icon} color={GLOBAL.style.streaming} size={10}/>
        <Text style={{fontSize:11, fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.color, marginLeft:GLOBAL.padding / 3, textTransform:'uppercase'}}>
        {this.props.string || string}
        </Text>
      </View>
    )
  }

  renderCountdown = (value) => {
    return(
      <View style={[MainStyles.flexRow, MainStyles.flexCenter]}>
        {
          // <CountDown
          //   until={value}
          //   size={14}
          //   onFinish={this.onFinish}
          //   digitStyle={{backgroundColor: 'transparent', padding:0, width:18}}
          //   digitTxtStyle={{color: 'white', fontFamily:GLOBAL.fonts.bold}}
          //   timeToShow={['H', 'M', 'S']}
          //   timeLabels={{}}
          //   showSeparator
          //   separatorStyle={{color: 'white', opacity:0.5, fontFamily:GLOBAL.fonts.regular, marginTop:-2.5}}
          // />
        }
      </View>
    )
  }

  renderDaily = () => {
    var { data } = this.props
    var icon = null
    if(data.id === 'highlights' || data.id === 'live_stream'){ icon = 'play' }
    else if(data.id === 'live_commentary'){ icon = 'microphone' }
    var size = 28

    return(
      <View style={[{height:size, width:size, borderRadius:size/2, borderWidth:1, borderColor:GLOBAL.style.borderColor, backgroundColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}>
        <Icon icon={icon} color={GLOBAL.style.streaming} size={10}/>
      </View>
    )
  }

  render = () => {
    var { theme } = this.props
    try{
      if(theme === 'match_screen'){
        return(
          <Pressable onPress={this.open} style={[{marginBottom:GLOBAL.padding/2}, MainStyles.flexCenter]}>
            <View
              style={[{height:28, borderRadius:100, borderColor:GLOBAL.style.borderColor, borderWidth:0, overflow:'hidden', backgroundColor:GLOBAL.style.section_subtitle.background}, MainStyles.flexCenter, MainStyles.flexRow]}>
              {this.renderInside()}
            </View>
          </Pressable>
        )
      }else{
        return(
          <Pressable onPress={this.open} style={[{}, MainStyles.flexCenter]}>
            {
              this.props.mode === 'daily' ? this.renderDaily() :
              <View
                style={[{height:28, borderRadius:100, borderColor:GLOBAL.style.borderColor, borderWidth:1, overflow:'hidden'}, MainStyles.flexCenter, MainStyles.flexRow]}>
                {this.renderInside()}
              </View>
            }
          </Pressable>
        )
      }
    }catch(e){
      return null
    }
  }

}
