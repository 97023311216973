import React, { PureComponent } from 'react';

//Modules
import {Helmet} from "react-helmet";

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Alert,
  ActivityIndicator,
  Platform,
  SafeAreaView,
  Pressable,
  Button,
  ScrollView,
  Dimensions
} from 'react-native';

//Modules

// import { firebase } from '@react-native-firebase/auth';
// import firestore from '@react-native-firebase/firestore';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactCountryFlag from "react-country-flag"
import SEOTitle from '../components/general/seotitle.js'
import { initAmplitudeInstance, logAmplitudeEvent } from '../AmplitudeUtils';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

//Components

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

const axios = require("axios");
var moment = require('moment-timezone');
var CircularJSON = require('circular-json')


// const { flag } = require('country-emoji');

var textStyle = {fontFamily:'Roboto-Bold', fontSize:14, color:'white', letterSpacing:-0.4, paddingRight:12}
var hasNumber = /\d/

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

const getItem = (data, index) => ({
  id: Math.random().toString(12).substring(0),
  title: `Item ${index+1}`,
  data: data[index],
});

var whichPlatform = Platform.OS
var isWeb = dimensions.width > 500

const getItemCount = (data) => 50;

var PLAYER_HEIGHT = 36
var PLAYER_WIDTH = 300
var HEADER_HEIGHT = 70

var scale = 1

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      mode: this.props.route?.params?.mode,
      docId: this.props.route?.params?.tournamentId,
      userId_viewing: this.props.route?.params?.userId,
      players_main: [],
      players_qualifiers: [],
      players_featured: [],
      points: [],
      seeds: [],
      seeds_other: [],
      featured:[],
      other:[],
      qualified:[],
      activeIndex:0,
      game:this.props.game || {},
      tabs:[],
      picks:{},
      scale:isWeb ? 1 : 0.2,
      // scale:1,
      scaleSet:true,
    }
  }

  async componentDidMount(){
    this.setup()

    // alert(isWeb)

    //Set scale
    // var scale = 1
    // if(whichPlatform === 'web'){
    //   scale = 1
    // }else{
    //   //Mobile
    //   scale = 0.25
    // }
    // scale = 0.25
    // this.setState({scale})

    await initAmplitudeInstance().then(() => {
      logAmplitudeEvent('Web - Bracket View')
    });
  }

  setup = async () => {
    var { game, userId_viewing } = this.props

    //http://localhost:3001/bracket/jzbuWmPW0KI1yxUx6Tj3/xzRqgLRWA9P0XHmN5YTBqg7pkdJ2

    var fantasy = true
    var { docId, userId_viewing } = this.state

    //Get call API
    var params = {
      docId,
      userId_viewing,
      mode:'getBracket',
    }

    // console.log('Calling bracket', docId, title, id)

    var url = "https://us-central1-tennis-lwts.cloudfunctions.net/fantasy_api"
    var data_response = await this.firestoreAPI(url, params)

    // console.log(data_response)

    var { data, tabs, empty, picksClosed, picks, score, title, subtitle, username, bracket_name } = data_response

    bracket_name = bracket_name.split(", ")[0]

    picksClosed = false

    var activeData = data ? data[0] : null

    var data_display = data.slice(0, data.length - 1)


    await this.setState({tabs, data, data_display, docId, game, activeIndex:0, activeData, picksClosed, score, picks:picks || {}, loading:false, fantasy, title, subtitle, username, bracket_name})
  }

  nextRound = () => {
    var { activeIndex } = this.state
    this.changeRound(activeIndex+1)
  }

  pickPlayer = async (player, bracket_data) => {
    //Update that next round bracket pick
    var { activeIndex, picksClosed } = this.state
    if(picksClosed || player.disablePick){
      console.log('')
    }else{
      var { index_bracket, index_round, string } = player.nextRound
      var id = player.id

      var picks = {...this.state.picks}
      if(!picks[string]){
        picks[string] = {}
      }

      if(id){
        //Set the pick into the user pick object
        picks[string][index_bracket] = id

        //Set the player into the next bracket
        var data = [...this.state.data] || []
        data[index_round].brackets[index_bracket].players = player.players || []
        data[index_round].brackets[index_bracket].id = player.id || null
        data[index_round].brackets[index_bracket].seed = player.seed || null

        //If the other player is picked for future rounds beyond that, remove the pick in later rounds
        var checkNextRound = true

        this.setState({picks, data})
      }

    }


  }

  firestoreAPI = async (url_raw, params) => {
    var data = null

    var language = GLOBAL.language || 'en'

    var tz = await moment.tz.guess(true)
    var url = url_raw + "?=" + tz + "?=" + language + "?=web"

    if(!params){
      params = {}
    }

    params.userId = 'test-user'

    await axios
    .get(url, {params})
    .then(async (response) => {
      //Clean up and remove @ symbols
      var stringifyRaw = CircularJSON.stringify(response)
      var stringify = stringifyRaw.replace(/@/g,"")
      var cleanResponse = CircularJSON.parse(stringify);
      data = cleanResponse.data
      return
    })
    .catch(async (error) => {
      console.log('Axios error', error)
    });

    return data
  }

  changeRound = async (activeIndex) => {
    await this.setState({changingRound:true})
    var { data } = this.state
    var activeData = data[activeIndex]
    await this.setState({activeIndex, activeData, loading:false, changingRound:false})
  }

  handleLayout = async ({nativeEvent}) => {
    var { scaleSet } = this.state
    var { height, width } = nativeEvent.layout

    // console.log(height, width, dimensions.height, dimensions.width)

    var scale_x = dimensions.width / width
    var scale_y = (dimensions.height - HEADER_HEIGHT*2) / (height)

    // if(!scaleSet && !isWeb){
    //   if(scale_x > scale_y){
    //     await this.setState({scale:scale_y, scaleSet:true})
    //   }else{
    //     await this.setState({scale:scale_x, scaleSet:true})
    //   }
    //   console.log(scale_x, scale_y)
    //   console.log('scale set as', this.state.scale)
    // }
  }

  //Draws
  renderDraw = () => {
    var { loading } = this.state
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexStartStart]}>
        {this.renderContent()}
      </View>
    )
    // return null
  }

  renderGameHeader = () => {
    var { tabs, picksClosed } = this.state
    return(
      <>
      <View style={{height:15}}/>
      </>
    )
  }

  renderContent = () => {
    var { empty, activeData, changingRound, loading, data, data_display, scaleSet } = this.state
    if(!empty && activeData){
      return(
        <View style={[{width:'100%', marginLeft:0, opacity:scaleSet || isWeb ? 1 : 0}, MainStyles.flexRow, MainStyles.flexStartStart]} onLayout={this.handleLayout}>
        {data_display.map((item, index) => this.renderBrackets(item, index))}
        </View>
      )
    }else if(loading || changingRound){
      return this.renderLoading()
    }else{
      return this.renderEmptyState()
    }
  }

  renderHeaders = () => {
    var { empty, activeData, changingRound, loading, data, data_display, scaleSet } = this.state
    return(
      <View style={[{width:'100%', paddingTop:5, opacity:scaleSet || isWeb ? 1 : 0}, MainStyles.flexRow, MainStyles.flexStartStart]}>
      {data_display.map((item, index) => this.renderHeader(item, index))}
      </View>
    )


  }

  renderHeader = (data, index2) => {
    return(
      <View key={index2} style={[MainStyles.flexCenterStart, {paddingVertical:this.state.scale*10, paddingLeft:this.state.scale*(index2 === 0 ? 30 : 20), flex:1, borderBottomColor:'rgba(255,255,255,0.08)', borderBottomwidth:this.state.scale*1}]}>
        <Text style={{color:'white', fontFamily:'Roboto-Bold', fontSize:this.state.scale*14, opacity:0.5, textTransform:'uppercase', letterSpacing:this.state.scale*2}}>
        {data.string}
        </Text>
      </View>
    )
  }

  renderBrackets = (data, index2) => {
    var { changingRound } = this.state
    // return(
    //   <ScrollView
    //   directionalLockEnabled={false}
    //   horizontal={true}
    //   contentContainerStyle={[{flex:1}, MainStyles.flexStartCenter]}
    //   style={{flex:1, width:this.state.scale*'100%'}}>
    //     {data.brackets.map((item, index) => this.renderBracket(item, index))}
    //   </ScrollView>
    // )

    return(
      <View
      key={index2}
      style={[{flex:1, backgroundColor:"transparent", marginLeft:0}, MainStyles.flexStartStart]}>
        {index2 > 0 ? <View style={{position:'absolute', top:0, left:0, height:'100%', width:1, borderRightColor:'rgba(255,255,255,0.06)', borderRightwidth:this.state.scale*0, borderStyle: 'dashed'}}/> : null}
        {data.brackets.map((item, index) => this.renderBracket(item, index, index2, index2 > 0 ? data.string_short : null))}
      </View>
    )
  }

  renderBrackets_old = (data) => {
    var { changingRound } = this.state
    return(
      <FlatList
        ListHeaderComponent={this.renderGameHeader}
        data={changingRound ? [] : data.brackets || []}
        style={{width:this.state.scale*'100%', borderTopwidth:1, borderTopColor:'rgba(255,255,255,0.08'}}
        contentContainerStyle={{paddingBottom:30, paddingHorizontal:0, marginTop:0}}
        keyExtractor={(item, index) => index}
        // renderItem={({item, index})=>this.renderBracket(item, index, data?.brackets?.length || 0)}
        renderItem={({item, index})=>this.renderBracket(item, index)}
        ListEmptyComponent={this.renderEmpty}
        // renderSectionHeader={()=>this.renderSearchBar()}
        // stickySectionHeadersEnabled={false}
      />
    )
  }

  renderEmpty = () => {
    return(
      <View style={[{flex:1}, MainStyles.flexCenter]}>
        <ActivityIndicator size="small" color={'white'}/>
      </View>
    )
  }

  renderBracket_old = (item_bracket, index_bracket, length) => {
    var { activeIndex, picksClosed } = this.state
    var isEven = (index_bracket + 1) % 2 === 0
    var isSection = (index_bracket + 1) % 4 === 0
    var marginVertical = activeIndex > 0 && picksClosed ? 25 : 12.5

    var backgroundColor = 'white'
    var opacity = 0.2

    return(
      <View style={[{width:this.state.scale*'100%', paddingLeft:0, marginVertical}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {paddingLeft:activeIndex === 0 ? 10 : 0, height:'100%'}]}>
          {
            activeIndex === 0 ? null :
            <>
            <View style={{width:this.state.scale*10, height:'57%', borderwidth:1, borderColor:backgroundColor, borderLeftwidth:this.state.scale*0, borderRightwidth:this.state.scale*0, opacity}}/>
            </>
          }
        </View>
        <View style={{flex:1}}>
        {item_bracket.map((item, index) => this.renderPlayers(item, index, item_bracket))}
        </View>

        <View style={[MainStyles.flexRow, MainStyles.flexCenter, {paddingRight:length < 2 ? 10 : 0, height:'100%'}]}>
          {
            length < 2 ? null :
            <>
            <View style={{width:this.state.scale*10, height:'57%', borderwidth:1, borderColor:backgroundColor, borderLeftwidth:this.state.scale*0, opacity}}/>
            <View style={{width:this.state.scale*10, height:1, backgroundColor, opacity, marginVertical:-0.5}}/>
            </>
          }
        </View>
      </View>
    )
  }

  renderBracket = (item, index, index_b, round) => {
    var { activeIndex, picksClosed, tabs } = this.state
    var isEven = index % 2 === 0
    var isSection = false
    var marginVertical = activeIndex > 0 && picksClosed ? 25 : 12.5

    var length = 2

    var backgroundColor = '#3A3A3A'
    var opacity = 1

    var spacingLarge = 70

    var marginMajor = !picksClosed || activeIndex === 0 ? 30 : spacingLarge
    var marginMinor = !picksClosed || activeIndex === 0 ? 10 : spacingLarge

    // var marginMajor = picksClosed ? 50 : 30
    // var marginMinor = 10

    var marginTop = isEven && index !== 0 ? marginMajor : marginMinor
    if(index === 0 && activeIndex > 0){
      marginTop = 20
    }

    var spacing_brackets = 0
    var spacing_baseline = 51
    var line_brackets = 0
    if(index_b === 1){ spacing_brackets = spacing_baseline*1 } //1
    if(index_b === 2){ spacing_brackets = spacing_baseline*3; line_brackets = spacing_baseline*1 } //3
    if(index_b === 3){ spacing_brackets = spacing_baseline*7; line_brackets = spacing_baseline*3 } //7
    if(index_b === 4){ spacing_brackets = spacing_baseline*15; line_brackets = spacing_baseline*7 }
    if(index_b === 5){ spacing_brackets = spacing_baseline*31; line_brackets = spacing_baseline*15 }
    if(index_b === 6){ spacing_brackets = spacing_baseline*63; line_brackets = spacing_baseline*31 }

    var bracket_gap = 20 + spacing_brackets + (index === 0 ? 0 : spacing_brackets )

    if(isEven){
      marginTop = bracket_gap
    }else{
      // marginBottom = size
    }

    line_brackets = line_brackets + 28

    var line_width = 20

    return(
      <View style={[{width:this.state.scale*PLAYER_WIDTH, paddingLeft:0, marginTop:this.state.scale*marginTop, marginRight:-1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {
          index_b === 0 ?
          <View style={[{width:this.state.scale*32, paddingRight:this.state.scale*5}, MainStyles.flexCenterEnd]}>
            <Text style={{color:backgroundColor, opacity, fontFamily:'Roboto-Bold', fontSize:this.state.scale*12}}>
            {index+1}
            </Text>
          </View>
          :
          <View style={{width:this.state.scale*line_width, height:1, backgroundColor, opacity}}/>
        }
        <View style={{flex:1}}>
        {this.renderPlayers(item, index, round)}
        </View>

        <View style={{width:this.state.scale*line_width}}/>
        {
          isEven && index_b !== (this.state.data_display.length-1) ?
          <View style={{width:this.state.scale*line_width, height:1, position:'absolute', right:0}}>
            <View style={{width:'100%', height:'100%', backgroundColor, marginTop:(this.state.scale * (PLAYER_HEIGHT + marginMinor) / 2)}}/>
          </View>
          :
          null
        }
        {
          index_b === 0 ? null :
          isEven ?
          <View style={{position:'absolute', bottom:this.state.scale*PLAYER_HEIGHT/2, left:0, height:this.state.scale*line_brackets, width:1, backgroundColor, opacity}}>
          </View>
          :
          <View style={{position:'absolute', top:this.state.scale*PLAYER_HEIGHT/2, left:0, height:this.state.scale*line_brackets, width:1, backgroundColor, opacity}}>
          </View>
        }
      </View>
    )
  }

  renderPlayers = (item_players, index_players, round) => {
    var { activeIndex, picksClosed, picks } = this.state
    var isEven = index_players % 2 === 0
    var marginBottom = activeIndex > 0 && picksClosed ? 20 : 5
    var marginTop = activeIndex > 0 && picksClosed ? 20 : 5

    var players = item_players.players || item_players.picked_players || []

    var nextRound = item_players.nextRound || {}
    var picked_round = (picks[nextRound.string]) || {}
    var picked = (picked_round[nextRound.index_bracket]) === (item_players.players ? item_players.id : item_players.id_picked)

    var hasResult = item_players.correctPick || item_players.wrongPick
    var backgroundColor = 'rgba(255,255,255,0.1)'
    var color = 'white'
    var borderColor = null

    if(item_players.correctPick){
      backgroundColor = "rgba(73,215,130,0.9)"
      color = 'white'
    }

    if(item_players.wrongPick){
      backgroundColor = "rgba(236,17,129,0.9)"
      color = 'white'
    }

    // var backgroundColor = 'rgba(255,255,255,0.1)'

    // var correct = item

    // picksClosed = false

    var renderPick =
    <View style={[{flex:1, width:'100%', position:'absolute', paddingLeft:this.state.scale*15}, isEven ? {top:-this.state.scale*20} : {bottom:-this.state.scale*20}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>
      {
        !item_players?.header ? <View/> :
        <Text style={{fontFamily:'Roboto-Regular', color:'white', fontSize:this.state.scale*12, opacity:0.5}} numberOfLines={1}>
        {item_players?.header || ""}
        </Text>
      }
      {
        !isEven || !round ? null :
        <Text style={{fontFamily:'Roboto-Regular', color:'white', fontSize:this.state.scale*12, opacity:0.5}} numberOfLines={1}>
        {round} {round === 'F' ? null : "#"+Math.ceil((index_players+1) / 2)}
        </Text>
      }
    </View>

    return(
      <View style={[{width:'100%'}, MainStyles.flexCenterStart]}>

        {isEven ? renderPick : null}

        <Pressable key={index_players}
        // onPress={()=>this.pickPlayer(item_players, bracket_data)}
        style={[{width:'100%', paddingHorizontal:this.state.scale*10, backgroundColor, borderColor, borderwidth:this.state.scale*0, borderRadius:this.state.scale*2.5, height:this.state.scale*PLAYER_HEIGHT}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

          <View style={[{flex:1}, MainStyles.flexCenterStart]}>
            {
              players.map((item, index) => this.renderPlayer({item, index}, item_players.opacity || 1, color, picked))
            }
          </View>
          {
            !picked ? null :
            <View style={{paddingVertical:this.state.scale*2.5, paddingHorizontal:this.state.scale*5, backgroundColor:'rgba(0,0,0,0.2)', borderRadius:this.state.scale*2.5, marginLeft:this.state.scale*5}}>
              <Text style={{fontFamily:'Roboto-Regular', color:'white', fontSize:this.state.scale*11, opacity:1}}>
              Picked
              </Text>
            </View>
          }
        </Pressable>
        {
          // !item_players.footer || !picksClosed ? null :
          // <Text style={{color:'white', fontSize:this.state.scale*12, fontFamily:'Roboto-Regular', paddingLeft:15, position:'absolute', bottom:-20}}>
          // {item_players.footer}
          // </Text>
        }

        {!isEven ? renderPick : null}
      </View>
    )
  }

  renderPointsValue = (item, index) => {
    return(
      <View key={index} style={[{flex:1, paddingHorizontal:5}, MainStyles.flexCenter]}>
        <Text style={{fontSize:this.state.scale*24, color:item.color || 'white', fontFamily:item.fontFamily || 'Roboto-Bold', textAlign:'center'}} numberOfLines={1}>
        {item.value}
        </Text>
        <Text style={{fontSize:this.state.scale*12, color:'white', fontFamily:'Roboto-Regular', textAlign:'center', opacity:0.5, marginTop:0}} numberOfLines={1}>
        {item.title}
        </Text>
      </View>
    )
  }

  renderPlayer = ({item, index, small}, opacity, color, picked) => {
    small = true
    if(item){
      var { activeIndex } = this.state
      var flag = item.flag || null
      var width = small ? 16 : 20
      var height = small ? 10 : 12
      var fontSize = small ? 14 : 14
      var marginRight = small ? 7.5 : 10

      if(flag === 'GBR'){flag = 'GB'}
      if(flag === 'TWN'){flag = 'TW'}
      // console.log(flag)

      return(
        <View key={index} style={[{width:'100%'}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
          {
            <View style={{width:this.state.scale*width, marginRight:this.state.scale*marginRight}}>
            {
              !flag ? null :
              // <Flag
              //   id={flag}
              //   width={width}
              //   height={height}
              // />
              <ReactCountryFlag
                countryCode={flag}
                svg
                style={{
                    width:this.state.scale*width,
                    height:this.state.scale*height,
                }}
                title="Flag"
              />
            }
            </View>
          }
          <View style={[{flex:1, flexShrink:1}, MainStyles.flexCenterStart]}>
          <Text style={{fontFamily:'Roboto-Regular', color, opacity:1, flexShrink:1, fontSize:this.state.scale*fontSize,
          width:(PLAYER_WIDTH-(picked ? 150 : 120))*scale
          }} numberOfLines={1} adjustsFontSizeToFit>
          {item.name}
          </Text>
          </View>
        </View>
      )
    }else{return null}
  }

  renderEmptyState = () => {
    return(
      <View style={[{flex:1, width:this.state.scale*'100%', padding:30}, MainStyles.flexCenter]}>
        <Text style={{fontFamily:'Roboto-Black', fontSize:this.state.scale*16, color:'white', textAlign:'center'}}>
        Bracket is not available yet.
        </Text>
        <Text style={{fontFamily:'Roboto-Regular', fontSize:this.state.scale*14, color:'white', textAlign:'center', marginTop:10}}>
        Check back soon to make your first picks
        </Text>
      </View>
    )
  }

  renderLoading = () => {
    return(
      <View style={[{flex:1, width:'100%'}, MainStyles.flexCenter]}>
        <ActivityIndicator size='small' color={'white'}/>
      </View>
    )
  }

  renderHeader_title = () => {
    var { game, title, subtitle } = this.state
    var width = 60
    return(
      <View style={[{width:this.state.scale*'100%', borderBottomColor:'rgba(255,255,255,0.08', borderBottomwidth:this.state.scale*0, paddingHorizontal:15, paddingVertical:15, marginTop:15}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

      <View style={[MainStyles.flexCenter]}>
        <Text style={{color:'white', fontFamily:'Roboto-Black', fontSize:this.state.scale*48}}>
        TNNS
        </Text>
        <Text style={{color:GLOBAL.colors.volt, fontFamily:'Roboto-Bold', fontSize:this.state.scale*24, marginTop:-5}}>
        FANTASY
        </Text>
      </View>

      <View style={[MainStyles.flexCenter, {marginLeft:60}]}>
        <Text style={{color:'white', fontFamily:'Roboto-Bold', fontSize:this.state.scale*16}}>
        JOIN THE GAME
        </Text>
        <Text style={{color:'white', fontFamily:'Roboto-Bold', fontSize:this.state.scale*24, marginTop:-5}}>
        TNNSLIVE.COM
        </Text>
      </View>

      </View>
    )
  }

  renderBase = () => {
    var { picksClosed, activeIndex, tabs } = this.state
    return(
      <View style={{width:this.state.scale*'100%'}}>
        <View style={{width:this.state.scale*'100%', paddingTop:15, paddingBottom:30, paddingHorizontal:15, borderTopColor:'rgba(255,255,255,0.08', borderTopwidth:this.state.scale*1}}>
          {
            picksClosed ? null : <Button title='Save Picks' loading={this.state.submittingPicks} onPress={this.savePicks}/>
          }
          {
            activeIndex < (tabs.length-1) ? <Button title='Next Round' onPress={this.nextRound} style={{backgroundColor:'rgba(255,255,255,0.08', marginTop:picksClosed ? 0 : 15}}/> : null
          }
        </View>
        <SafeAreaView/>
      </View>
    )
  }

  renderTNNSHeader = () => {
    var { game, title, subtitle, bracket_name } = this.state
    // if(!game){
      return(
        <>
        <Pressable onPress={()=>this.props.navigation.navigate('Home')} style={[{width:'100%', backgroundColor:'rgba(0,0,0,0.5)', borderBottomColor:'rgba(255,255,255,0.05)', borderBottomWidth:1, paddingHorizontal:15, paddingBottom:15, height:HEADER_HEIGHT, marginTop:0}, MainStyles.flexRow, MainStyles.flexBetweenEnd]}>

          <View style={[MainStyles.flexCenter, {marginLeft:!isWeb ? 0 : 10}]}>
            <Text style={{color:'white', fontFamily:'Averta-Black', fontSize:(isWeb ? 30 : 20)}}>
            TNNS
            </Text>
            <Text style={{color:GLOBAL.colors.volt, fontFamily:'Averta-Bold', fontSize:isWeb ? 16 : 14, marginTop:-5}}>
            FANTASY
            </Text>
          </View>

          <View style={[MainStyles.flexCenterEnd, {marginLeft:0, marginBottom:0}]}>

            <Text style={{color:'white', fontFamily:'Roboto-Regular', fontSize:isWeb ? 16 : 14, marginBottom:this.state.scale*2}}>
            {subtitle}
            </Text>
            <Text style={{color:'white', fontFamily:'Roboto-Regular', fontSize:isWeb ? 14 : 12, opacity:0.5}}>
            {bracket_name || ""}
            </Text>
          </View>

        </Pressable>
        {
          isWeb ? null :
          <View style={[{width:'100%', backgroundColor:'rgba(0,0,0,0.5)', borderBottomColor:'rgba(255,255,255,0.05)', borderBottomWidth:1, paddingHorizontal:15, paddingVertical:5}, MainStyles.flexRow, MainStyles.flexBetweenEnd]}>
            <Text style={{color:'white', fontFamily:'Roboto-Regular', fontSize:isWeb ? 14 : 12, opacity:1}}>
            Pinch and zoom to move around the draw
            </Text>
          </View>
        }
        </>
      )
    // }else{return null}
  }

  render() {
    var { loading, subtitle } = this.state
    try{
    //   if(loading){
    //     return(
    //       <View style={[{flex:1, backgroundColor:'#121212'}, MainStyles.flexCenter]}>
    //         <ActivityIndicator size='small' color='white'/>
    //         <Text style={{color:'white', fontSize:14, fontFamily:'Roboto-Bold', marginTop:10}}>
    //         Loading bracket
    //         </Text>
    //       </View>
    //     )
    //   }else{
    //     // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    //     var title = "Fantasy TNNS Bracket" + (subtitle ? " - " + subtitle : '')
    //     var subtitle = "Latest tennis live scores, fantasy games, results and schedules for Grand Slam, ATP, WTA, Challenger and ITF events."
    //     return(
    //     <>
    //       <SEOTitle title={title}/>
    //       <Helmet>
    //       <title>{title}</title>
    //       <meta property="og:title" content={title} />
    //       <meta property="description" content={subtitle}/>
    //       </Helmet>
    //       <View style={[{flex:1, width:'100%', backgroundColor:'#121212'}, MainStyles.flexStartStart]}>
    //         <View style={{flex:1, width:'100%', marginLeft:0}}>
    //           {this.renderTNNSHeader()}
    //           <ScrollView style={{flex:1, width:'100%'}} contentContainerStyle={{height:'100%', marginLeft:!isWeb ? -15 : -20}} horizontal={true}>}>
    //             <View style={[MainStyles.flexStartStart, {flex:1, width:this.state.scale*"100%"}]}>
    //
    //               {
    //                 this.renderHeaders()
    //               }
    //               <ScrollView style={{flex:1, width:'100%'}} contentContainerStyle={[{width:'100%', paddingBottom:60}, MainStyles.flexStartStart]}>
    //               {this.renderDraw()}
    //               </ScrollView>
    //             </View>
    //           </ScrollView>
    //         </View>
    //       </View>
    //       </>
    //     )
    //   }
    // }catch(e){
    //   console.log(e)
    //   return null
    // }

    if(loading){
      return(
        <View style={[{flex:1, backgroundColor:'#121212'}, MainStyles.flexCenter]}>
          <ActivityIndicator size='small' color='white'/>
          <Text style={{color:'white', fontSize:14, fontFamily:'Roboto-Bold', marginTop:10}}>
          Loading bracket
          </Text>
        </View>
      )
    }else if(isWeb){
      var title = "Fantasy TNNS Bracket" + (subtitle ? " - " + subtitle : '')
      var subtitle = "Latest tennis live scores, fantasy games, results and schedules for Grand Slam, ATP, WTA, Challenger and ITF events."
      return(
      <>
        <SEOTitle title={title}/>
        <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="description" content={subtitle}/>
        </Helmet>
        <View style={[{flex:1, width:'100%', backgroundColor:'#121212'}, MainStyles.flexStartStart]}>
          <View style={{flex:1, width:'100%', marginLeft:0}}>
            {this.renderTNNSHeader()}
            <ScrollView style={{flex:1, width:'100%'}} contentContainerStyle={{height:'100%', marginLeft:!isWeb ? -15 : -20}} horizontal={true}>}>
              <View style={[MainStyles.flexStartStart, {flex:1, width:this.state.scale*"100%"}]}>

                {this.renderHeaders()}
                <ScrollView style={{flex:1, width:'100%'}} contentContainerStyle={[{width:'100%', paddingBottom:60}, MainStyles.flexStartStart]}>
                {this.renderDraw()}
                </ScrollView>
              </View>
            </ScrollView>
          </View>
        </View>
        </>
      )
      // return(
      // <>
      //   <SEOTitle title={title}/>
      //   <Helmet>
      //   <title>{title}</title>
      //   <meta property="og:title" content={title} />
      //   <meta property="description" content={subtitle}/>
      //   </Helmet>
      //   <View minimumZoomScale={1} maximumZoomScale={1} style={{flex:1, width:'100%', backgroundColor:'#121212' }} contentContainerStyle={[{width:'100%'}, MainStyles.flexStartStart]}>
      //     <View style={{width:'100%', marginLeft:0}}>
      //       {this.renderTNNSHeader()}
      //       <VIew minimumZoomScale={1} maximumZoomScale={1} style={{flex:1, width:'100%'}} contentContainerStyle={{height:'100%', marginLeft:!isWeb ? -15 : -20}} horizontal={true}>}>
      //         <View style={[MainStyles.flexStartStart, {flex:1, width:this.state.scale*"100%"}]}>
      //
      //           {
      //             this.renderHeaders()
      //           }
      //           <View style={[{width:'100%', paddingBottom:60}, MainStyles.flexStartStart]}>
      //           {this.renderDraw()}
      //           </View>
      //         </View>
      //       </View>
      //     </View>
      //   </View>
        // </TransformComponent>
        // </TransformWrapper>
      //   </>
      // )
    }else{
      // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
      var title = "Fantasy TNNS Bracket" + (subtitle ? " - " + subtitle : '')
      var subtitle = "Latest tennis live scores, fantasy games, results and schedules for Grand Slam, ATP, WTA, Challenger and ITF events."
      return(
      <>
        <SEOTitle title={title}/>
        <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="description" content={subtitle}/>
        </Helmet>
        <View style={[{flex:1, width:'100%', backgroundColor:'#121212'}, MainStyles.flexStartStart]}>
        {this.renderTNNSHeader()}
        <TransformWrapper
        minScale={0.1}
        initialScale={1/(this.state.scale * 2)}
        initialPositionX={0}
        initialPositionY={0}
        minPositionX={0}
        maxPositionX={1000}
        >
        <TransformComponent>
        <View style={{flex:1, width:'100%', marginLeft:0}}>

          <View style={{height:'100%', marginLeft:0}} horizontal={true}>
            <View style={[MainStyles.flexStartStart, {flex:1, width:"100%"}]}>
            {this.renderHeaders()}
            {this.renderDraw()}
            </View>
          </View>
        </View>
        </TransformComponent>
        </TransformWrapper>
        </View>
        </>
      )
    }
  }catch(e){
    console.log(e)
    return null
  }
  }

}
