/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  Picker,
  Pressable,
  Image,
  ScrollView,
} from 'react-native';

//Modules
import {Helmet} from "react-helmet";

//Components
import NavBar from '../components/navigation/navbar.js'
import Background from '../components/general/background.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      id:'test',
      tournament:GLOBAL.tournamentsObj[this.props.route.params?.id] || null,
      activeView:'Live'
    }
  }

  async componentDidMount(){
    this.openStore()
    // GLOBAL.navigation = this.props.navigation
    var id = null
    if(this.props.route.params){
      id = this.props.route.params.id
      // this.setup(id)
    }
    // this.setState({id, loading:false})
  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  openStore = () => {
    if(isMobile && isIOS){
      console.log('Sending user to App Store')
      //Send user to the app store
      Functions.openURL("https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724")
    }else if(isMobile && isAndroid){
      console.log('Sending user to Play Store')
      //Send user to the app store
      Functions.openURL("https://play.google.com/store/apps/details?id=com.tennisrn")
    }
  }

  setup = async (id) => {
    //Get tournament info
    var url = "https://us-central1-tennis-lwts.cloudfunctions.net/api_getTournament?="+id
    var data = await Functions.firestoreAPI(url)
    data.tournament_data.id = data.tournament_data.docId
    this.setState({tournament:data, tournament_data:data?.tournament_data})
  }

  getRankings = async (rankings) => {
    //
    this.setState({rankings})
  }

  onEmpty = () => {
    var { tournament_data, firstSetDone } = this.state

    if(!firstSetDone){
      var activeView = 'Live'
      if(tournament_data.completedDates.length === 0){
        activeView = 'Scheduled'
      }else{
        activeView = 'Completed'
      }
      this.setState({activeView, firstSetDone:true})
    }

  }

  pickMatch = async (match) => {
    await this.setState({match:match || null})
  }

  renderContent = () => {
    var title = "TNNS Fantasy: Rules"
    return (
      <>
        <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="description" content={"Download the fastest, most comprehensive dedicated tennis live scores app, built by fans for fans."}/>
        </Helmet>
        {this.renderMain()}
      </>
    )
  }

  renderMain = () => {
    return(
      <ScrollView contentContainerStyle={[{width:'100%', padding:15}, MainStyles.flexStartStart]} style={[{flex:1, width:'100%', backgroundColor:'white'}]}>
        <Text style={{fontFamily:'Averta-Black', color:'black', fontSize:24, textAlign:'left'}}>
        Fantasy TNNS
        </Text>
        <Text style={{fontFamily:'Averta-Semibold', color:'black', fontSize:18, opacity:0.5, marginBottom:15, textAlign:'left'}}>
        How it works
        </Text>

        <Text style={{fontFamily:'Averta-Regular', color:'black', fontSize:18, opacity:1, marginBottom:15, textAlign:'left'}}>
        Welcome to the 2022 TNNS Fantasy Challenge!
        {"\n"}{"\n"}
        How do brackets work? From the draw of an upcoming tournament, select the players you think will win each match, starting from the first round and going all the way through to the final. You'll get points for each match you pick correctly, with the points increasing during each round of the tournament.
        {"\n"}{"\n"}
        Tournament predictions can be made in the time between the earliest draw availability (i.e. when the main draw gets released) and the first scheduled main draw match of the tournament.  Once a tournament is available to play, it'll show up on the Fantasy screen in the TNNS app under the "Make Your Picks" section - tap on it to open the bracket and make your predictions.
        {"\n"}{"\n"}
        Predictions need to be made for all rounds and be saved before the first main draw match starts.  Where possible, we'll show you on the Fantasy page when picks close for each tournament in your timezone.
        {"\n"}{"\n"}
        When you see a 'Qualifer' bracket, it means that the qualification rounds are still in progress and the player to fill that spot is yet to be confirmed.  As soon as a qualifier has made it through to the main draw, their name will be added to the bracket and be available to pick.  Because of this, we always recommend you double check your picks again just before the first main draw match starts, to ensure that you've got all the latest players and info for your picks.
        {"\n"}{"\n"}
        Once the tournament starts, picks will close.  Your bracket will continuously update throughout the tournament to highlight which picks you got right.  Leaderboards also activate to show your score versus other fantasy players.  If you join a league, that league will also have it's own leaderboard for the bracket that only includes members of that league.
        {"\n"}{"\n"}
        Points are allocated as below for each correct pick in the following rounds:
        {"\n"}{"\n"}
        Round of 128 - 1 point{"\n"}
        Round of 64 - 1 point{"\n"}
        Round of 32 - 2 points{"\n"}
        Round of 16 - 4 points{"\n"}
        Quarterfinal - 8 points{"\n"}
        Semifinal - 12 points{"\n"}
        Final - 16 points
        {"\n"}{"\n"}
        Cash prizes are sometimes available for bracket competitions.  The full prize is awarded to the user who has the highest points once the competition is complete.  In the event that more than one user is tied for first place, the full prize is shared evenly amongst all of the users tied for first place.  Cash prizes are paid out via PayPal and winning users should reach out to the TNNS team at support@downloadtennis.com to claim their prize.
        {"\n"}{"\n"}
        Contact the team at support@downloadtennis.com anytime if you have any questions, and good luck!
        </Text>
      </ScrollView>
    )
  }

  renderButton = (img, url) => {
    return(
      <Pressable onPress={()=>Functions.openURL(url)} style={{width:160, aspectRatio:2, marginHorizontal:10, marginTop:GLOBAL.padding}}>
        <Image style={{height:'100%', width:'100%', resizeMode:'contain'}} source={{uri:img}}/>
      </Pressable>
    )
  }

  renderHighlight = (text, index) => {
    return(
      <Text key={index} style={{marginTop:GLOBAL.padding, fontSize:16, textTransform:'uppercase', letterSpacing:2, fontFamily:GLOBAL.fonts.bold, color:'white'}}>
      {text}
      </Text>
      )
  }

  render() {
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
