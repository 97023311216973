/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Pressable,
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";

//Components
import Background from '../components/general/background.js'
import Button from '../components/general/button.js'
import SEOTitle from '../components/general/seotitle.js'
import SectionHeader from '../components/general/sectionheader.js'
import BottomNavbar from '../components/navigation/bottomnavbar.js'
import Watch from './matches/watch.js'
import Scores from './matches/scores.js'
import Tournaments from './matches/tournaments.js'
import Header from '../components/seo/header.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      activeView:'watch',
      title:"Tennis Live Streams - Stream tennis free online for ATP Challenger & ITF events",
      description:'Tennis live streams. Stream ATP Challenger & ITF tennis matches for free. Tennis 2022 streams live for free.',
    }
  }

  async componentDidMount(){
    this.props.navigation.setOptions({title:this.state.title})
  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  openTips = () => {
    GLOBAL.navigation.push('HowToStream')
  }

  changeTab = (item) => {
    this.setState({activeView:item.id})
  }


  renderContent = () => {
    var { activeView, title, description } = this.state
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    return(
      <>
      <Header title={this.state.title} description={this.state.description}/>
      <Background navigation={this.props.navigation} column={this.renderColumn()} title="Free Streams" icon={require('../assets/icons/tv.png')} page="Streams" hideColumn={activeView !== 'watch'}>
      {this.renderMain()}
      </Background>
      <BottomNavbar/>
      </>
    )
  }

  renderMain = () => {
    var { activeView } = this.state

    var tabs = [
      {id:'watch', title:'Free Streams'},
      {id:'how_to_watch_atp', title:'Watch ATP'},
      {id:'how_to_watch_wta', title:'Watch WTA'},
    ]

    var render = null
    if(activeView === 'watch'){render = <Watch/>}

    return(
      <View style={[{width:'100%'}, MainStyles.flexStartCenter]}>

        <View style={[{width:'100%', marginTop:-GLOBAL.padding}, MainStyles.flexStartStart]}>
          {render}
        </View>
      </View>
    )
  }

  renderColumn = () => {
    return(
      <Pressable style={{width:'100%', maxWidth:GLOBAL.maxWidth, padding:GLOBAL.padding}}>
        <Text style={{width:'100%', textAlign:'left', fontSize:16, fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.color, marginBottom:GLOBAL.padding/2}}>
        Wanting to stream ATP & WTA tennis matches?
        </Text>
        <Text style={{width:'100%', textAlign:'left', fontSize:14, fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, marginBottom:GLOBAL.padding}}>
        Check out our tips on where and how to watch ATP & WTA matches, including low-cost options and tennis providers in your country.
        </Text>

        <Button title="See how to stream ATP & WTA matches" onPress={this.openTips}/>
      </Pressable>
    )
  }



  renderTab = (item, index) => {
    var active = this.state.activeView === item.id
    return(
      <Pressable onPress={()=>this.changeTab(item)} style={[{paddingVertical:GLOBAL.padding/2, paddingHorizontal:GLOBAL.padding * 2, backgroundColor:active ? '#2B2B2B' : GLOBAL.style.cardColor, borderRightWidth:1, borderRightColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}>
        <Text style={{fontSize:14, fontFamily:GLOBAL.fonts.medium, opacity:active ? 1 : 1, color:active ? "white" : GLOBAL.style.color, textTransform:'none'}}>
        {item.title}
        </Text>
      </Pressable>
    )
    // return(
    //   <Pressable onPress={()=>this.changeTab(item)}>
    //     <View style={[{height:48, paddingHorizontal:GLOBAL.padding*1}, MainStyles.flexCenter, MainStyles.flexRow]}>
    //       <Text style={{fontSize:15, fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.cardColor}}>
    //       {item.title}
    //       </Text>
    //       {
    //         !active ? null :
    //         <View style={{width:'100%', height:2, backgroundColor:GLOBAL.style.cardColor, position:'absolute', bottom:0}}/>
    //       }
    //     </View>
    //   </Pressable>
    // )
  }

  render = () => {
    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
