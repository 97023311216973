/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Pressable,
  Image,
  SectionList,
} from 'react-native';

//Modules

//Components
import MatchList from '../../components/matches/matchlist.js'
import Tabs from '../../components/general/tabs.js'
import Loading from '../../components/general/loading.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      sections:[],
      loading:true,
      refreshing:true,
      format:'all',
      tabs:this.props.players || [],
    }
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {

  }

  changeTab = (tab) => {
    this.setState({refreshing:true})
    var id = tab.id
    this.setState({active_tab:id})
    this.setup(id)
  }

  setup = async (id) => {
    var { players } = this.props

    var params = {}

    if(id){
      params.id = id
    }else{
      params.id = players[0].id
    }
    params.mode = 'form'

    if(!params.id.includes('sr')){
      params.id = "sr:competitor:"+params.id
    }

    //Fetch from API
    var data_r = await Functions.tnnsAPI(params)
    var data = data_r?.data || {}
    var { sections } = data
    await this.setState({sections, active_tab:params.id, loading:false, refreshing:false})
    // await Functions.updateCache(cache_key, data)
  }

  renderTabs = () => {
    var { loading, tabs } = this.state
    return(
      !loading && tabs?.length > 0 ?
      <View style={{width:'100%'}}>
        <Tabs tabs={tabs} onChange={this.changeTab} active_tab={this.state.active_tab} secondary/>
      </View>
      :
      null
    )
  }

  renderContent = () => {
    var sections = this.state.sections || []
    return (
      <View animation={'fadeIn'} duration={350} style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
        {this.props.hideTabs ? null : this.renderTabs()}
        <MatchList
        data={sections}
        loading={this.state.loading || this.state.refreshing}
        refreshing={this.state.refreshing}
        />
      </View>
    );
  }

  renderLoading = () => {
    return <Loading string="Loading form"/>
  }

  render = () => {
    var { loading } = this.state

    return (
      <View style={[{flex:1, width:'100%', minHeight:300}, MainStyles.flexStartCenter]}>
        {loading ? this.renderLoading() : this.renderContent()}
      </View>
    );
  }

}
