/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
} from 'react-native';

//Modules

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      title:this.props.title,
    }
  }

  async componentDidMount(){

  }

  async componentDidUpdate(prevProps){
    if(prevProps.title !== this.props.title){
      this.setState({title:this.props.title})
    }
  }

  async componentWillUnmount(){

  }


  renderContent = () => {
    return(
      <View style={[{position:'absolute', opacity:0}, MainStyles.flexCenterStart]}>
        <h1>
        {this.state.title}
        </h1>
      </View>
    )
  }


  render() {
    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
