/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  FlatList,
  Pressable,
  SectionList,
  Dimensions,
  ImageBackground,
} from 'react-native';

//Modules

//Components
import SectionHeader from '../../components/general/sectionheader.js'
import Icon from '../../components/general/icon.js'
import MatchList from '../../components/matches/matchlist.js'
import LiveStreamVideo from '../../components/media/livestreamplayer.js'

import Stream from '../stream.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      latest:[],
      loading:true,
      data:this.props.data || {},
    }
  }

  componentDidMount = async () => {
  }

  componentDidUpdate = async (prevProps) => {
    if(prevProps.data !== this.props.data){
      this.setState({data:this.props.data || {}})
    }
  }

  componentWillUnmount = async () => {

  }

  setup = async () => {

  }


  renderContent = () => {
    var { data } = this.state
    var screen_width = Dimensions.get('window').width > GLOBAL.maxWidth ? GLOBAL.maxWidth : Dimensions.get('window').width
    var width = (this.props.width) || (GLOBAL.displayMode === 'web' ? (screen_width - GLOBAL.column_width - (GLOBAL.padding * 2)) : Dimensions.get('window').width)
    var height = width / 1920 * 1080
    var size = 60
    return(
      <>
      <View style={{aspectRatio:1920/1080, width:'100%', backgroundColor:GLOBAL.style.borderColor, overflow:'hidden'}}>
        {
          data.id ?
          <iframe
          src={"https://livestream.com/accounts/5057055/events/"+data.id+"/player?width="+width+"&height="+height+"&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false&playsinline=1"}
          width={'100%'}
          height={height} frameborder="0" scrolling="no"
          playsinline
          allow="fullscreen;"
          />
          :
          <Pressable onPress={()=>Functions.openURL(data.url)}>
          <ImageBackground
          style={[{aspectRatio:1920/1080, width:'100%'}, MainStyles.flexCenter]}
          source={{uri:data?.thumbnail}}
          >
          <View style={[{height:size, width:size, borderRadius:100, backgroundColor:'rgba(0,0,0,0.75)'}, MainStyles.flexCenter]}>
            <Icon icon='play' color='white' size={size/2.5}/>
          </View>
          </ImageBackground>
          </Pressable>
        }
      </View>
      {this.renderInfo()}
      </>
    )
  }

  renderThumbnail = () => {
    var { data } = this.state
    var width = GLOBAL.dimensions.width
    var height = width / 1920 * 1080
    var thumbnail = data?.thumbnail
    var size = 60
    return(
      <>
      {
        !thumbnail ? null :
        <Pressable style={{width:'100%', marginTop:GLOBAL.padding / 2}}>
          <Text style={{color:'white'}}>
          Watch the match live
          </Text>
        </Pressable>
      }
      </>
      )
  }

  renderInfo = () => {
    var { data } = this.state
    var info = data?.info || []
    return(
      <View style={{width:'100%', flex:1}} animation='fadeIn' duration={240}>
        <SectionList
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:32}}
          sections={info || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          renderSectionHeader={this.renderSectionHeader}
          initialNumToRender={20}
        />
      </View>
    )
  }

  renderItem = ({item, index}) => {
    var backgroundColor = index%2 ? GLOBAL.style.background_alternating : 'transparent'

    var size = 48
    var scale = 1
    var height = 90

    var icon = item.icon
    var ratio = item.icon === 'play' ? 0.8 : 1

    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding/1.5, backgroundColor:'transparent', borderTopColor:GLOBAL.style.borderColor, borderTopWidth:index > 0 ? 1 : 0}, MainStyles.flexRow, MainStyles.flexStartCenter]}>

        <View style={[{height:36, width:36, borderRadius:100, borderColor:GLOBAL.style.borderColor, borderWidth:1}, MainStyles.flexCenter]}>
        <Icon icon={icon} ratio={ratio} size={18}/>
        </View>

        <View style={[{flex:1, paddingLeft:GLOBAL.padding/1.5}, MainStyles.flexCenterStart]}>
          <Text style={{fontFamily:GLOBAL.fonts.bold, fontSize:12, color:GLOBAL.style.color, textAlign:'left'}}>
          {item.title}
          </Text>

          <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:14, color:GLOBAL.style.color_secondary, textAlign:'left', marginTop:2}}>
          {item.subtitle}
          </Text>
        </View>

      </View>
    )
  }

  renderSectionHeader = ({section}) => {
    var { title } = section
    return(
      <SectionHeader title={title} />
    )
  }

  render = () => {
    var { loading } = this.state
    try{
      return (
        <View animation='fadeIn' duration={240} style={[{flex:1, width:'100%', backgroundColor:GLOBAL.style.backgroundColor, minHeight:400}, MainStyles.flexStartCenter]}>
          {this.renderContent()}
        </View>
      );
    }catch(e){
      return null
    }
  }

}
