/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  SectionList,
  Pressable,
  Image
} from 'react-native';

//Modules
import { Hoverable } from "react-native-web-hover";

//Components
import SectionHeader from '../../components/general/sectionheader.js'
import Icon from '../../components/general/icon.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      sections:[
        {
          title:'Official Singles',
          data:[
            {id:'1', title:'ATP Singles', active:true, tour:'atp', type:'official', format:'singles'},
            {id:'2', title:'ATP Singles Race to Turin', active:true, tour:'atp', type:'race', format:'singles'},
            {id:'3', title:'WTA Singles', active:true, tour:'wta', type:'official', format:'singles'},
            {id:'4', title:'WTA Singles Race', active:true, tour:'wta', type:'race', format:'singles'},
          ]
        },
        {
          title:'Official Doubles',
          data:[
            {id:'5', title:'ATP Doubles', active:true, tour:'atp', type:'official', format:'doubles'},
            {id:'6', title:'ATP Doubles Race to Turin', active:true, tour:'atp', type:'race', format:'doubles'},
            {id:'7', title:'WTA Doubles', active:true, tour:'wta', type:'official', format:'doubles'},
            {id:'8', title:'WTA Doubles Race', active:true, tour:'wta', type:'race', format:'doubles'},
          ]
        }
      ]
    }
  }

  componentDidMount = async () => {

  }

  componentWillUnmount = async () => {

  }

  //
  renderContent = () => {
    var { sections } = this.state
    return(
      <View style={{width:'100%'}}>
        <SectionList
          style={{width:'100%'}}
          contentContainerStyle={{width:'100%', paddingBottom:0}}
          sections={sections || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          renderSectionHeader={this.renderSectionHeader}
          initialNumToRender={20}
        />
      </View>
    )
  }

  renderItem = ({item, index}) => {
    var backgroundColor = index%2 ? GLOBAL.style.background_alternating : 'transparent'

    var size = 48
    var scale = 1
    var height = 90
    var onPress = item.ltr ? ()=>Functions.openURL(item.link) : ()=>GLOBAL.navigation.push('Ranking', {tour:item.tour, format:item.format, type:item.type, title:item.title})

    return(
      <Hoverable>
      {({ hovered }) => (
        <Pressable onPress={onPress} style={[{width:'100%', paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding/1, backgroundColor:hovered ? GLOBAL.style.hover : 'transparent', borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

          <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:14, color:GLOBAL.style.color}}>
          {item.title}
          </Text>


          <View style={[MainStyles.flexRow, MainStyles.flexCenter]}>
            {
              !item.ltr ? null :
              <Image
              style={[{height:24, width:24, resizetour:'contain',position:'absolute', right:GLOBAL.padding*1.5}]}
              source={{uri:"https://live-tennis.eu/ltr_logo.png"}}
              />
            }
            <Icon icon="chevron-forward" opacity={0.5} size={14}/>
          </View>

        </Pressable>
      )}
      </Hoverable>

    )
  }

  renderSectionHeader = ({section}) => {
    var { title } = section
    return(
      <SectionHeader title={title} />
    )
  }

  render = () => {
    try{
      return (
        <View style={[{flex:1, width:'100%', padding:GLOBAL.isMobile ? 0 : 0}, MainStyles.flexStartCenter]}>
          {this.renderContent()}
        </View>
      );
    }catch(e){
      return null
    }
  }

}
