/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  SectionList,
  Pressable,
  Image,
} from 'react-native';

//Modules

//Components
import SectionHeader from '../../components/general/sectionheader.js'
import Loading from '../../components/general/loading.js'
import Tabs from '../../components/general/tabs.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      sections:this.props.data || [],
    }
  }

  componentDidMount = async () => {
    if(this.props.tabbed){
      this.setupTabbed()
    }
  }

  componentDidUpdate = async (prevProps) => {
    if(prevProps.data !== this.props.data){
      await this.setState({sections:this.props.data || []})
      if(this.props.tabbed){
        this.setupTabbed()
      }else{
        this.setState({tabs:[]})
      }
    }
  }

  componentWillUnmount = async () => {

  }

  setupTabbed = (active_tab) => {
    var { tabbed } = this.props

    var tabs = tabbed.tabs || []
    var data = tabbed.data || {}

    if(!active_tab){
      active_tab = tabs[0].id
    }

    var sections = data[active_tab] || []

    this.setState({sections, active_tab, tabs})
  }

  changePeriod = (data) => {
    var active_tab = data.id
    this.setupTabbed(active_tab)
  }

  renderContent = () => {
    var { sections, tabs } = this.state
    return(
      <View style={{width:'100%', flex:1}} animation='fadeIn' duration={240}>
        {
          tabs?.length === 0 || !tabs ? null :
          <View style={{width:'100%'}} animation={'fadeIn'} duration={240}>
            <Tabs tabs={tabs} onChange={this.changePeriod} active_tab={this.state.active_tab} secondary/>
          </View>
        }
        <SectionList
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:32}}
          sections={sections || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          renderSectionHeader={this.renderSectionHeader}
          initialNumToRender={20}
        />
      </View>
    )
  }

  renderItem = ({item, index}) => {
    var backgroundColor = index%2 ? GLOBAL.style.background_alternating : 'transparent'

    var size = 48
    var scale = 1
    var height = 90
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding/1.5, backgroundColor:'transparent', borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0}, MainStyles.flexRow, MainStyles.flexBetweenStart]}>

        <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:14, color:GLOBAL.style.color_secondary, width:180}}>
        {item.title}
        </Text>

        <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:14, color:GLOBAL.style.color, textAlign:'left', flex:1}}>
        {item.value}
        </Text>

      </View>
    )
  }

  renderSectionHeader = ({section}) => {
    var { title } = section
    return(
      <SectionHeader title={title} />
    )
  }

  render() {
    return(
      <View style={[{flex:1, width:'100%', minHeight:300}, MainStyles.flexStartCenter]}>
        {this.renderContent()}
      </View>
      )
  }

}
