/* @flow */

import React, { PureComponent } from 'react';

import {
  View,
  Image,
  Text,
  Pressable,
  ScrollView,
} from 'react-native';

//Modules
import Match from '../../screens/match.js'

//
import GLOBAL from '../../helpers/global.js'
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'

export default class Item extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      id:this.props.id,
    }
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.id !== this.props.id){
      this.setState({id:this.props.id})
    }
  }

  hide_overlay = () => {
    if(this.props.hide_overlay){
      this.props.hide_overlay()
    }
    this.setState({id:null})
  }

  goToTNNS = () => {
    Functions.openURL("https://www.tnnslive.com")
  }

  renderOverlay = () => {
    var { id } = this.state
    if(id){
      var padding = GLOBAL.padding
      var size = 36
      return(
        <View style={[{width:'100%', backgroundColor:'white', borderRadius:0}, MainStyles.flexCenter]}>
          {this.props.showBranding ? this.renderPoweredBy() : null}
          {
            // <Pressable onPress={this.hide_overlay} style={[{zIndex:2, position:'absolute', top:-size/3, right:-size/3, height:size, aspectRatio:1, borderRadius:100, backgroundColor:'white'}, MainStyles.flexCenter]}>
            //   <Image
            //   style={{height:size/2, width:size/2, tintColor:'black', resizeMode:'contain'}}
            //   source={require('../../assets/icons/close.png')}
            //   />
            // </Pressable>
          }
          {
            // <ScrollView style={{flex:1, width:'100%'}} contentContainerStyle={{width:'100%', minHeight:100}}>
            //   <Match id={id} overlay/>
            // </ScrollView>

            <View style={{width:'100%'}}>
              <Match id={id} overlay hideMatchCard hidePreview hideStatPeriods/>
            </View>
          }
          {this.props.showBranding ? this.renderTNNSCTA() : null}
          {
            this.renderBarrier()
          }
        </View>
      )
    }else{return null}
  }

  renderBarrier = () => {
    return <View style={{height:GLOBAL.padding, width:'100%', backgroundColor:'#D1D2D3' || GLOBAL.style.borderColor}}/>
  }

  renderPoweredBy = () => {
    return(
      <Pressable onPress={this.goToTNNS} style={[{width:'100%', backgroundColor:GLOBAL.style.active_tab, height:32, paddingHorizontal:GLOBAL.padding, borderBottomWidth:1, borderBottomColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:12, color:'rgba(255,255,255,0.5)', textTransform:'uppercase', letterSpacing:0.4}}>
        Powered by <Text style={{fontFamily:GLOBAL.fonts.bold, color:'white'}}>TNNSLIVE.COM</Text>
        </Text>
      </Pressable>
    )
  }

  renderTNNSCTA = () => {
    return(
      <Pressable
      onPress={this.goToTNNS}
      style={[{width:'100%', paddingVertical:GLOBAL.padding/2, paddingHorizontal:GLOBAL.padding, borderTopWidth:1, borderTopColor:GLOBAL.style.borderColor}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <View style={[{flex:1, paddingRight:GLOBAL.padding}, MainStyles.flexCenterStart]}>
          {
            <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:12, color:'rgba(0,0,0,1)', opacity:1, lineHeight:16}}>
            Get match notifications, OOPs in your timezone, live PDF draws, historical records, fantasy brackets & more - only on TNNS.
            </Text>
            // <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:12, color:'rgba(0,0,0,1)', opacity:1, lineHeight:16}}>
            // Get live scores, live PDF draws, schedules (in your timezone), match notifications, fantasy + more - only on TNNS.
            // </Text>
          }
        </View>

        <View style={[{height:36, paddingHorizontal:GLOBAL.padding / 1.5, borderRadius:GLOBAL.borderRadius, backgroundColor:GLOBAL.colors.button}, MainStyles.flexCenter]}>
          <Text style={{fontFamily:GLOBAL.fonts.bold, color:'white', fontSize:14}}>
          Open TNNS
          </Text>
        </View>
      </Pressable>
    )
  }

  render = () => {
    try{
      return this.renderOverlay()
    }catch(e){
      return null
    }
  }

}
