/* @flow */

import React, { PureComponent } from 'react';

import {
  Pressable,
  Text,
  ActivityIndicator,
  Image
} from 'react-native';

//Modules


//
import GLOBAL from '../../helpers/global.js'
import MainStyles from '../../helpers/mainstyles.js'

export default class Item extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      loading:this.props.loading,
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.loading !== this.props.loading){
      this.setState({loading:this.props.loading})
    }
  }

  onPress = () => {
    var { onPress, copyLink } = this.props
    if(onPress){
      onPress()
    }
    if(copyLink){
      this.setState({pressed:true})
      setTimeout(()=> {
        this.setState({pressed:false})
      },4000)
    }
  }

  render() {
    var { onPress, cancel, snapchat, copyLink, destructive, disabled, apple, google, small } = this.props
    var { loading, pressed } = this.state

    var color = 'white'
    var backgroundColor = GLOBAL.style.button?.background || GLOBAL.style.section_title.background
    var title = this.props.title

    if(cancel){
      color = 'rgba(0,0,0,0.67)'
      backgroundColor = 'rgba(0,0,0,0.1)'
    }

    if(snapchat){
      color = 'black'
      backgroundColor = '#FFFC00'
    }

    if(copyLink && pressed){
      backgroundColor = GLOBAL.colors.teal
    }

    if(destructive){
      backgroundColor = GLOBAL.colors.youtube
    }

    var icon_size = 22

    var opacity = disabled ? 0.2 : 1

    var appleStyle = {}
    var appleTextStyle = {}

    if(apple){
      appleStyle = {backgroundColor:GLOBAL.appTheme === 'dark' ? 'white' : 'black'}
      appleTextStyle = {color:GLOBAL.appTheme === 'dark' ? 'black' : 'white'}
      title = 'Sign in with Apple'
    }

    var height = small ? 38 : 48



    try{
      return (
        <Pressable onPress={disabled ? null : this.onPress} style={[{width:'100%', height, borderRadius:5, backgroundColor, opacity, borderWidth:1, borderColor:GLOBAL.style.borderColor}, MainStyles.flexCenter, MainStyles.flexRow, appleStyle, this.props.style]}>

          {
            !google ? null :
            <Image
            source={require('../../assets/icons/google.png')}
            style={{height:18, width:18, marginRight:GLOBAL.padding/2, marginTop:1, resizeMode:'contain'}}/>
          }

          {
            loading ? <ActivityIndicator color={color}/> :
            <Text style={[{fontSize:14, fontFamily:GLOBAL.fonts.bold, color}, appleTextStyle, this.props.textStyle]}>
            {title}
            </Text>
          }
        </Pressable>

      );
    }catch(e){
      return null
    }
  }

}
