/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  // Pressable,
  Image,
} from 'react-native';

//Modules
import { Pressable, Hoverable } from "react-native-web-hover";

//Components
import Loading from '../../components/general/loading.js'
import MatchList from '../../components/matches/matchlist.js'
import Tabs from '../../components/general/tabs.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      sections:[],
      loading:true,
      refreshing:true,
      format:'all',
      submode_data:{},
      formats:[],
      mounted:true,
    }
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentDidUpdate = async (prevProps) => {
    if(this.props.submode !== prevProps.submode){
      await this.setState({
        sections:[],
        loading:true,
        refreshing:true,
        format:'all',
        submode_data:{},
        formats:[],
        mounted:true,
      })
      this.setup()
    }
  }

  componentWillUnmount = async () => {
    clearTimeout(this.refresh_data)
    await this.setState({mounted:false})
  }

  setup = async (params, hideRefreshing) => {
    clearTimeout(this.refresh_data)

    var { id } = this.props

    if(!hideRefreshing){
      this.setState({refreshing:true})
    }

    if(!params){
      params = {}
    }

    params.id = id
    params.mode = 'matches_tournament'
    params.submode = this.props.submode

    params.theme = 'daily'

    if(!params.date){
      params.date = this.state.date
    }


    // Check if we already loaded this data
    var data_key = (params.date || '') + (params.format || 'all')

    var data = {}
    var data_r = await Functions.tnnsAPI(params)
    data = data_r?.data || {}

    var { formats, header, sections, format, refresh_time } = data
    this.refresh_time(refresh_time)
    await this.setState({formats:formats || this.props.formats || this.state.formats, header:header || null, sections, id, submode:this.props.submode, loading:false, refreshing:false, format, header_temp:null})
    this.setState({[data_key]:data})
  }

  changeFormat = async (format_data) => {
    clearTimeout(this.refresh_data)
    var format = format_data.id
    if(format !== this.state.format){
      await this.setState({format})
      this.setup({format})
    }
  }

  changeDate = async (date_obj) => {
    clearTimeout(this.refresh_data)
    var { header, refreshing } = this.state
    if(date_obj && !refreshing){
      var { date, title } = date_obj
      var header_temp = Object.assign({}, header)
      header_temp.title = title
      await this.setState({header_temp, date})
      this.setup({date})
    }
  }

  //AppState & Reload Controls
  refresh_time = (refresh_time) => {
    if(refresh_time){
      this.refresh_data = setTimeout(()=>{
        if(!this.state.app_backgrounded && this.state.mounted){
          this.setup(null, true)
        }
      }, refresh_time)
    }
  }

  onAppStateChange = (appState) => {
    var app_backgrounded = appState === 'background'
    this.setState({appState, app_backgrounded})
    if(appState === 'active'){
      //Restart the listener
      this.setup(null, true)
    }
  }


  //Renders
  renderControls = () => {
    var { submode } = this.state
    return(
      <>
      {submode === 'scheduled' || submode === 'completed' ?  this.renderDateHeader() : null}
      {this.renderFormats()}
      </>
    )
  }

  renderDateHeader = () => {
    var { header, header_temp } = this.state
    var size = 28

    var back = header?.back
    var forward = header?.forward

    var disabled_opacity = 0.15

    return(
      <View style={[{width:'100%', borderBottomColor:GLOBAL.style.borderColor, borderBottomWidth:1}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

        <Hoverable style={{}}>
        {({ hovered }) => (
          <Pressable style={{padding:GLOBAL.padding}} onPress={!back ? null : ()=>this.changeDate(back)}>
            <View style={[{height:size, width:size, borderRadius:100, backgroundColor:hovered ? GLOBAL.style.hoverTab : 'rgba(0,0,0,0.04)', opacity:back ? 1 : disabled_opacity}, MainStyles.flexCenter]}>
              <Image style={{transform:[{rotate:'0deg'}], marginRight:2,height:size/2, width:size/2, resizeMode:'contain', tintColor:GLOBAL.style.color}} source={require('../../assets/icons/arrow.png')}/>
            </View>
          </Pressable>
        )}
        </Hoverable>

        <View style={[{flex:1}, MainStyles.flexCenter]}>
          <Text style={{fontSize:14, color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.bold}}>
          {header_temp?.title || header?.title}
          </Text>
          {
            !header?.subtitle ? null :
            <Text style={{marginTop:2, fontSize:12, color:GLOBAL.style.color_secondary, fontFamily:GLOBAL.fonts.regular}}>
            {header?.subtitle}
            </Text>
          }
        </View>

        <Hoverable style={{}}>
        {({ hovered }) => (
          <Pressable style={{padding:GLOBAL.padding}} onPress={!forward ? null : ()=>this.changeDate(forward)}>
            <View style={[{height:size, width:size, borderRadius:100, backgroundColor:hovered ? GLOBAL.style.hoverTab : 'rgba(0,0,0,0.04)', opacity:forward ? 1 : disabled_opacity}, MainStyles.flexCenter]}>
              <Image style={{transform:[{rotate:'180deg'}], marginLeft:2,height:size/2, width:size/2, resizeMode:'contain', tintColor:GLOBAL.style.color}} source={require('../../assets/icons/arrow.png')}/>
            </View>
          </Pressable>
        )}
        </Hoverable>
      </View>
    )
  }

  renderFormats = () => {
    var { formats } = this.state
    var tabs = formats || []

    return tabs.length > 0 ? <Tabs tabs={tabs} onChange={this.changeFormat} active_tab={this.state.format} secondary disableBorder noBorder/> : null
  }

  render = () => {
    var sections = this.state.sections || []
    try{
      return (
        <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
          <MatchList
          header={this.renderControls}
          data={sections}
          loading={this.state.loading}
          refreshing={this.state.refreshing}
          />
        </View>
      );
    }catch(e){
      return null
    }
  }

}
