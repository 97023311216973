/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  Pressable,
  Image,
  Dimensions,
  ActivityIndicator,
  Linking,
  Alert,
} from 'react-native';

//Modules
// import Image from 'react-native-fast-image'

// import * as Animatable from 'react-native-animatable';

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

var moment = require('moment-timezone');

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      index:this.props.index,
      item:this.props.item,
      active:this.props.active,
      isVisible: this.props.isVisible,
      muted:this.props.muted || true,
      play:true,
      timer:-1,
      small:this.props.small,
      duration:'',
      opacity:1,
    }
  }

  async componentDidMount(){
    this.checkSeen()
  }

  async componentDidUpdate(prevProps){
    if(prevProps.item !== this.props.item){
      this.setState({item:this.props.item})
    }
    if(prevProps.index !== this.props.index){
      this.setState({index:this.props.index})
    }
    if(prevProps.active !== this.props.active){
      this.setActive(this.props.active)
    }
    if(prevProps.isVisible !== this.props.isVisible){
      this.setState({isVisible: this.props.isVisible})
    }
    if(prevProps.volume !== this.props.volume){
      if(this.state.playState === 'playing' && this.props.volume > prevProps.volume){
        //User has increased their volume, turn on audio
        this.setState({muted:false})
      }
    }
  }

  componentWillUnmount(){
    clearTimeout(this.timeoutVideoDuration)
  }

  checkSeen = async () => {
    var { item } = this.props
    // //Check seen
    // var seen = await Functions.getAsyncData('videos/seen/'+item.id)
    // seen = false

    //Set duration & opacity
    var duration = item.duration
    var opacity = 1

    // if(seen){
    //   duration = 'Seen'
    //   opacity = 0.45
    // }

    this.setState({duration, opacity})
  }

  setActive = async (active) => {
    clearTimeout(this.timeoutVideoDuration)

    if(!active){
      this.setState({active, playState:false})
    }else{
      //Set the volume listener
      if(this.props.delayPreview){
        setTimeout(async () => {
          this.setState({active})
        }, 1 * 1000)
      }else{
        setTimeout(async () => {
          this.setState({active})
        }, 0)
      }

    }
  }

  updateTimer = async () => {
    clearTimeout(this.timeoutVideoDuration)

    //Check swiper and app state are ok
    if(GLOBAL.appState === 'active' && GLOBAL.swiperIndex === 0){
      this.setState({timer:this.state.timer + 1})

      this.timeoutVideoDuration = setTimeout(async () => {
        this.updateTimer()
      }, 1 * 1000)
    }else{
      this.setState({active:false, isVisible:false, playState:false})
    }


  }

  videoReady = () => {
    this.setState({ isReady: true })
  }

  onChangeState = (playState) => {
    this.setState({playState})

    if(playState === 'playing'){
      //Start the timer
      this.updateTimer()
    }
  }

  openVideo = async () => {
    var { item, index, timer } = this.state
    var { source } = this.props

    await Functions.openURL(item.link)

    // await Functions.openYouTube(item, source)

    //Refresh async in 5s
    setTimeout(async () => {
      this.checkSeen()
    },5 * 1000)
  }

  openVideoOptions = async ()  => {
    var { item, index } = this.state

    var options = [
      {text:'Watch Video', onPress:this.openVideo},
      {text:'Go to YouTube Channel', onPress:()=>{
        Linking.openURL(item.author.link)
        Functions.amplitudeEvent('Opened YouTube Channel',{channel:item.author.name})
      }},
      {text:'Subscribe to YouTube Channel', onPress:()=>{
        Linking.openURL('http://www.youtube.com/channel/'+item.author.id+'?sub_confirmation=1')
        Functions.amplitudeEvent('Subscribed to YouTube Channel',{channel:item.author.name})
      }},
      {text:'Cancel', style:'cancel'}
    ]

    Alert.alert(item.author.name, 'YouTube Options', options)
  }

  //

  renderVideo = (height) => {
    var { item, index, active } = this.state
    if(item.id){
      return(
        <View style={{position:'absolute', width:'100%', flex:1}}>

        </View>
      )
    }
  }

  renderDuration = () => {
    var { active, playState, item, duration } = this.state
    return(
      <View style={[{position:'absolute', bottom:10, right:10}, MainStyles.flexRow, MainStyles.flexCenter]}>
        {
          // active && playState !== 'playing' ?
          // <View style={{marginRight:10}}>
          // <ActivityIndicator size="small" color='white'/>
          // </View>
          // : null
        }
        {
          duration ?
          <View style={[{height:24, borderRadius:5, paddingHorizontal:5, backgroundColor:'rgba(0,0,0,0.67)'}, MainStyles.flexCenter]}>
            <Text style={{"fontSize":14,"color":"rgba(255,255,255,1)","letterSpacing":0.25,"textAlign":"center", opacity:1, fontFamily:'Averta-Bold'}}>
            {duration}
            </Text>
          </View>
          : null
        }
        {
          item.live || item.isLive ?
          <View style={[{height:24, borderRadius:5, paddingHorizontal:5, backgroundColor:'#F00942'}, MainStyles.flexCenter]}>
            <Text style={{"fontSize":14,"color":"rgba(255,255,255,1)","letterSpacing":0.25,"textAlign":"center", opacity:1, fontFamily:'Averta-Black'}}>
            LIVE
            </Text>
          </View>
          : null
        }
      </View>
    )
  }

  renderPreview = (aspectRatio) => {
    var { item, index, active, playState, opacity } = this.state

    return(
      <View style={[{aspectRatio, width:'100%'}, MainStyles.flexCenter]} animation={playState === 'playing' ? 'fadeOut' : 'fadeIn'} duration={250}>
        <Image
          style={[{aspectRatio, width:'100%', opacity, backgroundColor:'rgba(0,0,0,0.5)'}]}
          source={{
              uri: item.background,
              // priority: Image.priority.normal,
          }}
          // resizeMode={Image.resizeMode.cover}
        />
      </View>
    )
  }

  renderSmall = () => {
    var { item, index, duration, opacity } = this.state
    var width = 160
    var height = width / (1920/1080)
    return(
      <Pressable key={index} style={[MainStyles.flexCenter, {marginRight:15, width}]}
      onPress={this.openVideo}
      >

        <View style={{height, width}}>
          <Image
            style={[{height, width, borderRadius:5, backgroundColor:'rgba(0,0,0,0.5)', opacity, overflow:'hidden'}]}
            source={{
                uri: item.background,
                // priority: Image.priority.normal,
            }}
            // resizeMode={Image.resizeMode.cover}
          />

          {
            duration ?
            <View style={[{height:24, borderRadius:5, paddingHorizontal:5, backgroundColor:'rgba(0,0,0,0.67)', position:'absolute', bottom:5, right:5}, MainStyles.flexCenter]}>
              <Text style={{"fontSize":14,"color":"rgba(255,255,255,1)","letterSpacing":0,"textAlign":"center", opacity:1, fontFamily:'Averta-Bold'}}>
              {duration}
              </Text>
            </View>
            : null
          }

        </View>



      </Pressable>
    )
  }

  render() {
    var { item, index, active, isReady, playState, small } = this.state
    var { mode } = this.props

    // var aspectRatio = 335/169
    var aspectRatio = 1920/1080

    var height = this.props.height || (dimensions.width / aspectRatio)

    var insideRender =
    <>
      <View style={[{width:'100%', aspectRatio, borderRadius:this.props.fullMode ? 0 : 5, backgroundColor:GLOBAL.style.borderColor, overflow:'hidden'}, MainStyles.flexCenter]}>
      {
        // active ? this.renderVideo(height) : null
      }
      {this.renderPreview(aspectRatio)}
      {this.renderDuration()}
      </View>

      {
        this.props.hideInfo ? null :
        <View style={[MainStyles.flexRow, MainStyles.flexStartStart, {width:'100%', marginTop:10}]}>
          <Image
            style={[{height:40, width:40, borderRadius:20, backgroundColor:GLOBAL.style.borderColor, marginRight:15}]}
            source={{
                uri: item?.author?.image,
                // priority: Image.priority.normal,
            }}
            // resizeMode={Image.resizeMode.cover}
          />
          <View style={[{flex:1, paddingRight:20}, MainStyles.flexColumn, MainStyles.flexStartStart]}>
            <Text style={{"fontSize":14,"color":GLOBAL.style.color_font,"letterSpacing":-0.25,"lineHeight":18, fontFamily:'Averta-Semibold', marginTop:2}} numberOfLines={small || mode === 'live' ? 1 : 2}>
            {item.title} {item.subtitle ? " - "+item.subtitle : null}
            </Text>
            {
              item.live ?
              <Text style={{"fontFamily":"Averta-Regular","fontSize":14,"color":GLOBAL.style.color_font,"letterSpacing":-0.25, opacity:0.5}}>
              {item.author?.name} · {item.watching}
              </Text>
              :
              <Text style={{"fontFamily":"Averta-Regular","fontSize":14,"color":GLOBAL.style.color_font,"letterSpacing":-0.43, opacity:0.5}}>
              {item.author?.name} · {item.views} views · {moment(item.publishedAt, 'x').fromNow()}
              </Text>
            }
          </View>
        </View>
      }
    </>
    if(small){
      try{
        return this.renderSmall()
      }catch(e){
        return null
      }
    }else if(this.props.fullMode){
      try{
        return (
          <View style={[MainStyles.flexCenter, {width:'100%', paddingHorizontal:this.state.padding, marginVertical:15}]}>
            {insideRender}
          </View>
        );
      }catch(e){return null}
    }else{
      try{
        return (
          <Pressable style={[MainStyles.flexCenter, {width:'100%', paddingHorizontal:this.state.padding, marginVertical:15, marginBottom:0}]} onPress={this.openVideo}>
            {insideRender}
          </Pressable>
        );
      }catch(e){return null}
    }
  }
}
