/* @flow */

import React, { PureComponent } from 'react';
import {
  View, ScrollView
} from 'react-native';

//Components
import ScoresBanner from '../components/widgets/scores.js'
import Scores from '../components/widgets/allscores.js'
import Tournaments from '../components/widgets/tournaments.js'

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

import { initAmplitudeInstance, logAmplitudeEvent } from '../AmplitudeUtils';

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      id:this.props.route.params?.id,
      scroll:this.props.route.params?.scroll,
      height:800,
    }
  }

  async componentDidMount(){
    // var iframe = document.querySelector('iframe');
    // iframe.contentWindow.postMessage('Hello my child', 'http://remote-domain.com:8080');

    // parent.postMessage('Hello parent','https://tnnslive.com');
    window.addEventListener("message", this.handleMsg, true);
  }

  handleMsg = (msg) => {
    // console.log(msg)
    if(msg?.data?.target === 'tnnsWidgetHeight'){
      var height = Number(msg.data.value)
      this.setState({height})
    }
  }



  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  renderContent = () => {
    var {height} = this.state
    return(
      <ScrollView
      contentContainerStyle={[{width:'100%'}, MainStyles.flexStartCenter]}
      style={[{width:'100%', height:'100%', backgroundColor:'black'}]}
      >
        <View
        style={{width:700, flex:1}}
        // contentContainerStyle={{width:'100%'}}
        >
          <iframe
          width={'100%'}
          height={height}
          src={"http://localhost:3000/widgets/allscores?host=ltr"}
          scrolling="yes"
          >
          </iframe>
        </View>
      </ScrollView>
    )
  }

  render = () => {
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
