/* @flow */

import React, { PureComponent } from 'react';
import {
  View, Text, Image, Pressable, Dimensions
} from 'react-native';

//Modules


import MainStyles from '../../helpers/mainstyles.js'
import GLOBAL from '../../helpers/global.js'
import Functions from '../../helpers/functions.js'

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
    }
  }

  async componentDidMount(){

  }

  async componentDidUpdate(prevProps){

  }

  openAppStore = (appStore) => {
    var url = appStore ? "https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724" : "https://play.google.com/store/apps/details?id=com.tennisrn"
    Functions.openURL(url)
  }

  openDownload = () => {
    GLOBAL.navigation.push('Download')
  }

  renderContent = () => {
    var highlights = [
      "- Faster Live Scores",
      "- Player & Match Notifications",
      "- Streams, Videos & Live Commentary",
      "- Free & Ad Free"
    ]

    var size = 240

    return(
      <View style={[{width:'100%', marginBottom:GLOBAL.padding_card}, MainStyles.flexCenter]}>
      <View style={[{width:'100%', borderRadius:GLOBAL.displayMode === 'mobile' ? 0 : GLOBAL.borderRadius, overflow:'hidden', backgroundColor:GLOBAL.displayMode === 'mobile' ? 'transparent' :GLOBAL.style.color_card, padding:GLOBAL.padding}, MainStyles.flexCenter, MainStyles.flexColumn]}>
        <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[{width:'45%'}, MainStyles.flexCenterStart]}>
            <Text style={{fontFamily:GLOBAL.fonts.black, fontSize:14, color:GLOBAL.style.color_font, width:'100%', textAlign:'left', textTransform:'uppercase', letterSpacing:2}}>
            Get the free TNNS App
            </Text>
            <View style={[{width:'100%'}, MainStyles.flexCenterStart]}>
              {highlights.map((item, index) => this.renderHighlight(item, index))}
            </View>
          </View>
          <View style={{width:'25%', height:'100%', marginTop:20}}>
            <Image style={{height:size, aspectRatio:12/22, resizeMode:'cover', position:'absolute', right:40, top:-110}} source={require('../../images/stream_demo.webp')}/>
            <Image style={{height:size*1.1, aspectRatio:12/22, resizeMode:'cover', position:'absolute', right:-35, top:-120}} source={require('../../images/score_demo.webp')}/>
          </View>
        </View>
      </View>
      <View style={[{marginTop:GLOBAL.padding, width:'100%', flexWrap:'wrap', paddingHorizontal:(GLOBAL.displayMode !== 'mobile' ? 0 : 10)}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
      {this.renderButton(require('../../images/appstore.webp'), true)}
      {this.renderButton(require('../../images/playstore.webp'), false)}
      </View>
      </View>
    )
  }

  renderButton = (image, appStore) => {
    var width = GLOBAL.displayMode !== 'mobile' ? 140 : (dimensions.width/2 - 20)
    var paddingRight = appStore ? (GLOBAL.displayMode !== 'mobile' && appStore ? {paddingRight:GLOBAL.padding/2} : {paddingRight:GLOBAL.padding/2}) : 0
    return(
      <Pressable onPress={()=>this.openAppStore(appStore)} style={[paddingRight]}>
        <Image style={{width, aspectRatio:600.5/178.5, resizeMode:'contain'}} source={image}/>
      </Pressable>
    )
  }

  renderHighlight = (text, index) => {
    return(
      <Text key={index} style={{marginTop:GLOBAL.padding/2, fontSize:14, fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color_font, opacity:0.5}}>
      {text}
      </Text>
      )
  }


  render() {
    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
