/* @flow */

import React, { PureComponent } from 'react';

import {
  View,
  Text,
  Image,
} from 'react-native';

//Modules

//
import GLOBAL from '../../helpers/global.js'
import Functions from '../../helpers/functions.js'
import MainStyles from '../../helpers/mainstyles.js'

export default class Item extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      url:''
    }
  }

  componentDidMount = () => {
    this.setState({url:"https://twitter.com/rogerfederer"})
  }

  componentDidUpdate = (prevProps) => {

  }

  renderTwitter = () => {
    return(
      <View style={{width:'100%', height:1000}}>
        <Text>Twitter</Text>
        {this.renderTimeline()}
      </View>
    )
  }

  renderTimeline = () => {
    return(
      <>
      <blockquote class="twitter-tweet"><p lang="en" dir="ltr">I had a need for speed 🏎💨 Good Luck tomorrow <a href="https://twitter.com/LewisHamilton?ref_src=twsrc%5Etfw">@LewisHamilton</a> <a href="https://twitter.com/GeorgeRussell63?ref_src=twsrc%5Etfw">@GeorgeRussell63</a> <a href="https://twitter.com/MercedesAMGF1?ref_src=twsrc%5Etfw">@MercedesAMGF1</a> <a href="https://t.co/EZLcjmewoK">pic.twitter.com/EZLcjmewoK</a></p>&mdash; Roger Federer (@rogerfederer) <a href="https://twitter.com/rogerfederer/status/1528054470776455169?ref_src=twsrc%5Etfw">May 21, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

      <Image style={{width:100, height:100, resizeMode:'cover'}} source={{uri:"https://pic.twitter.com/EZLcjmewoK"}}/>
      </>
    )
  }

  render = () => {
      try{
        return (
          <View style={{width:'100%', backgroundColor:GLOBAL.style.backgroundColor}}>
            {this.renderTwitter()}
          </View>
        )
      }catch(e){
        console.log(e)
        return null
      }
    // }
  }

}
