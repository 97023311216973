/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Dimensions,
  Image,
  TouchableOpacity,
  Alert
} from 'react-native';

//Modules
// import { firebase } from '@react-native-firebase/auth';
// import firestore from '@react-native-firebase/firestore';

// import Image from 'react-native-fast-image'

// import { Flag } from 'react-native-svg-flagkit'
import ReactCountryFlag from "../../components/general/flag.js"
//Components


//Screens


//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

var moment = require('moment-timezone');
const { flag, code } = require('country-emoji')

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

var widths = [
  {width:66},
  {flex:1},
  {width:72}
]


export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      loading:true,
      notificationsOn:false,
    }
  }

  async componentDidMount(){
    this.setup()
  }

  async componentDidUpdate(prevProps){
    if(prevProps.data !== this.props.data){
      // this.setup()
    }
  }

  setup = async () => {
    var { data, index } = this.props

    data.flag = flag(data.country)
    data.name = ((data.name.split(", ")).reverse()).join(" ")
    data.country_cca = code(data.flag)
    if(data.flags){
      data.country_cca = data.flags[0]
    }

    this.setState({data, index, loading:false})

    // Check notifications
    // var notificationsOn = GLOBAL?.players_subscribed?.includes(data.id)
    // this.setState({notificationsOn})
  }

  openPlayer = async () => {
    var { data } = this.state
    var name = data.name.split(" ").join("-")
    var id = data.docId
    GLOBAL.navigation.push("Player", {id})
  }

  toggleNotifications = async () => {
    var { data, notificationsOn } = this.state

    await Functions.toggleNotificationsPlayer(data.id, !notificationsOn, data)
    this.setState({notificationsOn:!notificationsOn})
  }

  //

  renderRank(textStyle){
    var { loading, index, data } = this.state

    var progressRender = false
    var tintColor
    var transform
    var movement = ''
    var position = null

    if(data.movement > 0){
      progressRender = true
      tintColor = GLOBAL.colors.green
      transform = [{rotate:'90deg'}]
      movement = "+"+data.movement
      position = {top:-12}
    }
    if(data.movement < 0){
      progressRender = true
      tintColor = GLOBAL.colors.pink
      transform = [{rotate:'-90deg'}]
      movement = data.movement
      position = {bottom:-12}
    }

    return(
      <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, widths[0]]}>
        <Text style={[textStyle, {minWidth:16}]}>
        {data.rank}
        </Text>
        {!progressRender ? null :
          <View style={[{marginLeft:12}, MainStyles.flexCenter]}>
            <Text style={[textStyle, {fontSize:12, color:tintColor}]}>
            {movement}
            </Text>
          </View>
        }
      </View>
    )
  }

  renderName(textStyle){
    var { loading, index, data } = this.state

    var isDoubles = false
    if(data.players){
      isDoubles = true
    }

    var showImage = false

    return(
      <View style={[widths[1], MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {this.renderFlag(data.country_cca || '')}
        <Text style={[textStyle]}>
        {data.name}
        </Text>
      </View>
    )

  }

  renderFlag = (flag) => {
    var width = 20
    var height = 12
    return(
      <View style={{marginRight:10}}>
        <ReactCountryFlag countryCode={flag} svg style={{width, height}} title={flag} />
      </View>
    )
  }

  renderBell(){
    var { notificationsOn } = this.state

    var icon = require('../../icons/bell-filled.png')
    var tintColor = GLOBAL.style.color_font
    var opacity = 0.2

    if(notificationsOn){
      icon = require('../../icons/bell-filled.png')
      tintColor = GLOBAL.style.color_bell
      opacity = 1
    }

    return(
      <>
      <TouchableOpacity onPress={this.toggleNotifications} style={{}}>
        <Image
        style={{height:22, width:22, resizeMode:'contain', tintColor, opacity}}
        source={icon}
        />
      </TouchableOpacity>
      </>
    )
  }

  renderImage(){
    var { loading, index, data } = this.state
    var image = data.image || null

    var size = 24

    var style = {height:size, width:size, borderRadius:20, backgroundColor:'rgba(255,255,255,0.12)', marginRight:12, overflow:'hidden'}
    var imageStyle = {height:size, width:size, borderRadius:20}

    if(image){
      return(
        <View style={[style, MainStyles.flexCenter]}>
          <Image
            style={[imageStyle]}
            source={{
                uri: image,
                priority: Image.priority.normal,
            }}
            resizeMode={Image.resizeMode.contain}
          />
        </View>
      )
    }else{
      return(
        <View style={[style, MainStyles.flexCenter]}>
          <Image
          source={require('../../icons/user.png')}
          style={[{tintColor:GLOBAL.style.color_font, opacity:0.12, position:'absolute', bottom:-2, height:size*0.9, width:size*0.9}]}
          />
        </View>
      )
    }
  }

  renderPoints(textStyle){
    var { loading, index, data } = this.state
    return(
      <View style={[MainStyles.flexRow, MainStyles.flexEndCenter, widths[2]]}>
        <Text style={[textStyle]}>
        {data.points}
        </Text>
        <Image
        source={require('../../icons/back.png')}
        style={{height:12, width:12, resizeMode:'contain', tintColor:GLOBAL.style.color_font, opacity:0.33, marginLeft:12, transform:[{scaleX:-1}]}}
        />
      </View>
    )
  }

  render() {
    var { loading, index, data } = this.state

    var style = {fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color_font, fontSize:14, letterSpacing:-0.35}

    var even = index % 2 == 0
    var backgroundColor = even ? 'transparent' : GLOBAL.style.alternatingBackground

    try{
      if(!loading){
        return (
          <View key={data.id} style={{width:'100%'}}>
            <TouchableOpacity onPress={this.openPlayer} style={{width:'100%'}}>
              <View style={[MainStyles.flexRow, MainStyles.flexCenter, {width:'100%', paddingHorizontal:this.props.padding || 20, paddingVertical:this.props.padding ? 24 - (24 - this.props.padding)/2 : 24, backgroundColor}]}>
                {this.renderName(style)}
              </View>
              {index === data.length-1 ? <View style={{height:32}}/> : null}
            </TouchableOpacity>
          </View>
        )
      }else{
        return null
      }
    }catch(e){
      return null
    }
  }

}
