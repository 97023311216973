/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Button,
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";

//Components
import Background from '../components/general/background.js'
import SEOTitle from '../components/general/seotitle.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      activeView:'Live',
    }
  }

  async componentDidMount(){


  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }



  renderContent = () => {
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var title = "Live Tennis Scores, Results, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var subtitle = "Latest tennis live scores, results and schedules for Grand Slam, ATP, WTA, Challenger and ITF events."
    return(
      <>
      <SEOTitle title={title}/>
      <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="description" content={subtitle}/>
      </Helmet>
      <Background navigation={this.props.navigation}>
      {this.renderMain()}
      </Background>
      </>
    )
  }

  renderMain = () => {
    var { activeView } = this.state

    return(
      <View style={[{width:'100%'}, MainStyles.flexCenter]}>
        <Text>Fantasy</Text>
      </View>
    )
  }

  render = () => {
    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
