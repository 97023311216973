/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  SectionList,
  Pressable,
  Image,
} from 'react-native';

//Modules

//Components
import SectionHeader from '../../components/general/sectionheader.js'
import Loading from '../../components/general/loading.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      draws:this.props.draws || []
    }
  }

  componentDidMount = async () => {

  }

  componentWillUnmount = async () => {

  }

  openDraw = (item) => {
    Functions.openURL(item.url)
  }

  renderContent = () => {
    var { draws } = this.state
    return(
      <View style={{width:'100%', flex:1}} animation='fadeIn' duration={240}>
        <SectionList
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:32}}
          sections={draws || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          renderSectionHeader={this.renderSectionHeader}
          ListFooterComponent={this.renderMessage}
        />
      </View>
    )
  }

  renderMessage = () => {
    var { draws_message } = this.props
    if(!draws_message){return null}else{
      return(
        <Text style={{marginTop:GLOBAL.padding, fontSize:12, color:GLOBAL.style.color_secondary, fontFamily:GLOBAL.fonts.regular, paddingHorizontal:GLOBAL.padding}}>
        {draws_message}
        </Text>
      )
    }
  }

  renderItem = ({item, index}) => {
    var backgroundColor = index%2 ? GLOBAL.style.background_alternating : 'transparent'

    var size = 48
    var scale = 1
    var height = 90
    return(
      <Pressable onPress={()=>this.openDraw(item)} style={[{width:'100%', paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding/1, backgroundColor:'transparent', borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

        <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:14, color:GLOBAL.style.color}}>
        {item.title}
        </Text>

        <Image style={{height:20, width:20, resizeMode:'contain', tintColor:GLOBAL.style.color, opacity:0.33}} source={require('../../assets/icons/forward.png')}/>

      </Pressable>
    )
  }

  renderSectionHeader = ({section}) => {
    var { title } = section
    return(
      <SectionHeader title={title} />
    )
  }

  render() {
    try{
      return (
        <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
          {this.renderContent()}
        </View>
      );
    }catch(e){
      return null
    }
  }

}
