/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Pressable,
  Button,
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";

//Components
import Background from '../components/general/background.js'
import SEOTitle from '../components/general/seotitle.js'
import SectionHeader from '../components/general/sectionheader.js'

import BottomNavbar from '../components/navigation/bottomnavbar.js'
import Watch from './matches/watch.js'
import Scores from './matches/scores.js'
import Tournaments from './matches/tournaments.js'

import Header from '../components/seo/header.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      activeView:'scores',
      title:"Live Tennis Scores - Tennis Scores, Streams, Results, ATP, WTA & ITF Rankings",
      description:"Tennis live scores on TNNS are the fastest & most comprehensive tennis scores - with live real time scores and results from all pro tennis tournaments. ATP and WTA tennis live, ATP & WTA Rankings!"
    }
  }

  async componentDidMount(){
    this.props.navigation.setOptions({title: this.state.title})
  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  changeTab = (item) => {
    this.setState({activeView:item.id})
  }


  renderContent = () => {
    var { title, description } = this.state

    return(
      <>
      <Header title={this.state.title} description={this.state.description}/>
      <Background navigation={this.props.navigation} page="Tournaments" hideColumn>
      {this.renderMain()}
      </Background>
      <BottomNavbar/>
      </>
    )
  }

  renderMain = () => {
    var { activeView } = this.state

    var tabs = [
      // {id:'watch', title:'Watch'},
      // {id:'scores', title:'Scores'},
      {id:'tournaments', title:'Tournaments'},
    ]

    var render = <Tournaments/>

    return(
      <View style={[{width:'100%'}, MainStyles.flexStartCenter]}>
        <View style={{width:'100%', height:GLOBAL.displayMode === 'web' ? 0 : 0, backgroundColor:GLOBAL.style.color}}/>
        <View style={[{width:'100%', maxWidth:GLOBAL.maxWidth}, MainStyles.flexStartStart]}>
          <View style={[{width:'100%', backgroundColor:GLOBAL.style.cardColor}, MainStyles.flexRow, MainStyles.flexCenterStart]}>
            {render}
            {
              // this.renderRight()
            }
          </View>
        </View>
      </View>
    )
  }

  renderTab = (item, index) => {
    var active = this.state.activeView === item.id
    return(
      <Pressable onPress={()=>this.changeTab(item)} style={[{paddingVertical:GLOBAL.padding/2, paddingHorizontal:GLOBAL.padding * 2, backgroundColor:GLOBAL.style.cardColor, borderRightWidth:1, borderRightColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}>
        <Text style={{fontSize:14, fontFamily:GLOBAL.fonts.medium, opacity:active ? 1 : 1, color:active ? "white" : GLOBAL.style.color, textTransform:'none'}}>
        {item.title}
        </Text>
      </Pressable>
    )
  }

  render = () => {
    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
