import {StyleSheet} from 'react-native';

//Modules
// import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

//Grid
const spacingConstant = 10;

const spacing = {
  tiny: spacingConstant/4,
  minor: spacingConstant/2,
  medium: spacingConstant,
  major: spacingConstant*2,
}

const icon = {
  minor: 18,
  medium: 24,
  moderate: 32,
  major: 42,
}

//Colors
const color = {
  background: '#ECF3F8',
  backgroundElement: 'white',
  blue: '#25BCFE',
  yellow: '#FEFF60',
  primary: '#121426',
  secondary: '#434A54',
}

const MainStyles = StyleSheet.create({

  //Basics ---------------------------------------------------------------------
  background:{
    flex:1,
  },

  listWrapper:{
    width:'100%',
    backgroundColor: color.backgroundElement,
    padding: spacing.major,
    borderBottomWidth:1,
    borderBottomColor:color.background,
  },

  paddingNormal:{
    padding: spacing.major,
  },

  paddingHorizontal:{
    paddingHorizontal: spacing.major,
  },

  paddingVertical:{
    paddingVertical: spacing.major,
  },

  verticalSpacing:{
    marginVertical: spacing.major,
  },

  verticalSpacingTop:{
    marginTop: spacing.major,
  },

  verticalSpacingMinor:{
    marginVertical: spacing.medium,
  },

  flex1:{
    flex:1
  },

  flexColumn:{
    flexDirection:'column',
  },

  flexRow:{
    flexDirection:'row',
  },

  flexCenter:{
    justifyContent:'center',
    alignItems:'center'
  },

  flexCenterEnd:{
    justifyContent:'center',
    alignItems:'flex-end'
  },

  flexEndEnd:{
    justifyContent:'flex-end',
    alignItems:'flex-end'
  },

  flexCenterStart:{
    justifyContent:'center',
    alignItems:'flex-start'
  },

  flexStartStart:{
    justifyContent:'flex-start',
    alignItems:'flex-start'
  },

  flexStartEnd:{
    justifyContent:'flex-start',
    alignItems:'flex-end'
  },

  flexStartCenter:{
    justifyContent:'flex-start',
    alignItems:'center'
  },

  flexBetweenCenter:{
    justifyContent:'space-between',
    alignItems:'center'
  },

  flexBetweenStart:{
    justifyContent:'space-between',
    alignItems:'flex-start'
  },

  flexBetweenEnd:{
    justifyContent:'space-between',
    alignItems:'flex-end'
  },

  flexAround:{
    justifyContent:'space-around',
    alignItems:'center'
  },

  flexEndCenter:{
    justifyContent:'flex-end',
    alignItems:'center'
  },

  flexEndStart:{
    justifyContent:'flex-end',
    alignItems:'flex-start'
  },

  flexGrow:{
    flexGrow:1,
  },

  width100:{
    width:'100%'
  },

  height100:{
    height:'100%'
  },

})

export default MainStyles;
