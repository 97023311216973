/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
} from 'react-native';

//Modules


//Components


//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      string:this.props.string,
    }
  }

  async componentDidMount(){

  }

  async componentDidUpdate(prevProps){
    if(prevProps.string !== this.props.string){
      this.setState({string:this.props.string})
    }
  }

  async componentWillUnmount(){

  }

  render = () => {
    try{
      return(
        <View style={[{width:'100%', flex:1, minHeight:200}, MainStyles.flexCenter]}>
          <ActivityIndicator color={GLOBAL.style.color}/>
          <Text style={{color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.bold, marginTop:8, fontSize:14}}>
          {this.state.string}
          </Text>
        </View>
      )
    }catch(e){
      return null
    }
  }

}
