/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Button,
  SectionList,
  Image,
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";

import { NavigationContainer } from '@react-navigation/native';
// import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

//Components
import Background from '../components/general/background.js'
import Loading from '../components/general/loading.js'
import Tabs from '../components/general/tabs.js'
import Player from '../components/general/player.js'
import SectionHeader from '../components/general/sectionheader.js'
import SectionItem from '../components/general/sectionitem.js'
import Header from '../components/seo/header.js'

import BottomNavbar from '../components/navigation/bottomnavbar.js'


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      id1:this.props.route?.params?.id1,
      id2:this.props.route?.params?.id2,
      description:"Live scores, stats, point by point recaps, commentary, streams and bios."
    }
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {
    clearTimeout(this.refresh_data)
  }

  setup = async () => {

    var { id1, id2 } = this.props.route.params

    var player_1 = {
      id:"sr:competitor:"+id1,
      name:'Test, Name'
    }
    var player_2 = {
      id:"sr:competitor:"+id2,
      name:'Test, Name'
    }

    var params = {
      player_1,
      player_2,
      mode:'h2h_sr',
    }

    var data_r = await Functions.tnnsAPI(params)
    var data = data_r?.data || {}

    var {
      sections,
      header
    } = data

    console.log(header)

    this.setState({sections, header, loading:false})
  }

  renderContent = () => {
    var { loading } = this.state
    return(
      <>
      <Background navigation={this.props.navigation} page={'Home'} >
      {loading ? <Loading/> : this.renderMain()}
      </Background>
      </>
    )
  }

  renderMain = () => {
    var { id1, id2, sections } = this.state
    return(
      <View style={[{width:'100%'}, MainStyles.flexStartCenter]}>
        <View style={{maxWidth:GLOBAL.maxWidth, width:'100%', borderRadius:0, backgroundColor:GLOBAL.isMobile ? null : GLOBAL.style.cardColor, borderWidth:0, borderColor:GLOBAL.style.borderColor}}>
          <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexCenterStart]}>
            <View style={{flex:1}}>
              {this.renderSections()}
            </View>
          </View>
        </View>
      </View>
    )
  }

  renderSections = () => {
    var { sections } = this.state
    return(
      <View style={{width:'100%', flex:1}} animation='fadeIn' duration={240}>
        <SectionList
          ref={ref => (this.sectionListRef = ref)}
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:0}}
          sections={sections || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          renderSectionHeader={this.renderSectionHeader}
          onScrollBeginDrag={this.props.onScroll}
          initialNumToRender={20}
          ListFooterComponent={this.renderPlayers}
          ListHeaderComponent={this.renderHeader}
        />
      </View>
    )
  }

  renderPlayers = () => {
    var { players } = this.state
    if(!players){return null}else{
      return(
        <View style={{width:'100%'}}>
        <SectionHeader title={'Players'}/>
        {players.map((item, index) => <Player index={index} data={item} allButtons/>)}
        </View>
      )
    }
  }

  renderHeader_old = () => {
    var { header } = this.state
    var maxWidth = 180
    return(
      <View style={[{width:'100%'}, MainStyles.flexCenter]}>

      {
        // <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        //   <Image style={{width:'45%', aspectRatio:1, maxWidth, resizeMode:'contain'}} source={{uri:header?.players?.left?.image}}/>
        //   <Image style={{width:'45%', aspectRatio:1, maxWidth, resizeMode:'contain'}} source={{uri:header?.players?.right?.image}}/>
        // </View>
      }

      <View style={[{width:'100%', padding:GLOBAL.padding/2}, MainStyles.flexRow, MainStyles.flexCenter]}>
        <Text style={{position:'absolute', top:GLOBAL.padding*3, color:'black', opacity:0.05, fontFamily:GLOBAL.fonts.black, fontSize:48}}>
        H2H
        </Text>
        <View style={[{width:'50%', paddingRight:GLOBAL.padding/2}, MainStyles.flexCenterStart]}>
          {this.renderPlayer(header.players.left, 'left')}
        </View>
        <View style={[{width:'50%', paddingLeft:GLOBAL.padding/2}, MainStyles.flexCenterEnd]}>
          {this.renderPlayer(header.players.right, 'right')}
        </View>
      </View>

      </View>
    )
  }

  renderHeader = () => {
    var { header } = this.state
    var maxWidth = 180
    var margin = GLOBAL.padding

    return(
      <View style={[{width:'100%', backgroundColor:'white'}, MainStyles.flexCenter]}>

        {
          !(header?.players?.left?.image || header?.players?.left?.image)? null :
          <View style={[{width:'100%', marginTop:0, backgroundColor:'rgba(255,255,255,0.1)', paddingHorizontal:GLOBAL.padding}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

            <View style={{width:'33%'}}>
              <Image style={{width:'100%', maxWidth, aspectRatio:1, resizeMode:'cover', marginTop:margin, borderColor:GLOBAL.style.borderColorSecondary, borderWidth:1, borderRadius:2}} source={{uri:header?.players?.left?.image}}/>
            </View>
            <View style={[{width:'34%'}, MainStyles.flexCenter]}>
              <Text style={{fontSize:32, color:'black', fontFamily:GLOBAL.fonts.ultra, marginTop:margin, textTransform:'uppercase', opacity:0.1, textAlign:'center'}}>
              VS
              </Text>
            </View>
            <View style={{width:'33%'}}>
              <Image style={{width:'100%', maxWidth, aspectRatio:1, resizeMode:'cover', marginTop:margin, borderColor:GLOBAL.style.borderColorSecondary, borderWidth:1, borderRadius:2}} source={{uri:header?.players?.right?.image}}/>
            </View>

          </View>
        }

        <View style={[{width:'100%', padding:GLOBAL.padding, paddingVertical:GLOBAL.padding/2}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

          <View style={[{width:'50%', paddingRight:GLOBAL.padding/2}, MainStyles.flexCenterStart]}>
          {this.renderName(header?.players?.left)}
          </View>

          <View style={[{width:'50%', paddingLeft:GLOBAL.padding/2}, MainStyles.flexCenterEnd]}>
          {this.renderName(header?.players?.right, 'right')}
          </View>
        </View>

        {
          !header?.record?.title ? null :
          <View style={[{width:'100%', padding:GLOBAL.padding/2, backgroundColor:GLOBAL.style.accent}, MainStyles.flexRow, MainStyles.flexCenter]}>

          <Text style={{fontSize:16, color:'white', fontFamily:GLOBAL.fonts.black, textTransform:'uppercase', opacity:1, textAlign:'center'}}>
          {header?.record?.title}
          </Text>

          </View>
        }



      </View>
    )
  }

  renderPlayer = (player, textAlign) => {
    var maxWidth = 180
    var borderRadius = 4
    return(
      <>
      <Image style={{width:'100%', aspectRatio:1, maxWidth, resizeMode:'cover'}} source={{uri:player?.image}}/>
      <View style={{width:'100%', padding:GLOBAL.padding/2, borderRadius:2, borderTopLeftRadius:0, borderTopRightRadius:0, borderBottomRightRadius:textAlign === 'left' ? borderRadius : borderRadius, borderBottomLeftRadius:textAlign !== 'left' ? borderRadius : borderRadius}}>
        <Text style={{fontSize:13, color:'black', fontFamily:GLOBAL.fonts.medium, textTransform:'uppercase', opacity:0.5, textAlign}}>
        {player.title}
        </Text>
        <Text style={{fontSize:16, color:'black', fontFamily:GLOBAL.fonts.black, textTransform:'uppercase', textAlign}}>
        {player.subtitle}
        </Text>
      </View>
      </>
    )
  }

  renderName = (player, textAlign) => {
    var maxWidth = 180
    var borderRadius = 4
    return(
      <View style={{width:'100%'}}>
        <Text style={{fontSize:16, color:'black', fontFamily:GLOBAL.fonts.medium, textTransform:'uppercase', opacity:0.5, textAlign}}>
        {player.title}
        </Text>
        <Text style={{fontSize:24, color:'black', fontFamily:GLOBAL.fonts.black, textTransform:'uppercase', marginTop:-5, textAlign}}>
        {player.subtitle}
        </Text>
      </View>
    )
  }

  renderImage = (player) => {
    var maxWidth = 180
    return(
      <Image style={{width:'100%', aspectRatio:1, maxWidth, resizeMode:'cover', borderRadius:100, backgroundColor:'rgba(255,255,255,0.04)'}} source={{uri:player?.image}}/>
    )
  }

  renderItem = ({item, index}) => {
    // console.log(item)
    // return <SectionItem item={item} index={index} mode='match_bios'/>
    var style = {fontFamily:GLOBAL.fonts.regular, fontSize:12, color:GLOBAL.style.color, paddingHorizontal:GLOBAL.padding/2}

    if(!item.values[2]){
      return(
        <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding/2, borderBottomWidth:1, borderBottomColor:GLOBAL.style.borderColorSecondary}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>
          <View style={[{flex:1}, MainStyles.flexCenterStart]}>
            <Text style={[style, {textAlign:'left'}]}>
            {item.values[0]}
            </Text>
          </View>
          <View style={[{width:120, backgroundColor:'rgba(0,0,0,0.01)', paddingVertical:GLOBAL.padding, paddingHorizontal:GLOBAL.padding/2}, MainStyles.flexCenter]}>
            <Text style={[style, {textAlign:'center', opacity:0.5}]}>
            {item.title}
            </Text>
          </View>
          <View style={[{flex:1}, MainStyles.flexCenterEnd]}>
            <Text style={[style, {textAlign:'right'}]}>
            {item.values[1]}
            </Text>
          </View>
        </View>
      )
    }else{
      return <SectionItem item={item} index={index} mode='match_bios'/>
    }


    // return null
  }

  renderSectionHeader = ({section}) => {
    var { title, type, players } = section
    var style = {fontFamily:GLOBAL.fonts.bold, fontSize:12, textTransform:'uppercase', color:GLOBAL.style.color, paddingHorizontal:GLOBAL.padding/2, opacity:0.5}
    if(players){
      return(
        <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding/2, backgroundColor:GLOBAL.style.borderColorSecondary}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>
          <View style={[{flex:1}, MainStyles.flexCenterStart]}>
            <Text style={[style, {textAlign:'left'}]}>
            {players[0]}
            </Text>
          </View>
          <View style={[{width:120, paddingVertical:GLOBAL.padding/2, paddingHorizontal:GLOBAL.padding/2}, MainStyles.flexCenter]}>
            <Text style={[style, {textAlign:'center', opacity:1, fontFamily:GLOBAL.fonts.black}]}>
            {title}
            </Text>
          </View>
          <View style={[{flex:1}, MainStyles.flexCenterEnd]}>
            <Text style={[style, {textAlign:'right'}]}>
            {players[1]}
            </Text>
          </View>
        </View>
      )
    }else{
      return(
        <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding/2, backgroundColor:GLOBAL.style.borderColorSecondary}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>
          <View style={[{width:'100%', backgroundColor:'rgba(0,0,0,0.01)', paddingVertical:GLOBAL.padding/2, paddingHorizontal:GLOBAL.padding/2}, MainStyles.flexCenter]}>
            <Text style={[style, {textAlign:'center', opacity:1, fontFamily:GLOBAL.fonts.black}]}>
            {title}
            </Text>
          </View>
        </View>
      )
    }
  }

  render = () => {
    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
