import { AppRegistry } from "react-native";
import App from "./App";
import './index.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVW64hdcykMWFtQqGD4hy3ahmHVATgn88",
  authDomain: "tennis-lwts.firebaseapp.com",
  projectId: "tennis-lwts",
  storageBucket: "tennis-lwts.appspot.com",
  messagingSenderId: "414782898270",
  appId: "1:414782898270:web:197a752cb2ed60a210f4cf",
  // measurementId: "G-D3SD6S9JJW"
  measurementId:"G-WVPSD2WN29",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

AppRegistry.registerComponent("App", () => App);

AppRegistry.runApplication("App", {
  rootTag: document.getElementById("root")
});
