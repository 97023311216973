/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  Pressable,
  Linking,
  Image,
} from 'react-native';

//Modules

//Components
import Tournaments from '../../screens/matches/tournaments.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

var isMobile = dimensions.width < 600

var accent = "#007BB2"
var accent_1 = "#36B9F2"
var color = "#121212"

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      activeView:'Live',
    }
  }

  async componentDidMount(){
    GLOBAL.external_host = this.props.theme

    var hideVideos = false
    var host = ''

    if(this.props.theme === 'TennisHead'){
      accent = '#1393CC'
      accent_1 = "#36B9F2"
      color = "#fff"
      host = 'Tennishead'
    }else if(this.props.theme === 'Tweener'){
      accent_1 = '#121212'
      accent = "#232528"
      color="#fff"
      host = 'Tweener'
    }else if(this.props.theme === 'ltr'){
      // alert('bingo')
      accent_1 = '#02BFF3' //Blue
      accent = '#20AC34' //Green
      accent_1 = '#127E22'
      GLOBAL.fonts = {
        ultra:'Tahoma-Bold',
        black:'Tahoma-Bold',
        bold:'Tahoma-Bold',
        medium:'Tahoma',
        regular:'Tahoma',
      }
      hideVideos = true
      host = 'LTR'
    }else if(this.props.theme === 'buzz'){
      host = 'Tennisbuzz'
      accent = '#caf34c'
      accent_1 = '#121212'
      GLOBAL.style.active_tab_color = '#121212'

      GLOBAL.fonts = {
        ultra:'Roboto-Black',
        black:'Roboto-Black',
        bold:'Roboto-Bold',
        medium:'Roboto-Medium',
        regular:'Roboto-Regular',
      }

    }else if(this.props.theme === 'infinity'){
      accent = '#1EB3E1'
      accent_1 = '#119FCB'
      host = 'Tennis Infinity'

      GLOBAL.style.active_tab = accent_1
      GLOBAL.style.active_tab_color = 'white'
    }

    if(this.props.theme){
      //Set theme
      GLOBAL.darkMode = false
      GLOBAL.style = GLOBAL.style_light
      GLOBAL.externalMode = true
      GLOBAL.style.color_accent_1 = accent_1
      GLOBAL.color_accent_1 = accent_1

      GLOBAL.style.active_tab = accent
    }



    await this.setState({loading:false, hideVideos, host})

    if(GLOBAL.hideNavbar){
      GLOBAL.hideNavbar()
    }
  }

  renderMain = () => {
    return(
      <View style={[{width:'100%', height:'100%'}, MainStyles.flexStartCenter]}>
      {this.props.theme ? this.renderPoweredBy() : null}
      <Tournaments/>
      </View>
    )
  }

  openTNNS = () => {
    Functions.openURL("https://tnnslive.com")
  }

  renderPoweredBy = () => {
    return(
      <Pressable onPress={this.openTNNS} style={[{width:'100%', backgroundColor:GLOBAL.style.active_tab, paddingVertical:5, paddingHorizontal:GLOBAL.padding, borderTopWidth:1, borderTopColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:12, color:GLOBAL.style.active_tab_color, textTransform:'uppercase', letterSpacing:0.4}}>
        Powered by <Text style={{fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.active_tab_color}}>TNNSLIVE.COM</Text>
        </Text>
      </Pressable>
    )
  }

  render = () => {
    var { loading } = this.state
    try{
      return loading ? null : this.renderMain()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
