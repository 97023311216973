/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  Picker,
  Pressable,
  Image,
  ScrollView
} from 'react-native';

//Modules
import {Helmet} from "react-helmet";
import Header from '../components/seo/header.js'
import { Hoverable } from "react-native-web-hover";

//Components
import NavBar from '../components/navigation/navbar.js'
import Background from '../components/general/background.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      id:'test',
      tournament:GLOBAL.tournamentsObj[this.props.route.params?.id] || null,
      activeView:'Live',
      title:'TNNS - Tennis Live Scores App - Grand Slam, ATP Tour, WTA Tour, ATP Challenger & ITF',
      description:'TNNS is the only tennis live scores app you need to follow all tennis scores, results, rankings, news, videos, tennis stats, streams and schedules of professional tennis, including Grand Slams, ATP Tour, WTA Tour, ATP Challenger Tour, ITF Tour and more.',
    }
  }

  async componentDidMount(){
    this.openStore()
    this.props.navigation.setOptions({title:this.state.title})
  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  openStore = () => {
    if(isMobile && isIOS){
      console.log('Sending user to App Store')
      //Send user to the app store
      Functions.openURL("https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724")
    }else if(isMobile && isAndroid){
      console.log('Sending user to Play Store')
      //Send user to the app store
      Functions.openURL("https://play.google.com/store/apps/details?id=com.tennisrn")
    }
  }

  setup = async (id) => {
    //Get tournament info
    var url = "https://us-central1-tennis-lwts.cloudfunctions.net/api_getTournament?="+id
    var data = await Functions.firestoreAPI(url)
    data.tournament_data.id = data.tournament_data.docId
    this.setState({tournament:data, tournament_data:data?.tournament_data})
  }

  getRankings = async (rankings) => {
    //
    this.setState({rankings})
  }

  onEmpty = () => {
    var { tournament_data, firstSetDone } = this.state

    if(!firstSetDone){
      var activeView = 'Live'
      if(tournament_data.completedDates.length === 0){
        activeView = 'Scheduled'
      }else{
        activeView = 'Completed'
      }
      this.setState({activeView, firstSetDone:true})
    }

  }

  pickMatch = async (match) => {
    console.log(match)
    await this.setState({match:match || null})
  }

  renderContent = () => {
    return (
      <>
      <Header title={this.state.title} description={this.state.description}/>
      {this.renderMain()}
      </>
    )
  }

  renderMain = () => {
    var height = 540
    if(GLOBAL.displayMode === 'mobile'){
      return(
        <ScrollView style={{flex:1}} contentContainerStyle={[{width:'100%', backgroundColor:'rgba(0,0,0,0.75)', zIndex:9999999}, MainStyles.flexCenter]}>
          <View style={[{width:'100%', padding:GLOBAL.padding, borderTopRightRadius:0, borderBottomRightRadius:0, backgroundColor:'white'}, MainStyles.flexCenterStart]}>
            <Text style={{fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:24}}>
            Come join the ultimate platform for tennis fans!
            </Text>
            <Text style={{fontFamily:GLOBAL.fonts.bold, color:GLOBAL.style.color, fontSize:16, marginTop:GLOBAL.padding / 2, opacity:0.5}}>
            Built by fans, for fans
            </Text>

            <Text style={{fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:14, marginTop:GLOBAL.padding*2, textTransform:'uppercase', marginBottom:4, opacity:0.5}}>
            KEY FEATURES
            </Text>
            <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:14, lineHeight:24}}>
            {"- Personalized news, scores and stats\n- Get notifications everytime your favorites play\n- Live chats during matches with other fans\n- Fantasy bracket competitions for every tour event\n- Orders of play in your timezone\n- Live PDF draws"}
            </Text>

            <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:14, marginTop:GLOBAL.padding * 2}}>
            Download the TNNS app to find out why it's the highest rated app in tennis!
            </Text>

            <Text style={{fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:14, marginTop:GLOBAL.padding*2, textTransform:'uppercase', marginBottom:4}}>
            Download now!
            </Text>
            <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            {this.renderDownloadButton('apple')}
            {this.renderDownloadButton('android')}
            </View>
          </View>
          {
            <View style={[{width:'100%', padding:GLOBAL.padding, backgroundColor:"#13074d", borderTopRightRadius:GLOBAL.borderRadius, borderBottomRightRadius:GLOBAL.borderRadius}, MainStyles.flexCenter]}>
              <Image style={{width:'100%', aspectRatio:1, resizeMode:'contain', borderRadius:20}} source={require('../assets/images/demosapp.webp')}/>
            </View>
          }
        </ScrollView>
      )
    }else{
      return this.renderMainOld()
    }
  }

  renderDownloadButton = (app) => {
    var image = ''
    var url = ''
    if(app === 'apple'){
      image = require('../images/apple_app.png')
      url = "https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724?platform=iphone"
    }else if(app === 'android'){
      url = "https://play.google.com/store/apps/details?id=com.tennisrn"
      image = require('../images/google_app.png')
    }

    return(
      <Hoverable style={{}}>
      {({ hovered }) => (
        <Pressable onPress={()=>window.open(url, '_blank')}>
        <Image style={{height:52, aspectRatio:150/60, marginRight:GLOBAL.padding, resizeMode:'contain', opacity:hovered ? 0.8 : 1}} source={image}/>
        </Pressable>
      )}
      </Hoverable>

      )
  }

  renderMainOld = () => {
    var highlights = [
    "- Faster Live Scores",
    "- Player & Match Notifications",
    "- Streams, Videos & Live Commentary",
    "- Free & Ad Free"
    ]
    return(
      <View style={[{flex:1, width:'100%', backgroundColor:GLOBAL.style_dark.backgroundColor}, MainStyles.flexCenter]}>
        <Pressable onPress={()=>GLOBAL.navigation.navigate('Home')}>
        <Image
        width={76} height={43}
        style={{width:120, aspectRatio:2, resizeMode:'contain', marginBottom:20}}
        source={require('../images/tennislive.png')}
        />
        </Pressable>
        <Text style={{fontSize:16, textTransform:'uppercase', letterSpacing:2, fontFamily:GLOBAL.fonts.regular, color:'white', opacity:0.5}}>
        Download the app
        </Text>
        <Image style={{maxWidth:1000, width:'100%', aspectRatio:2, resizeMode:'contain', marginTop:GLOBAL.padding}} source={{uri:"https://firebasestorage.googleapis.com/v0/b/tennis-lwts.appspot.com/o/web%2Fweb-phones.webp?alt=media&token=8dfbebea-d9e6-452a-a3a3-15d8c0821ee2"}}/>
        {highlights.map((item, index) => this.renderHighlight(item, index))}
        <View style={[MainStyles.flexRow, MainStyles.flexCenter]}>
          {this.renderButton("https://static.wixstatic.com/media/b3cd0e_a3a1c49c6484410e81059376c1ba87cb~mv2.png/v1/fill/w_162,h_50,al_c,q_95/appstorebutton.webp", "https://apps.apple.com/app/tnns-tennis-live-scores/id1547205724")}
          {this.renderButton("https://static.wixstatic.com/media/b3cd0e_d948f00a7a61417d9d759a061b4a7ca9~mv2.png/v1/fill/w_165,h_50,al_c,q_95/googleplay.webp", "https://play.google.com/store/apps/details?id=com.tennisrn")}
        </View>
      </View>
    )
  }

  renderButton = (img, url) => {
    return(
      <Pressable onPress={()=>Functions.openURL(url)} style={{width:160, aspectRatio:2, marginHorizontal:10, marginTop:GLOBAL.padding}}>
      <Image style={{height:'100%', width:'100%', resizeMode:'contain'}} source={{uri:img}}/>
      </Pressable>
    )
  }

  renderHighlight = (text, index) => {
    return(
      <Text key={index} style={{marginTop:GLOBAL.padding, fontSize:16, textTransform:'uppercase', letterSpacing:2, fontFamily:GLOBAL.fonts.bold, color:'white'}}>
      {text}
      </Text>
      )
  }

  render() {
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
