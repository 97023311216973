/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Image,
  Pressable
} from 'react-native';

//Modules
import { Link, StackActions } from '@react-navigation/native';

//Components
// import Image from '../general/image.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import GLOBAL from '../../helpers/global.js'
import Functions from '../../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      data:this.props.data,
      index:this.props.index,
    }
  }

  async componentDidMount(){

  }

  async componentDidUpdate(prevProps){
    if(prevProps.data !== this.props.data){
      this.setState({data:this.props.data})
    }
  }

  async componentWillUnmount(){

  }

  openTournament = () => {
    var { data, index } = this.state
    Functions.openTournament(data)
    // GLOBAL.navigation.push('Tournament', {id:data.id, name:data.name.split(" ").join("-"), city:data.city?.split(" ").join("-") || '', type:data.type?.split(" ").join("-") || '', codes:data.codes?.split(" ").join("-") || ''})
  }


  renderContent = () => {
    var { data, index } = this.state
    var isEven = index % 2 === 0
    return(
      <Pressable key={data.id} onPress={this.openTournament} style={{width:'100%', backgroundColor:isEven ? GLOBAL.style.alternatingBackground : 'transparent'}}>
        <View style={[{width:'100%', padding:GLOBAL.padding / 1}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
          <View style={[{flexShrink:1}, MainStyles.flexColumn, MainStyles.flexCenterStart]}>
            <Text style={{fontFamily:GLOBAL.fonts.semibold, color:GLOBAL.style.color_font, fontSize:14, marginBottom:GLOBAL.padding / 4, textTransform:'uppercase', letterSpacing:1}}>
              {data.name}
            </Text>
            {data.subtitles.map((item, index) => this.renderSubtitle(item, index))}
          </View>
          {data.logos.map((item, index) => this.renderImage(item, index))}
        </View>
      </Pressable>
    )
  }

  renderImage = (item, index) => {
    var size = item.size || 20
    return(
      <Image
        key={item.url}
        style={{height:size, width:size*item.aspectRatio, marginLeft:GLOBAL.padding / 2, tintColor:item.tintColor || null, resizeMode:'contain'}}
        source={{uri:item.url}}
      />
    )
  }

  renderSubtitle = (item, index) => {
    return(
      <Text key={index} style={{flexShrink:1, flexWrap:'wrap', fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color_font, fontSize:14, opacity:0.5, marginTop:2.5}}>
      {item}
      </Text>
    )
  }

  render() {
    try{
      return this.renderContent()
    }catch(e){
      console.error(e)
      return null
    }
  }

}
