/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  SectionList,
  Dimensions,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from 'react-native';

//Modules
// import { createFilter } from 'react-native-search-filter';
// import { firebase } from '@react-native-firebase/auth';
// import firestore from '@react-native-firebase/firestore';

//Components
import Match from '../../components/matches/match.js'
import Tabs from '../../components/general/tabs.js'
import MatchList from '../../components/matches/matchlist.js'
import EmptyState from '../../components/general/emptystate.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

var moment = require('moment-timezone');
var CircularJSON = require('circular-json')

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      loading:true,
      tournaments:[],
      activeView:GLOBAL.todayActiveTitle || 'ATP',
    }
  }

  async componentDidMount(){
    clearTimeout(this.updateData)
    setTimeout(async () => {
      this.getData()
    }, 50)
  }

  async componentDidUpdate(prevProps){
    // if(CircularJSON.stringify(prevProps.data) !== CircularJSON.stringify(this.props.data)){
    //   this.setup()
    // }
    if(prevProps.appState !== this.props.appState){
      this.setState({loading:true})

      if(this.props.appState === 'background'){
        this.cancelListeners()
      }
      if(this.props.appState === 'active'){
        this.getData()
      }
    }

    if(prevProps.isVisible !== this.props.isVisible){
      if(this.props.isVisible){
        this.getData()
      }else{
        this.cancelListeners()
        this.setState({loading:true})
      }
    }
  }

  async componentWillUnmount(){
    clearTimeout(this.updateData)
    this.cancelListeners()
  }

  cancelListeners = async () => {
    if(this.listenMatches){await this.listenMatches()}
    clearTimeout(this.updateData)
  }

  getData = async () => {
    clearTimeout(this.updateData)

    var { activeView } = this.state

    try{
      var doc = "live_"+activeView.toLowerCase()

      // this.listenMatches = await firebase.firestore()
      // .collection('summaries_sr').doc(doc)
      // .onSnapshot(this.handleData)

      var url = "https://us-central1-tennis-lwts.cloudfunctions.net/api_getTourLiveMatches?=all?="+GLOBAL.matchCardStyle_live
      var tournaments = await Functions.firestoreAPI(url)

      await tournaments.sort((a, b) => (Number(a.categoryRank) > Number(b.categoryRank)) ? 1 : -1)

      await this.setState({tournaments, loading:false})

      //Change update time depending on values

      if(this.props.onEmpty && tournaments.length === 0){
        this.props.onEmpty()
      }
    }catch(e){
      console.log("Error with live", e)
    }

    var updateTime = tournaments.length === 0 ? 30 * 1000 : (10 * 1000) //10s if live, 30s if none live

    // console.log('Updating again in', updateTime)

    this.updateData = setTimeout(()=>{
      this.getData()
    }, updateTime)
  }

  handleData = (doc) => {
    var matches = doc.data()?.matches || {}
    this.setup(matches)
  }

  setup = async (matchesRaw) => {
    var tournamentNames = GLOBAL.tournamentNames || {}
    // var matchesRaw = GLOBAL.liveMatches || {}
    var tournamentsObj = {}
    var tournaments = []

    for (let [key, value] of Object.entries(matchesRaw)) {
      //Try to attach a court ranking guess
      var court = value.court_name || ''

      //If court has number on it, use that
      var courtRanking = court.includes("Court ") ? Number(court.split("Court ")[1])+ 20 : 900

      //If court has keywords for main courts, push up
      if(court.includes('Center') || court.includes('Centre') || court.includes('Central')){
        courtRanking = 1 + Number(court.length / 100)
      }
      if(court.includes('Arena') || court.includes('Stadium')){
        courtRanking = 2 + Number(court.length / 100)
      }

      value.courtRanking = courtRanking

      //Tournament title
      var title = tournamentNames[value.season_id] || value.competition_name.split(',')[0]

      //Adjustment if the tournament is a merged ATP / WTA event
      if(tournamentNames[value.season_id]){
        if(value.season_name.includes('ATP') && !value.season_name.includes('Challenger')){
          title = title + " - ATP"
        }
        if(value.season_name.includes('WTA')){
          title = title + " - WTA"
        }
      }

      //Add to obj
      if(!tournamentsObj[title]){
        //Get the ranking for tournament (ATP, WTA, Challenger, ITF), try get level as well

        var category = value.category
        var categoryRank = 10

        if(category === 'ATP'){categoryRank = 1}
        if(category === 'WTA'){categoryRank = 2}
        if(category === 'Challenger'){categoryRank = 3}
        if(category === 'ITF Men'){categoryRank = 4}
        if(category === 'ITF Women'){categoryRank = 5}

        //Level rank (within categories)
        var levelRank = 100
        if(value.level === 'atp_1000'){levelRank = 2}
        if(value.level === 'atp_500'){levelRank = 3}
        if(value.level === 'atp_250'){levelRank = 4}

        if(value.level === 'wta_1000'){levelRank = 2}
        if(value.level === 'wta_500'){levelRank = 3}
        if(value.level === 'wta_250'){levelRank = 4}

        //Setup
        tournamentsObj[title] = {
          title,
          category,
          categoryRank,
          levelRank,
          id_sr:value.season_id,
          data:[],
        }
      }
      //Push match to data
      tournamentsObj[title].data.push(value)
    }

    //Within each tournament, sort matches by courtRanking and then push to final array
    for (let [key, value] of Object.entries(tournamentsObj)) {

      //Sort matches by court rank
      await value.data.sort((a, b) => (a.courtRanking > b.courtRanking) ? 1 : -1)

      //Push to tournaments
      tournaments.push(value)
    }

    //Sort tournaments by name
    await tournaments.sort((a, b) => (a.title < b.title) ? 1 : -1)

    //Sort tournaments by levelRank
    await tournaments.sort((a, b) => (a.levelRank < b.levelRank) ? 1 : -1)

    //Sort tournaments by categoryRank
    await tournaments.sort((a, b) => (a.categoryRank < b.categoryRank) ? 1 : -1)

    this.setState({tournaments, loading:false})
  }

  //Renders
  changeTab = async (activeView) => {
    GLOBAL.todayActiveTitle = activeView
    this.setState({loading:true})
    setTimeout(async () => {
      await this.setState({activeView})
      this.getData()
    }, 50)
  }

  changeTabIndex = async (activeIndex) => {
    GLOBAL.todayActiveIndex = activeIndex
  }

  renderTabs = () => {
    var tabs = [
      {title:'ATP'},
      {title:'WTA'},
      {title:'Challenger'},
      {title:'ITF'},
      {title:'Other'},
    ]

    // return <Tabs data={tabs} small lite onTapTitle={this.changeTab} onTapIndex={this.changeTabIndex} startingIndex={GLOBAL.todayActiveIndex}/>
    return null
  }

  renderCategories = () => {
    var { tournaments, activeView } = this.state

    if(tournaments[0]?.data?.length === 0 || tournaments?.length === 0){
      return <EmptyState text='No matches on court right now'/>
    }else{
      return <MatchList data={tournaments} mode='live' TNNSPro={true} forceShowCourt enableCarousel={true}  small={false}/>
    }
  }


  render() {
    var { loading } = this.state
    return(
      <View style={{width:'100%', marginTop:0, height:'100%'}}>
        {this.renderTabs()}
        {!loading ? this.renderCategories() : <ActivityIndicator size='small' color='white'/>}
      </View>
    )
  }

}
