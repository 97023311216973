/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Button,
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";

import { NavigationContainer } from '@react-navigation/native';
// import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

//Components
import Tabs from '../components/general/tabs.js'
import Background from '../components/general/background.js'
import Loading from '../components/general/loading.js'
import SEOTitle from '../components/general/seotitle.js'
import BottomNavbar from '../components/navigation/bottomnavbar.js'
import Matches from './tournament/matches.js'
import Draws from './tournament/draws.js'
import Info from './tournament/info.js'
import Players from './tournament/players.js'
import Header from '../components/seo/header.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      activeView:'Live',
      id:this.props.route?.params?.id,
    }
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {

  }

  setup = async () => {
    var { id } = this.state
    var params = {
      id,
      mode:'tournament',
    }

    var data_r = await Functions.tnnsAPI(params)
    var data = data_r?.data || {}

    var {
      tabs,
      first_tab,
      first_data,
      header,
      draws,
      information,
      formats,
      formats_summary,
      draws_message,
      seo
    } = data

    if(data?.id){
      id = data.id
    }

    var categories = seo.categories || {}
    var categories_string = Object.keys(categories).join(" & ").replace("_","")

    var title = seo.season + " " + seo.title + " " + categories_string.toUpperCase() + " - Tournament Live Scores, Results, Streams, Draws & Schedules"
    var description = 'Live scores, results, live streams, draws, schedules & more for the '+seo.season + " " + seo.title
    this.props.navigation.setOptions({title})

    document.title = title

    this.setState({title, description, data, draws, draws_message, header, tabs, information, formats, formats_summary, first_tab, activeView:first_tab.toLowerCase(), id, loading:false})
  }

  changeTab = (tab) => {
    this.setState({activeView:tab.id})
  }



  renderContent = () => {
    var { loading } = this.state
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    return(
      <>
      {
        // <Header title={this.state.title} description={this.state.description}/>
      }
      <Background navigation={this.props.navigation} page={'Scores'}>
      {loading ? <Loading/> : this.renderMain()}
      </Background>
      <BottomNavbar/>
      </>
    )
  }

  renderHeader = () => {
    var { header } = this.state
    return(
      <View style={[{width:'100%', paddingVertical:GLOBAL.padding, borderColor:GLOBAL.style.borderColor, marginBottom:GLOBAL.padding*0}, MainStyles.flexCenter]}>
        <View style={[{width:'100%', maxWidth:GLOBAL.maxWidth}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding}, MainStyles.flexCenterStart]}>
            <Text style={{fontSize:20, color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.black, marginBottom:GLOBAL.padding / 4, textTransform:'uppercase'}}>
            {header?.title}
            </Text>
            <Text style={{fontSize:14, color:GLOBAL.style.color, opacity:0.67, fontFamily:GLOBAL.fonts.regular}}>
            {header?.subtitle}
            </Text>
          </View>
        </View>
      </View>
      )
  }

  renderMain = () => {
    var { tabs, first_tab, id, draws, formats, formats_summary, information, draws_message, activeView } = this.state

    var tabs = [
      {id:'summary', title:'All'},
      {id:'live', title:'Live'},
      {id:'completed', title:'Completed'},
      {id:'scheduled', title:'Scheduled'},
      {id:'draws', title:'Draws'},
      {id:'players', title:'Players'},
      {id:'info', title:'Info'},
    ]

    var render = null
    if(activeView === 'live'){render = <Matches id={id} submode='live'/>}
    else if(activeView === 'completed'){render = <Matches id={id} submode='completed'/>}
    else if(activeView === 'scheduled'){render = <Matches id={id} submode='scheduled'/>}
    else if(activeView === 'draws'){render = <Draws id={id} draws={draws} draws_message={draws_message}/>}
    else if(activeView === 'summary'){render = <Matches id={id} submode='summary' formats={formats_summary}/>}
    else if(activeView === 'players'){render = <Players id={id} formats={formats_summary}/>}
    else if(activeView === 'info'){render = <Info id={id} data={information}/>}

    return(
      <View style={[{width:'100%'}, MainStyles.flexStartCenter]}>
      {this.renderHeader()}
        <View style={{maxWidth:GLOBAL.maxWidth, width:'100%'}}>
          <View style={[{width:'100%', borderTopWidth:1, borderTopColor:GLOBAL.style.borderColorSecondary, marginVertical:0}, MainStyles.flexCenter]}>
            <Tabs tabs={tabs} onChange={this.changeTab} active_tab={activeView}/>
          </View>
          <View style={[{width:'100%', borderTopWidth:1, borderTopColor:GLOBAL.style.borderColorSecondary, borderRadius:0, maxWidth:GLOBAL.maxWidth}, MainStyles.flexRow, MainStyles.flexCenterStart]}>
            {render}
            {
              // this.renderRight()
            }
          </View>
        </View>
      </View>
    )
  }

  renderRight = () => {
    var { information, id } = this.state
    return(
      <View style={{width:600, minHeight:200,borderLeftWidth:1, borderLeftColor:GLOBAL.style.borderColor}}>
        <Text style={{fontSize:20, color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.black, textTransform:'uppercase', paddingLeft:GLOBAL.padding, marginBottom:GLOBAL.padding, marginTop:GLOBAL.padding}}>
        Info
        </Text>
        <Info id={id} data={information}/>
      </View>
    )
  }

  render = () => {
    var { loading } = this.state

    // const linking = {
    //   prefixes: ['https://downloadtennis.com', 'https://www.downloadtennis.com', "https://tnnsweb.web.app/", 'tennisrn://', "http://localhost:3001", "https://tnnslive.com", "https://www.tnnslive.com"],
    //   config: {
    //     screens: {
    //       TournamentLive:'',
    //     }
    //   },
    // }

    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
