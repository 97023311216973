/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Button,
  FlatList,
  SectionList,
  Pressable,
  Image
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";

import { NavigationContainer } from '@react-navigation/native';
// import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

//Components
import Tabs from '../components/general/tabs.js'
import Icon from '../components/general/icon.js'
import SectionHeader from '../components/general/sectionheader.js'
import Background from '../components/general/background.js'
import Loading from '../components/general/loading.js'
import SEOTitle from '../components/general/seotitle.js'
import EmptyState from '../components/general/emptystate.js'
import Player from '../components/general/player.js'
import Match from '../components/matches/match.js'
import BottomNavbar from '../components/navigation/bottomnavbar.js'
import H2H from './match/h2hbios.js'
import Preview from './match/preview.js'
import Recap from './match/recap.js'
import Stats from './match/stats.js'
import Watch from './match/watch.js'
import Form from './player/form.js'

import Header from '../components/seo/header.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      sections:[],
      loading:true,
      refreshing:true,
      active_tab:'all',
      search_term:'',
      options:[
        {
          title:'Official Singles',
          data:[
            {id:'1', title:'ATP Singles', active:true, tour:'atp', type:'official', format:'singles'},
            {id:'2', title:'ATP Singles Race to Turin', active:true, tour:'atp', type:'race', format:'singles'},
            {id:'3', title:'WTA Singles', active:true, tour:'wta', type:'official', format:'singles'},
            {id:'4', title:'WTA Singles Race', active:true, tour:'wta', type:'race', format:'singles'},
          ]
        },
        {
          title:'Official Doubles',
          data:[
            {id:'5', title:'ATP Doubles', active:true, tour:'atp', type:'official', format:'doubles'},
            {id:'6', title:'ATP Doubles Race to Turin', active:true, tour:'atp', type:'race', format:'doubles'},
            {id:'7', title:'WTA Doubles', active:true, tour:'wta', type:'official', format:'doubles'},
            {id:'8', title:'WTA Doubles Race', active:true, tour:'wta', type:'race', format:'doubles'},
          ]
        }
      ]
    }
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {

  }

  setup = async (params) => {
    this.setState({loading:true})

    var { tour, format, type } = this.props.route.params

    if(!params){
      params = {}
    }

    params.tour = tour
    params.format = format
    params.type = type
    params.mode = 'rankings'

    params.favorite_players = GLOBAL.favorite_players

    var data_r = await Functions.tnnsAPI(params)
    var data = data_r?.data || {}

    var { header, rankings } = data

    if(format === 'singles'){format = 'Singles'}
    else if(format === 'doubles'){format = 'Doubles'}

    if(type === 'official'){type = 'Official'}
    else if(type === 'race'){type = 'Race'}

    var title = tour.toUpperCase() + " " + format + ' ' + type + ' - Tennis Rankings - 2022'
    var description = "Official "+tour+" Rankings of the world's best tennis players, including rankings, points, player movement & more."

    this.props.navigation.setOptions({title})

    this.setState({title, description, header, rankings, loading:false})
  }

  changeView = (data) => {
    var active_tab = data.id
    this.setState({active_tab})

    var params = {}
    if(data.id === 'favorites'){
      params.only_favorites = true
    }

    this.setup(params)
  }

  renderRankings = () => {
    var { rankings } = this.state
    var data = rankings
    // var data = (rankings || []).filter(createFilter(this.state.search_term, ["name", "country"]))
    return(
      <View style={[{width:'100%', flex:1}]} animation='fadeIn' duration={240}>
        <FlatList
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:32}}
          data={data || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          ListEmptyComponent={this.renderEmpty}
          initialNumToRender={20}
          ListHeaderComponent={this.renderListHeader}
        />
      </View>
    )
  }

  renderEmpty = () => {
    return <EmptyState/>
  }

  renderItem = ({item, index}) => {
    return <Player data={item} index={index}/>
  }

  renderTabs = () => {
    var tabs = [
      {id:'all', title:'All Players'},
      {id:'favorites', title:'Favorite Players'}
    ]
    return(
      <View style={{width:'100%', backgroundColor:'transparent'}}>
        <Tabs tabs={tabs} onChange={this.changeView} active_tab={this.state.active_tab}/>
      </View>
    )
  }

  renderSearch = () => {
    // return <SearchBar onChangeText={(search_term)=>this.setState({search_term})} placeholder="Search for a player or country"/>
    return null
  }

  renderListHeader = () => {
    var style = {fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:12, textTransform:'uppercase'}
    return(
      <View style={[{width:'100%', height:28, backgroundColor:GLOBAL.style.backgroundColor, paddingHorizontal:GLOBAL.padding}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
        <Text style={[style, {width:50}]}>
        Rank
        </Text>

        <Text style={[style, {flex:1, paddingHorizontal:GLOBAL.padding}]}>
        Player
        </Text>

        <Text style={[style, {}]}>
        Points
        </Text>
      </View>
    )
  }

  renderRankingOptions = () => {
    var { options } = this.state
    return(
      <View style={{width:'100%', flex:1}}>
        {this.renderListHeader()}
        <SectionList
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:32}}
          sections={options || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderRankingItem}
          renderSectionHeader={this.renderSectionHeader}
          initialNumToRender={20}
        />
      </View>
    )
  }

  renderRankingItem = ({item, index}) => {
    var backgroundColor = index%2 ? GLOBAL.style.background_alternating : 'transparent'

    var size = 48
    var scale = 1
    var height = 90
    var onPress = item.ltr ? ()=>Functions.openURL(item.link) : ()=>GLOBAL.navigation.navigate('Ranking', {tour:item.tour, format:item.format, type:item.type})

    return(
      <Pressable onPress={onPress} style={[{width:'100%', paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding/1, backgroundColor:'transparent', borderTopColor:GLOBAL.style.borderColor, borderTopWidth:index > 0 ? 1 : 0}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>

        <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:14, color:GLOBAL.style.color}}>
        {item.title}
        </Text>


        <View style={[MainStyles.flexRow, MainStyles.flexCenter]}>
          {
            !item.ltr ? null :
            <Image
            style={[{height:24, width:24, resizetour:'contain',position:'absolute', right:GLOBAL.padding*1.5}]}
            source={{uri:"https://live-tennis.eu/ltr_logo.png"}}
            />
          }
          <Icon icon="chevron-forward" opacity={0.5} size={14}/>
        </View>

      </Pressable>
    )
  }

  renderSectionHeader = ({section}) => {
    var { title } = section
    return(
      <SectionHeader title={title} />
    )
  }

  renderContent = () => {
    var { loading } = this.state
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var title = "Live Tennis Scores, Results, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var description = "Latest tennis live scores, results and schedules for Grand Slam, ATP, WTA, Challenger and ITF events."
    return(
      <>
      <Header title={this.state.title} description={this.state.description}/>
      <Background navigation={this.props.navigation} page={'Rankings'}>
      {loading ? <Loading/> : this.renderMain()}
      </Background>
      <BottomNavbar/>
      </>
    )
  }

  renderHeader = () => {
    var { header, match_card } = this.state
    return(
      <View style={[{width:'100%', paddingVertical:GLOBAL.padding, backgroundColor:GLOBAL.style.cardColor, borderRadius:0, borderWidth:0, borderColor:GLOBAL.style.borderColor, marginBottom:GLOBAL.padding}, MainStyles.flexCenter]}>
        <View style={[{width:'100%', maxWidth:GLOBAL.maxWidth}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          <View style={[{flex:1, paddingHorizontal:GLOBAL.padding}, MainStyles.flexCenterStart]}>
            <Text style={{fontSize:24, color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.ultra, textTransform:'uppercase', marginBottom:GLOBAL.padding / 4}}>
            { header?.title || this.props.route.params.title}
            </Text>
            <Text style={{fontSize:14, color:GLOBAL.style.color, opacity:0.65, fontFamily:GLOBAL.fonts.regular}}>
            {header?.subtitle}
            </Text>
          </View>
        </View>
      </View>
      )
  }

  renderMain = () => {
    var { activeView, tabs, activeView, id, isLive, abbreviations, players, match_card, stream_data } = this.state

    var render = null
    if(activeView === 'watch'){render = <Watch data={stream_data}/> }
    if(activeView === 'stats'){render = <Stats tabLabel={'Stats'}  id={id} abbreviations={abbreviations} isLive={isLive}/> }
    if(activeView === 'recap'){render = <Recap tabLabel={'Recap'}  id={id} players={players} isLive={isLive}/> }
    if(activeView === 'chat'){render = null }
    if(activeView === 'preview'){render = <Preview tabLabel={'Preview'}  forceTab={this.forceTab} id={id}/> }
    if(activeView === 'h2h&bios'){render = <H2H tabLabel={'H2H & Bios'}  id={id}/>}
    if(activeView === 'form'){render = <Form tabLabel={'Form'}  players={players}/> }

    return(
      <View style={[{width:'100%'}, MainStyles.flexStartCenter]}>
      {this.renderHeader()}
        <View style={{maxWidth:GLOBAL.maxWidth, width:'100%'}}>
          <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexCenterStart]}>
            {this.renderRankings()}

          </View>
        </View>
      </View>
    )
  }

  renderRight = () => {
    if(GLOBAL.displayMode === 'web'){
      return(
        <View style={{width:400, minHeight:300, borderRightWidth:1, borderRightColor:GLOBAL.style.borderColor}}>
          {this.renderRankingOptions()}
        </View>
      )
    }else{return null}

  }

  render = () => {

    // const linking = {
    //   prefixes: ['https://downloadtennis.com', 'https://www.downloadtennis.com', "https://tnnsweb.web.app/", 'tennisrn://', "http://localhost:3001", "https://tnnslive.com", "https://www.tnnslive.com"],
    //   config: {
    //     screens: {
    //       TournamentLive:'',
    //     }
    //   },
    // }

    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
