/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Button,
  Image,
  Pressable,
  ActivityIndicator,
  Dimensions
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";
import { Hoverable } from "react-native-web-hover";
import LinearGradient from 'react-native-web-linear-gradient';

//Components
import Background from '../components/general/background.js'
import SEOTitle from '../components/general/seotitle.js'
import BottomNavbar from '../components/navigation/bottomnavbar.js'

import Scores from './matches/scores.js'

import Banner from '../components/widgets/scores.js'
import Tournaments from '../components/widgets/tournaments.js'

import Match from '../components/matches/match.js'
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

const axios = require("axios");
const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      activeView:'Live',
      active_tab:'tour',
      components:{},
      latest:[],
    }
  }

  //


  renderContent = () => {
    var { loading } = this.state
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var title = "Free tennis widgets - Get live score, results & schedule free tennis widgets for your site - TNNS Live"
    var subtitle = "Take advantage of TNNS' free tennis widgets for live scores, results, highlight videos and more smart widgets designed to provide full coverage of any tennis event!"
    return(
      <>
      <SEOTitle title={title}/>
      <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="description" content={subtitle}/>
      </Helmet>
      <Background navigation={this.props.navigation} page="Widget"
      // title='Free widgets'
      // icon={require('../assets/icons/ball.png')}
      >
        {loading ? this.renderLoading() : this.renderMain()}
      </Background>
      <BottomNavbar/>
      </>
    )
  }

  openBuilder = (id) => {
    // alert('bild')
    this.props.navigation.navigate('WidgetBuilder', {id})
  }

  renderMain = () => {
    var { activeView, latest } = this.state

    var title_style = {fontFamily:GLOBAL.fonts.bold, flex:1, color:GLOBAL.style.color, fontSize:17, marginTop:GLOBAL.padding*3, marginBottom:GLOBAL.padding/2}

    return(
      <View style={[{width:'100%', padding:GLOBAL.padding, borderTopRightRadius:0, borderBottomRightRadius:0, backgroundColor:'white'}, MainStyles.flexCenterStart]}>
        {
          // <Text style={{fontFamily:GLOBAL.fonts.black, color:GLOBAL.style.color, fontSize:24}}>
          // Get free tennis widgets for your website
          // </Text>
        }
        <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:14, marginTop:GLOBAL.padding / 2, opacity:1, lineHeight:20}}>
        TNNS offers <Text style={{fontFamily:GLOBAL.fonts.bold}}>free tennis widgets</Text> for websites who are looking to give their audience access to live scores, results, match highlights and more.
        {"\n\n"}
        These widgets can be customized to match the styling of your site.  To get your own free widget tap <Text style={{fontFamily:GLOBAL.fonts.bold}}>Customize Widget</Text> on either of the widgets below.
        </Text>

        {
          // <Text style={[title_style]}>
          // KEY WIDGET FEATURES
          // </Text>
          // <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:14, lineHeight:24}}>
          // {"- Free and ready to drag and drop into your site\n- Fully customizable - change the colors to match your site\n- Include live scores, results, schedules and links to video highlights\n- Full coverage of all tennis events from Grand Slams to ITF events\n- The most comprehensive tennis widgets available, built specifically for tennis fans"}
          // </Text>
        }

        {
          // <Text style={[title_style, {marginTop:GLOBAL.padding * 2}]}>
          // CUSTOM WIDGETS
          // </Text>
          //
          // <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:14, lineHeight:24}}>
          // Got a custom tennis widget in mind that you'd like to include on your site? Contact the TNNS team to talk about it more!
          // </Text>
          //
          // <View style={[{width:'100%', marginTop:GLOBAL.padding*2}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          //   <Hoverable style={{}}>
          //   {({ hovered }) => (
          //     <Pressable
          //     onPress={()=>window.open("https://oa4l98lgyn7.typeform.com/to/HDu357lG", '_blank')}
          //     style={[{backgroundColor:hovered ? GLOBAL.colors.button_hover : GLOBAL.colors.button, height:GLOBAL.button_height, marginRight:0, paddingHorizontal:GLOBAL.padding*2, borderRadius:GLOBAL.borderRadius}, MainStyles.flexCenter]}>
          //       <Text style={{fontSize:12, color:'white', fontFamily:GLOBAL.fonts.black, textTransform:'uppercase'}}>
          //       Contact TNNS to get your free widgets
          //       </Text>
          //     </Pressable>
          //   )}
          //   </Hoverable>
          // </View>
        }

        <View style={[{width:'100%', marginBottom:GLOBAL.padding*2}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          <Text style={[title_style]}>
          Scores Banner Widget
          </Text>
          {this.renderButton("Customize widget", ()=>this.openBuilder('scores'))}
        </View>
        <View style={{width:'100%', borderColor:GLOBAL.style.borderColor, borderWidth:1, borderBottomWidth:0}}>
          <Banner theme={"buzz"} external/>
        </View>

        {
          // <Text style={[title_style]}>
          // Active tournaments & calendar widget
          // </Text>
          // <View style={{width:'100%', borderColor:GLOBAL.style.borderColor, borderWidth:1}}>
          //   <Tournaments/>
          // </View>
        }


        {
          // <>
          // <View style={[{width:'100%', marginBottom:GLOBAL.padding*2, marginTop:GLOBAL.padding*2}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          //   <Text style={[title_style]}>
          //   Full Scores Widget
          //   </Text>
          //   {this.renderButton("Customize widget", ()=>this.openBuilder('allscores'))}
          // </View>
          //
          // <View style={{width:'100%', borderColor:GLOBAL.style.borderColor, borderWidth:1}}>
          // <Scores/>
          // </View>
          // </>
        }



      </View>
    )
  }

  renderButton = (text, onPress) => {
    return(
      <View style={[{marginTop:GLOBAL.padding*2}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        <Hoverable style={{}}>
        {({ hovered }) => (
          <Pressable
          onPress={onPress}
          style={[{borderColor:hovered ? GLOBAL.colors.button_hover : GLOBAL.colors.button, borderWidth:2, height:42, marginRight:0, paddingHorizontal:GLOBAL.padding*1.5, borderRadius:GLOBAL.borderRadius}, MainStyles.flexCenter]}>
            <Text style={{fontSize:12, color:hovered ? GLOBAL.colors.button_hover : GLOBAL.colors.button, fontFamily:GLOBAL.fonts.black, textTransform:'uppercase'}}>
            Build your own free widget
            </Text>
          </Pressable>
        )}
        </Hoverable>
      </View>
    )
  }

  renderArticle = (item, index) => {
    var onPress = ()=>window.open(item.url, '_blank')

    var logo = item.image
    // var onPress = ()=>GLOBAL.navigation.push('Tournament', {id:item.id})

    var size = 14

    var aspectRatio = 1.67

    if(index === 0){
      var width = GLOBAL.column_width - 32
      return(
        <Hoverable style={{width:'100%'}}>
        {({ hovered }) => (
          <Pressable onPress={onPress} style={[{width:'100%', padding:GLOBAL.padding/1.5, marginBottom:GLOBAL.padding, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, backgroundColor:hovered ? 'rgba(0,0,0,0.05)' : 'transparent', borderRadius:GLOBAL.borderRadius}, MainStyles.flexStartStart]}>

            <Image
            style={{width:'100%', aspectRatio, borderRadius:4, overflow:'hidden', resizeMode:'cover', opacity:1}}
            source={{uri:logo}}
            // source={require('../../assets/icons/news0.png')}
            />

            <View onPress={onPress} style={[{flex:1, paddingVertical:GLOBAL.padding/1.5, paddingBottom:0, marginLeft:-GLOBAL.padding/1.5, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, borderRadius:GLOBAL.borderRadius}, MainStyles.flexRow, MainStyles.flexStartStart]}>

              <View style={[{flex:1, paddingHorizontal:GLOBAL.padding}, MainStyles.flexStartStart]}>
                <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:20, textAlign:'left', marginBottom:GLOBAL.padding/4}}>
                {item.title}
                </Text>
                <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:12, flex:1, opacity:0.5, marginTop:GLOBAL.padding/2}}>
                {item?.author?.title} · {item?.time}
                </Text>
              </View>

            </View>

          </Pressable>
        )}
        </Hoverable>
      )
    }else{
      return(
        <Hoverable style={{width:'100%'}}>
        {({ hovered }) => (
          <Pressable onPress={onPress} style={[{width:'100%', padding:GLOBAL.padding/1.5, paddingVertical:GLOBAL.padding/1.5, borderTopColor:GLOBAL.style.borderColorSecondary, borderTopWidth:index > 0 ? 1 : 0, backgroundColor:hovered ? 'rgba(0,0,0,0.05)' : 'transparent', borderRadius:GLOBAL.borderRadius}, MainStyles.flexRow, MainStyles.flexStartStart]}>

            {
              !logo ? null :
              <Image
              style={{width:'33%', aspectRatio, borderRadius:4, overflow:'hidden', resizeMode:'cover', opacity:1}}
              source={{uri:logo}}
              // source={require('../../assets/icons/news0.png')}
              />
            }

            <View style={[{flex:1, height:'100%', paddingHorizontal:GLOBAL.padding}, MainStyles.flexStartStart]}>
              <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, lineHeight:22, fontSize:14, textAlign:'left', marginBottom:GLOBAL.padding/4, opacity:1}}>
              {item.title}
              </Text>
              <Text style={{fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.color, fontSize:12, flex:1, opacity:0.5, marginTop:GLOBAL.padding/2}}>
              {item?.author?.title} · {item?.time}
              </Text>
            </View>

          </Pressable>
        )}
        </Hoverable>
      )
    }
  }

  renderLoading = () => {
    return(
      <View
      style={[{flex:1, width:'100%', padding:GLOBAL.padding}, MainStyles.flexCenter]}
      >
        <ActivityIndicator size='small' color={GLOBAL.style.color_font}/>
      </View>
    )
  }

  render = () => {
    var { loading } = this.state
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
