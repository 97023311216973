/* @flow */

import React, { PureComponent } from 'react';


//Modules
import ReactCountryFlag from "react-country-flag"

import MainStyles from '../../helpers/mainstyles.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {

    }
  }

  async componentDidMount(){
    this.setup()
  }

  async componentDidUpdate(prevProps){
    if(prevProps.width !== this.props.width || prevProps.height !== this.props.height || prevProps.countryCode !== this.props.countryCode || prevProps.flag !== this.props.flag){
      this.setup()
    }
  }

  setup = () => {
    var { width, height, countryCode } = this.props

    //Corrections
    var corrections = {
      ['RUS']:'RU',
      ['GBR']:'GB',
      ['TWN']:'TW',
      ['KOR']:'KR',
      ['ROC']:'RU',
      ['']:'',
      ['']:'',
      ['']:'',
      ['']:'',
      ['']:'',
      ['']:'',
      ['']:'',
      ['']:'',
      ['']:'',
      ['']:'',
      ['']:'',
    }

    countryCode = corrections[countryCode] || countryCode

    this.setState({
      width:width,
      height:height,
      flag:countryCode,
    })
  }

  renderContent = () => {
    var { flag, width, height } = this.state
    return(
      <ReactCountryFlag
      countryCode={flag}
      svg
      style={this.props.style || {width, height}} title={flag} />
    )
  }


  render() {
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
