/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Pressable,
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";

//Components
import Background from '../components/general/background.js'
import SEOTitle from '../components/general/seotitle.js'
import SectionHeader from '../components/general/sectionheader.js'
import Button from '../components/general/button.js'
import Header from '../components/seo/header.js'

import Watch from './matches/watch.js'
import Scores from './matches/scores.js'
import Tournaments from './matches/tournaments.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      activeView:'how_to_watch_atp',
      title:'How to watch and stream live tennis in 2022 for the ATP Tour & WTA Tour',
      description:'There are lots of different ways that you could stream live tennis without having to pay for large subscriptions.'
    }
  }

  async componentDidMount(){
    this.props.navigation.setOptions({title:this.state.title})
  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }

  changeTab = (item) => {
    this.setState({activeView:item.id})
  }


  renderContent = () => {
    var { activeView } = this.state
    return(
      <>
      <Header title={this.state.title} description={this.state.description}/>
      <Background navigation={this.props.navigation} page="Streams" hideColumn>
      {this.renderMain()}
      </Background>
      </>
    )
  }

  renderMain = () => {
    var { activeView } = this.state

    var tabs = [
      {id:'how_to_watch_atp', title:'Watch ATP Matches'},
      {id:'how_to_watch_wta', title:'Watch WTA Matches'},
    ]

    var render = null
    if(activeView === 'how_to_watch_atp'){render = this.renderStreamingInfo('atp')}
    else if(activeView === 'how_to_watch_wta'){render = this.renderStreamingInfo('wta')}

    return(
      <View style={[{width:'100%'}, MainStyles.flexStartCenter]}>
        {
          // <View style={[{width:'100%', backgroundColor:GLOBAL.style.backgroundColor, padding:0}, MainStyles.flexCenterStart]}>
          //   <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {width:'100%', borderBottomColor:GLOBAL.style.borderColor, borderBottomWidth:1}]}>
          //   {tabs.map((item, index) => this.renderTab(item, index))}
          //   </View>
          // </View>
        }

        <View style={[{width:'100%', maxWidth:GLOBAL.maxWidth}, MainStyles.flexStartStart]}>
          {render}
        </View>
      </View>
    )
  }

  renderStreamingInfo = (mode) => {
    var sections = [
      {
        title:"How to stream live ATP matches",
        data:[
          {
            title:"Lowest cost option",
            data:[
              {
                title:'Betting sites',
                info:"Betting sites are by far the most cost effective way to stream tennis.\n\nAlmost all tennis matchups are available to stream live from Bet365 and other betting sites. To access the streams, register an account and if your balance is zero, make a deposit of any amount to active the video player. Geo-restrictions do at times apply. You must have a funded account or have placed a bet in the last 24 hours in order to access streams, however this is usually $10 or less. T&Cs apply, 18+. "
              }
            ]
          },
          {
            title:"Subscriptions",
            data:[
              {
                title:'TennisTV (Global)',
                info:"Tennis TV is the official live video streaming service of the ATP Tour. You need to buy a Tennis TV Premium subscription in order to watch live tennis and enjoy the wealth of replays and other video content.\n\nYou can watch live tennis on multiple devices including on your computer, mobile and tablet devices and on a TV – via Apple TV, Amazon Fire TV, Roku, Google Chromecast, Xbox One, Samsung Smart TV, LG Smart TV, Android TV, TCL and PlayStation 4.\n\nEnjoy up to 2,300 live matches a year from all ATP Tour tournaments – with matches made available as replays, on demand all year round.\n\nCosts vary depending on your country, however in the USA TennisTV is $14.99 monthly or $119.99 for an annual subscription.",
                button:{
                  title:'Go to TennisTV',
                  onPress:()=>Functions.openURL("https://tennistv.com")
                }
              },
              // {
              //   title:'Amazon Prime (UK)',
              //   info:"Tennis TV is the official live video streaming service of the ATP Tour. You need to buy a Tennis TV Premium subscription in order to watch live tennis and enjoy the wealth of replays and other video content.\n\nYou can watch live tennis on multiple devices including on your computer, mobile and tablet devices and on a TV – via Apple TV, Amazon Fire TV, Roku, Google Chromecast, Xbox One, Samsung Smart TV, LG Smart TV, Android TV, TCL and PlayStation 4.\n\nEnjoy up to 2,300 live matches a year from all ATP Tour tournaments – with matches made available as replays, on demand all year round.\n\nCosts vary depending on your country, however in the USA TennisTV is $14.99 monthly or $119.99 for an annual subscription.",
              //   button:{
              //     title:'Go to Amazon Prime',
              //     onPress:()=>Functions.openURL("https://tennistv.com")
              //   }
              // },
              // {
              //   title:'Tennis Channel (USA)',
              //   info:"Tennis TV is the official live video streaming service of the ATP Tour. You need to buy a Tennis TV Premium subscription in order to watch live tennis and enjoy the wealth of replays and other video content.\n\nYou can watch live tennis on multiple devices including on your computer, mobile and tablet devices and on a TV – via Apple TV, Amazon Fire TV, Roku, Google Chromecast, Xbox One, Samsung Smart TV, LG Smart TV, Android TV, TCL and PlayStation 4.\n\nEnjoy up to 2,300 live matches a year from all ATP Tour tournaments – with matches made available as replays, on demand all year round.\n\nCosts vary depending on your country, however in the USA TennisTV is $14.99 monthly or $119.99 for an annual subscription.",
              //   button:{
              //     title:'Go to the Tennis Channel',
              //     onPress:()=>Functions.openURL("https://tennistv.com")
              //   }
              // },
            ]
          },
          {
            title:"TV",
            data:[
              {
                title:'TV coverage around the world',
                info:'A full listing of TV providers who provide coverage of ATP events around the globe can be viewed on the ATP website.',
                button:{
                  title:'View TV Providers',
                  onPress:()=>Functions.openURL("https://www.atptour.com/en/tournaments/tv-schedule")
                }
                // info:<a href="https://www.atptour.com/en/tournaments/tv-schedule" target="_blank">Per the ATP website, ATP events can be viewed through the following providers</a>,
                // render:this.renderTV_atp(),
              }
            ]
          },
        ]
      },
      {
        title:"How to stream live WTA matches",
        data:[
          {
            title:"Lowest cost option",
            data:[
              {
                title:'Betting sites',
                info:"Betting sites are by far the most cost effective way to stream tennis.\n\nAlmost all tennis matchups are available to stream live from Bet365 and other betting sites. To access the streams, register an account and if your balance is zero, make a deposit of any amount to active the video player. Geo-restrictions do at times apply. You must have a funded account or have placed a bet in the last 24 hours in order to access streams, however this is usually $10 or less. T&Cs apply, 18+. "
              }
            ]
          },
          {
            title:"Subscriptions",
            data:[
              {
                title:'WTA TV (Global)',
                info:"WTA TV is the official streaming service of the WTA and provides access to nearly 2,000 live and on-demand matches from all WTA Premier tournaments and the majority of WTA International tournaments, and access includes HD streaming, multi-match viewing and multiple camera coverage for all main draw singles matches.\n\nSubscriptions cost (in USD) $9.99 monthly or $74.99 for an annual subscription.",
                button:{
                  title:'Go to WTA TV',
                  onPress:()=>Functions.openURL("https://wtatv.com")
                }
              },
              // {
              //   title:'Amazon Prime (UK)',
              //   info:"Tennis TV is the official live video streaming service of the ATP Tour. You need to buy a Tennis TV Premium subscription in order to watch live tennis and enjoy the wealth of replays and other video content.\n\nYou can watch live tennis on multiple devices including on your computer, mobile and tablet devices and on a TV – via Apple TV, Amazon Fire TV, Roku, Google Chromecast, Xbox One, Samsung Smart TV, LG Smart TV, Android TV, TCL and PlayStation 4.\n\nEnjoy up to 2,300 live matches a year from all ATP Tour tournaments – with matches made available as replays, on demand all year round.\n\nCosts vary depending on your country, however in the USA TennisTV is $14.99 monthly or $119.99 for an annual subscription.",
              //   button:{
              //     title:'Go to Amazon Prime',
              //     onPress:()=>Functions.openURL("https://tennistv.com")
              //   }
              // },
              // {
              //   title:'Tennis Channel (USA)',
              //   info:"Tennis TV is the official live video streaming service of the ATP Tour. You need to buy a Tennis TV Premium subscription in order to watch live tennis and enjoy the wealth of replays and other video content.\n\nYou can watch live tennis on multiple devices including on your computer, mobile and tablet devices and on a TV – via Apple TV, Amazon Fire TV, Roku, Google Chromecast, Xbox One, Samsung Smart TV, LG Smart TV, Android TV, TCL and PlayStation 4.\n\nEnjoy up to 2,300 live matches a year from all ATP Tour tournaments – with matches made available as replays, on demand all year round.\n\nCosts vary depending on your country, however in the USA TennisTV is $14.99 monthly or $119.99 for an annual subscription.",
              //   button:{
              //     title:'Go to the Tennis Channel',
              //     onPress:()=>Functions.openURL("https://tennistv.com")
              //   }
              // },
            ]
          },
          {
            title:"TV",
            data:[
              {
                title:'TV coverage around the world',
                info:'A full listing of TV providers who provide coverage of WTA events around the globe can be viewed on the WTA website.',
                button:{
                  title:'View TV Providers',
                  onPress:()=>Functions.openURL("https://www.wtatennis.com/broadcast")
                }
                // info:<a href="https://www.atptour.com/en/tournaments/tv-schedule" target="_blank">Per the ATP website, ATP events can be viewed through the following providers</a>,
                // render:this.renderTV_atp(),
              }
            ]
          },
        ]
      }
    ]

    return(
      <View style={{width:'100%', padding:GLOBAL.padding}}>
      {sections.map((item, index) => this.renderSection(item, index))}
      </View>
    )
  }

  renderSection = (item, index) => {
    return(
      <View style={{width:'100%', marginTop:index ? GLOBAL.padding * 4 : 0}}>
        <Text style={{width:'100%', textAlign:'left', fontSize:24, fontFamily:GLOBAL.fonts.black, marginBottom:0}}>
        {item.title}
        </Text>
        {item.data.map((item2, index2) => this.renderSection_data(item2, index2))}
      </View>
    )
  }

  renderSection_data = (item, index) => {
    return(
      <View style={{width:'100%', marginTop:GLOBAL.padding, paddingTop:GLOBAL.padding, borderTopColor:GLOBAL.style.borderColor, borderTopWidth: 1}}>
      <Text style={{width:'100%', textAlign:'left', fontSize:18, fontFamily:GLOBAL.fonts.bold, marginBottom:GLOBAL.padding/4}}>
      {item.title}
      </Text>
      {item.data.map((item2, index2) => this.renderSection_data_item(item2, index2))}
      </View>
    )
  }

  renderSection_data_item = (item, index) => {
    return(
      <View style={{width:'100%', marginTop:GLOBAL.padding}}>
      <Text style={{width:'100%', textAlign:'left', fontSize:14, fontFamily:GLOBAL.fonts.bold, marginBottom:GLOBAL.padding/4}}>
      {item.title}
      </Text>
      {
        item.info ?
        <Text style={{width:'100%', textAlign:'left', fontSize:14, fontFamily:GLOBAL.fonts.regular, marginBottom:GLOBAL.padding}}>
        {item.info}
        </Text>
        : null
      }
      {
        item.render || null
      }
      {
        item.button ?
        <View style={{width:'100%', maxWidth:400}}>
        <Button title={item.button.title} onPress={item.button.onPress}/>
        </View>
        : null
      }
      </View>
    )
  }

  renderTV_atp = () => {
    return(
      <View style={{width:'100%'}}>
      <table width="100%" class="articleTable" border="0">
        <tbody>
            <tr>
                <td><strong>Area</strong></td>
                <td><strong>ATP Masters 1000s</strong></td>
                <td><strong>ATP 500s</strong></td>
                <td><strong>ATP 250s</strong></td>
            </tr>
            <tr>
                <td>Australia</td>
                <td><a href="https://www.beinsports.com/au/" target="_blank">beIN Sports</a></td>
                <td><a href="https://www.beinsports.com/au/" target="_blank">beIN Sports</a></td>
                <td><a href="https://www.beinsports.com/au/" target="_blank">beIN Sports</a></td>
            </tr>
            <tr>
                <td>France</td>
                <td><a href="https://www.canalplus.com/programme-tv/" target="_blank">Canal+</a>, <a href="https://www.eurosport.fr/tennis/">Eurosport</a></td>
                <td><a href="https://www.eurosport.fr/tennis/">Eurosport</a></td>
                <td><a href="https://www.eurosport.fr/tennis/">Eurosport</a></td>
            </tr>
            <tr>
                <td>Germany</td>
                <td><a href="https://sport.sky.de/tennis" target="_blank">Sky<br />
                Deutschland</a></td>
                <td><a href="https://sport.sky.de/tennis" target="_blank">Sky<br />
                Deutschland</a></td>
                <td><a href="https://sport.sky.de/tennis" target="_blank">Sky<br />
                Deutschland</a></td>
            </tr>
            <tr>
                <td>Spain</td>
                <td>
                <p>Telefonica<br />
                (<a href="https://ver.movistarplus.es/">Movistar</a>),<br />
                Siccative (TVE)</p>
                </td>
                <td>Telefonica (<a href="https://ver.movistarplus.es/" target="_blank">Movistar</a>)</td>
                <td>Telefonica (<a href="https://ver.movistarplus.es/" target="_blank">Movistar</a>), <br />
                <a href="https://www.eurosport.es/" target="_blank">Eurosport</a> (Melbourne)</td>
            </tr>
            <tr>
                <td>United Kingdom</td>
                <td><a href="https://www.amazon.co.uk/b?node=16184708031" target="_blank">Amazon Prime Video</a></td>
                <td><a href="https://www.amazon.co.uk/b?node=16184708031" target="_blank">Amazon Prime Video</a></td>
                <td><a href="https://www.amazon.co.uk/tennisonprime" target="_blank">Amazon Prime Video</a></td>
            </tr>
            <tr>
                <td>United States</td>
                <td><a href="https://www.tennischanneleverywhere.com/" target="_blank">Tennis Channel</a></td>
                <td><a href="https://www.tennischanneleverywhere.com/" target="_blank">Tennis Channel</a></td>
                <td><a href="https://www.tennischanneleverywhere.com/" target="_blank">Tennis Channel</a></td>
            </tr>
            <tr>
            </tr>
        </tbody>
    </table>
    <p><strong>All Broadcasters</strong></p>
    <table width="100%" class="articleTable" border="0">
        <tbody>
            <tr>
                <td><strong>Area</strong></td>
                <td><strong>ATP Masters 1000s</strong></td>
                <td><strong>ATP 500s</strong></td>
                <td><strong>ATP 250s</strong></td>
            </tr>
            <tr>
                <td>Africa 1*</td>
                <td><a href="https://supersport.com/tennis/atp" target="_blank">Supersport</a></td>
                <td><a href="https://supersport.com/tennis/atp" target="_blank">Supersport</a></td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Africa 2*</td>
                <td><a href="https://www.canalplus-afrique.com/cd/programmes-tv/sport" target="_blank">Canal+ <br />
                Overseas/Africa</a></td>
                <td><a href="https://www.canalplus-afrique.com/cd/programmes-tv/sport" target="_blank">Canal+ Overseas</a></td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Albania &amp; Kosovo</td>
                <td>Straightforward<br />
                /<a href="https://www.supersport.al/category/tenis/" target="_blank">Supersport</a></td>
                <td>Straightforward<br />
                /<a href="https://www.supersport.al/category/tenis/" target="_blank">Supersport</a></td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Australia</td>
                <td><a href="https://www.beinsports.com/au/" target="_blank">beIN Sports</a></td>
                <td><a href="https://www.beinsports.com/au/" target="_blank">beIN Sports</a></td>
                <td><a href="https://www.beinsports.com/au/" target="_blank">beIN Sports</a></td>
            </tr>
            <tr>
                <td>Belgium</td>
                <td><a href="http://www.betv.be/" target="_blank">BeTV</a>,<br />
                <a href="https://www2.telenet.be/nl/producten/tv-en-entertainment/play-sports/overzicht/" target="_blank">Telenet / Playsports</a></td>
                <td><a href="http://www.betv.be/" target="_blank">BeTV</a>,<br />
                <a href="https://www2.telenet.be/nl/producten/tv-en-entertainment/play-sports/overzicht/" target="_blank">Telenet / Playsports</a></td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Bulgaria</td>
                <td><a href="https://live.a1.bg/" target="_blank">MTEL/A1</a></td>
                <td><a href="https://live.a1.bg/" target="_blank">MTEL/A1</a></td>
                <td><a href="https://live.a1.bg/" target="_blank">MTEL/A1</a></td>
            </tr>
            <tr>
                <td>Canada</td>
                <td><a href="https://www.sportsnet.ca/tennis/" target="_blank">Rogers Sportsnet</a>,<br />
                <a href="https://www.tsn.ca/tennis/atp" target="_blank">TSN</a></td>
                <td><a href="https://www.tsn.ca/tennis/atp" target="_blank">TSN</a></td>
                <td>TSN</td>
            </tr>
            <tr>
                <td>China</td>
                <td><a href="https://www.cctv.com/" target="_blank">CCTV</a>, <a href="https://www.iqiyi.com/" target="_blank">Iqiyi</a>,<br />
                SMG</td>
                <td><a href="https://www.iqiyi.com/" target="_blank">Iqiyi</a></td>
                <td><a href="https://www.iqiyi.com/" target="_blank">Iqiyi</a></td>
            </tr>
            <tr>
                <td>Cyprus</td>
                <td><a href="https://www.cyta.com.cy/tv-live-sports-events/en" target="_blank">CYTA</a></td>
                <td><a href="https://www.cyta.com.cy/tv-live-sports-events/en" target="_blank">CYTA</a></td>
                <td><a href="https://www.cyta.com.cy/tv-live-sports-events/en" target="_blank">CYTA</a></td>
            </tr>
            <tr>
                <td>Czech Republic</td>
                <td><a href="https://www.eurosport.cz/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.cz/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.cz/" target="_blank">Eurosport</a>,<br />
                Nova Sport</td>
            </tr>
            <tr>
                <td>Denmark</td>
                <td><a href="https://sport.tv2.dk/tennis" target="_blank">TV2</a></td>
                <td><a href="https://sport.tv2.dk/tennis" target="_blank">TV2</a></td>
                <td><a href="https://sport.tv2.dk/tennis" target="_blank">TV2</a></td>
            </tr>
            <tr>
                <td>Estonia</td>
                <td>All Media /<br />
                <a href="https://go3.tv/sport_tv" target="_blank">TV3</a></td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Europe 1*</td>
                <td><a href="https://www.setantaeurasia.com/ru/%D1%82%D0%B2-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0/">Setanta</a></td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Europe 2*</td>
                <td>
                <p>United Media<br />
                (<a href="http://sportklub.rs/Tenis/ATP" target="_blank">IKO Sportklub</a>)</p>
                </td>
                <td>
                <p>United Media<br />
                (<a href="http://sportklub.rs/Tenis/ATP" target="_blank">IKO Sportklub</a>)</p>
                </td>
                <td>
                <p>United Media<br />
                (<a href="http://sportklub.rs/Tenis/ATP" target="_blank">IKO Sportklub</a>)</p>
                </td>
            </tr>
            <tr>
                <td>Europe 3*</td>
                <td><a href="https://www.eurosport.fr/tennis/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.fr/tennis/">Eurosport</a></td>
                <td><a href="https://www.eurosport.fr/tennis/" target="_blank">Eurosport</a></td>
            </tr>
            <tr>
                <td>Europe 4*</td>
                <td><a href="https://sport.sky.de/tennis" target="_blank">Sky Deutschland</a></td>
                <td><a href="https://sport.sky.de/tennis" target="_blank">Sky Deutschland</a></td>
                <td><a href="https://sport.sky.de/tennis" target="_blank">Sky Deutschland</a></td>
            </tr>
            <tr>
                <td>France</td>
                <td><a href="https://www.canalplus.com/programme-tv/" target="_blank">Canal+</a>,<br />
                <a href="https://www.eurosport.fr/tennis/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.fr/tennis/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.fr/tennis/" target="_blank">Eurosport</a></td>
            </tr>
            <tr>
                <td>Georgia</td>
                <td><a href="https://silksport.ge/" target="_blank">Silk Media</a></td>
                <td><a href="https://silksport.ge/" target="_blank">Silk Media</a></td>
                <td>Adjara</td>
            </tr>
            <tr>
                <td>Germany</td>
                <td><a href="https://sport.sky.de/tennis" target="_blank">Sky Deutschland</a></td>
                <td><a href="https://sport.sky.de/tennis" target="_blank">Sky Deutschland</a></td>
                <td><a href="https://sport.sky.de/tennis" target="_blank">Sky Deutschland</a></td>
            </tr>
            <tr>
                <td>Greece</td>
                <td>Hellenic<br />
                (OTE TV)</td>
                <td>Hellenic<br />
                (OTE TV)</td>
                <td>Hellenic<br />
                (OTE TV)</td>
            </tr>
            <tr>
                <td>Hong Kong</td>
                <td>PCCW</td>
                <td>PCCW</td>
                <td>PCCW</td>
            </tr>
            <tr>
                <td>Hungary</td>
                <td><a href="https://www.eurosport.hu/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.hu/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.hu/" target="_blank">Eurosport</a></td>
            </tr>
            <tr>
                <td>India</td>
                <td><a href="https://www.voot.com/public_html/vm-promo/index.html" target="_blank">Voot</a></td>
                <td><a href="https://www.tennistv.com/" target="_blank">Tennis TV</a></td>
                <td>Discovery / Tennis TV</td>
            </tr>
            <tr>
                <td>Indonesia</td>
                <td><a href="https://www.vidio.com/categories/42-sports" target="_blank">Vidio</a></td>
                <td><a href="https://www.vidio.com/categories/42-sports" target="_blank">Vidio</a></td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Israel</td>
                <td>Charlton,<br />
                <a href="https://sport1.maariv.co.il/tennis/" target="_blank">Sport1</a></td>
                <td>Sport5</td>
                <td>Sport5</td>
            </tr>
            <tr>
                <td>Italy</td>
                <td><a href="https://sport.sky.it/tennis" target="_blank">Sky Italia</a></td>
                <td>Sportcast (SuperTennis)</td>
                <td>Sportcast (SuperTennis)</td>
            </tr>
            <tr>
                <td>Japan</td>
                <td><a href="https://www.wowow.co.jp/sports/tennis/" target="_blank">WOWOW</a>, <a href="https://www1.nhk.or.jp/sports2/tennis/" target="_blank">NHK</a>, <br />
                <a href="https://www.gaora.co.jp/tennis/" target="_blank">Gaora Sports Channel</a></td>
                <td><a href="https://www.wowow.co.jp/sports/tennis/" target="_blank">WOWOW</a><br />
                </td>
                <td><a href="https://www.wowow.co.jp/sports/tennis/" target="_blank">WOWOW</a></td>
            </tr>
            <tr>
                <td>Latin America</td>
                <td><a href="https://www.espn.com/watch/" target="_blank">ESPN</a></td>
                <td><a href="https://www.espn.com/watch/" target="_blank">ESPN</a></td>
                <td><a href="https://www.espn.com/watch/" target="_blank">ESPN</a></td>
            </tr>
            <tr>
                <td>Latvia</td>
                <td>All Media /<br />
                <a href="https://skaties.lv/sports/" target="_blank">TV3</a></td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Lithuania</td>
                <td>All Media /<br />
                <a href="https://www.tv3.lt/rubrika/naujienos/sportas" target="_blank">TV3</a></td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Luxembourg</td>
                <td><a href="http://www.betv.be/" target="_blank">BeTV</a></td>
                <td><a href="http://www.betv.be/" target="_blank">BeTV</a></td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Malaysia</td>
                <td>Measat<br />
                (<a href="https://www.stadiumastro.com/sports/tennis/news" target="_blank">Astro Supersport</a>)</td>
                <td>Measat<br />
                (<a href="https://www.stadiumastro.com/sports/tennis/news" target="_blank">Astro Supersport</a>)</td>
                <td><a href="https://www.stadiumastro.com/sports/tennis/news" target="_blank">Astro</a></td>
            </tr>
            <tr>
                <td>Malta</td>
                <td><a href="https://www.go.com.mt/go-sports-premium/" target="_blank">Go plc</a></td>
                <td><a href="https://www.go.com.mt/go-sports-premium/" target="_blank">Go plc</a></td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Middle East &amp; North Africa</td>
                <td><a href="https://www.beinsports.com/ar/%D8%AA%D9%86%D8%B3-%D8%A7%D9%84%D9%85%D8%AD%D8%AA%D8%B1%D9%81%D9%8A%D9%86/" target="_blank">beIN Sports</a></td>
                <td><a href="https://www.beinsports.com/ar/%D8%AA%D9%86%D8%B3-%D8%A7%D9%84%D9%85%D8%AD%D8%AA%D8%B1%D9%81%D9%8A%D9%86/" target="_blank">beIN Sports</a></td>
                <td><a href="https://www.beinsports.com/ar/%D8%AA%D9%86%D8%B3-%D8%A7%D9%84%D9%85%D8%AD%D8%AA%D8%B1%D9%81%D9%8A%D9%86/" target="_blank">beIN Sports</a></td>
            </tr>
            <tr>
                <td>Moldova</td>
                <td><a href="https://www.setantaeurasia.com/" target="_blank">Setanta</a></td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Netherlands</td>
                <td>Ziggo</td>
                <td>Ziggo</td>
                <td>Ziggo</td>
            </tr>
            <tr>
                <td>Philippines</td>
                <td>Elite Sport /<br />
                <a href="http://www.tapdmv.com/schedule.html#tapsports" target="_blank">TAP</a></td>
                <td>Elite Sport /<br />
                <a href="http://www.tapdmv.com/schedule.html#tapsports" target="_blank">TAP</a></td>
                <td>Elite Sport /<br />
                <a href="http://www.tapdmv.com/schedule.html#tapsports" target="_blank">TAP</a></td>
            </tr>
            <tr>
                <td>Poland</td>
                <td><a href="https://www.polsatsport.pl/dyscyplina/tenis/" target="_blank">Polsat</a></td>
                <td><a href="https://www.polsatsport.pl/dyscyplina/tenis/" target="_blank">Polsat</a></td>
                <td><a href="https://www.polsatsport.pl/dyscyplina/tenis/" target="_blank">Polsat</a></td>
            </tr>
            <tr>
                <td>Portugal</td>
                <td><a href="https://www.sporttv.pt/" target="_blank">Sport TV</a></td>
                <td><a href="https://www.sporttv.pt/" target="_blank">Sport TV</a></td>
                <td><a href="https://www.sporttv.pt/" target="_blank">Sport TV</a></td>
            </tr>
            <tr>
                <td>Republic of Ireland</td>
                <td><a href="https://www.amazon.co.uk/b?node=16184708031" target="_blank">Amazon<br />
                Prime Video</a></td>
                <td><a href="https://www.amazon.co.uk/b?node=16184708031" target="_blank">Amazon<br />
                Prime Video</a></td>
                <td><a href="https://www.amazon.co.uk/tennisonprime" target="_blank">Amazon<br />
                Prime Video</a></td>
            </tr>
            <tr>
                <td>Romania</td>
                <td><a href="https://www.eurosport.ro/tenis/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.ro/tenis/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.ro/" target="_blank">Eurosport</a>,<br />
                Pragosport</td>
            </tr>
            <tr>
                <td>Russia</td>
                <td><a href="https://www.eurosport.ru/tennis/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.ru/tennis/" target="_blank">Eurosport</a></td>
                <td><a href="https://www.eurosport.ru/tennis/" target="_blank">Eurosport</a>,<br />
                OKKO</td>
            </tr>
            <tr>
                <td>Scandinavia (ex. Denmark)</td>
                <td>Eurosport</td>
                <td>Eurosport</td>
                <td>Eurosport</td>
            </tr>
            <tr>
                <td>Singapore</td>
                <td><a href="https://connect-sg.beinsports.com/en" target="_blank">beIN Sports</a></td>
                <td><a href="https://connect-sg.beinsports.com/en" target="_blank">beIN Sports</a></td>
                <td><a href="https://connect-sg.beinsports.com/en" target="_blank">beIN Sports</a></td>
            </tr>
            <tr>
                <td>Slovakia</td>
                <td>Eurosport</td>
                <td>Eurosport</td>
                <td>Eurosport,<br />
                Markiza</td>
            </tr>
            <tr>
                <td>South Africa</td>
                <td><a href="https://supersport.com/" target="_blank">SuperSport</a></td>
                <td><a href="https://supersport.com/" target="_blank">SuperSport</a></td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>South America</td>
                <td><a href="https://www.espn.com/watch/" target="_blank">ESPN</a></td>
                <td><a href="https://www.espn.com/watch/" target="_blank">ESPN</a></td>
                <td><a href="https://www.espn.com/watch/" target="_blank">ESPN</a></td>
            </tr>
            <tr>
                <td>South Korea</td>
                <td><a href="http://www.spotv.net/" target="_blank">Eclat</a></td>
                <td><a href="http://www.spotv.net/" target="_blank">Eclat</a></td>
                <td><a href="http://www.spotv.net/" target="_blank">Eclat</a></td>
            </tr>
            <tr>
                <td>Spain</td>
                <td>Telefonica<br />
                (<a href="https://ver.movistarplus.es/" target="_blank">Movistar</a>),<br />
                Siccative (TVE)</td>
                <td>
                <p>Telefonica (<a href="https://ver.movistarplus.es/" target="_blank">Movistar</a>)</p>
                </td>
                <td>Telefonica (<a href="https://ver.movistarplus.es/" target="_blank">Movistar</a>)</td>
            </tr>
            <tr>
                <td>Taiwan</td>
                <td>Sino Catch (<a href="http://sportcast.com.tw/index.php" target="_blank">Sportcast</a>)</td>
                <td>Sino Catch (<a href="http://sportcast.com.tw/index.php" target="_blank">Sportcast</a>)</td>
                <td>Sino Catch<br />
                (<a href="http://sportcast.com.tw/index.php" target="_blank">Sportcast</a>)</td>
            </tr>
            <tr>
                <td>Thailand</td>
                <td>Cineplex (<a href="https://truevisionsgroup.truecorp.co.th/" target="_blank">Truevisions</a>)</td>
                <td>Cineplex (<a href="https://truevisionsgroup.truecorp.co.th/" target="_blank">Truevisions</a>)</td>
                <td>Cineplex<br />
                (<a href="https://truevisionsgroup.truecorp.co.th/" target="_blank">Truevisions</a>)</td>
            </tr>
            <tr>
                <td>Turkey</td>
                <td><a href="https://www.ssport.tv/" target="_blank">Saran<br />
                (S Sport)</a></td>
                <td><a href="https://www.ssport.tv/" target="_blank">Saran<br />
                (S Sport)</a></td>
                <td><a href="https://www.ssport.tv/" target="_blank">Saran<br />
                (S Sport)</a></td>
            </tr>
            <tr>
                <td>Ukraine</td>
                <td><a href="https://www.setanta.com.ua/" target="_blank">Setanta</a></td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>United Kingdom</td>
                <td><a href="https://www.amazon.co.uk/b?node=16184708031" target="_blank">Amazon<br />
                Prime Video</a></td>
                <td><a href="https://www.amazon.co.uk/b?node=16184708031" target="_blank">Amazon<br />
                Prime Video</a></td>
                <td><a href="https://www.amazon.co.uk/tennisonprime" target="_blank">Amazon<br />
                Prime Video</a></td>
            </tr>
            <tr>
                <td>United States</td>
                <td><a href="https://www.tennischanneleverywhere.com/" target="_blank">Tennis Channel</a></td>
                <td><a href="https://www.tennischanneleverywhere.com/" target="_blank">Tennis Channel</a></td>
                <td><a href="https://www.tennischanneleverywhere.com/" target="_blank">Tennis Channel</a></td>
            </tr>
            <tr>
                <td>Vietnam</td>
                <td><a href="https://www.vivendi.com/activites/canal-groupe/tv-payante-a-linternational/" target="_blank">Canal+</a></td>
                <td><a href="https://www.vivendi.com/activites/canal-groupe/tv-payante-a-linternational/" target="_blank">Canal+</a></td>
                <td><a href="https://www.vivendi.com/activites/canal-groupe/tv-payante-a-linternational/" target="_blank">Canal+</a></td>
            </tr>
            <tr>
            </tr>
        </tbody>
    </table>
      </View>
      )
  }



  renderTab = (item, index) => {
    var active = this.state.activeView === item.id
    return(
      <Pressable onPress={()=>this.changeTab(item)} style={[{paddingVertical:GLOBAL.padding/2, paddingHorizontal:GLOBAL.padding * 2, backgroundColor:active ? '#2B2B2B' : GLOBAL.style.cardColor, borderRightWidth:1, borderRightColor:GLOBAL.style.borderColor}, MainStyles.flexCenter]}>
        <Text style={{fontSize:14, fontFamily:GLOBAL.fonts.medium, opacity:active ? 1 : 1, color:active ? "white" : GLOBAL.style.color, textTransform:'none'}}>
        {item.title}
        </Text>
      </Pressable>
    )
    // return(
    //   <Pressable onPress={()=>this.changeTab(item)}>
    //     <View style={[{height:48, paddingHorizontal:GLOBAL.padding*1}, MainStyles.flexCenter, MainStyles.flexRow]}>
    //       <Text style={{fontSize:15, fontFamily:GLOBAL.fonts.regular, color:GLOBAL.style.cardColor}}>
    //       {item.title}
    //       </Text>
    //       {
    //         !active ? null :
    //         <View style={{width:'100%', height:2, backgroundColor:GLOBAL.style.cardColor, position:'absolute', bottom:0}}/>
    //       }
    //     </View>
    //   </Pressable>
    // )
  }

  render = () => {
    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
