/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Button,
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";

//Components
import Background from '../components/general/background.js'
import SEOTitle from '../components/general/seotitle.js'
import Home from './rankings/home.js'
import BottomNavbar from '../components/navigation/bottomnavbar.js'
import Header from '../components/seo/header.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      url:this.props.route?.params?.url,
      loading:true,
      activeView:'Live',
    }
  }

  async componentDidMount(){
    // var title = "Men's & Womens Tennis Rankings - ATP, WTA & ITF Rankings - TNNS"
    // var description = 'Check out the latest official tennis worldwide rankings for 2022 on TNNS'
    // this.props.navigation.setOptions({title})
    // this.setState({title, description})

    console.log(this.state.url)
  }

  async componentDidUpdate(prevProps){

  }

  async componentWillUnmount(){

  }



  renderContent = () => {
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var title = "Live Tennis Scores, Results, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var description = "Latest tennis live scores, results and schedules for Grand Slam, ATP, WTA, Challenger and ITF events."
    return(
      <>
      <Header title={this.state.title} description={this.state.description}/>
      <Background navigation={this.props.navigation} page="Home" disableContent>
      {this.renderMain()}
      </Background>
      <BottomNavbar/>
      </>
    )
  }

  renderMain = () => {
    var { url } = this.state
    return(
      <View style={{flex:1, width:'100%'}}>
        <iframe src={url} title="EmbeddedArticle" scrolling="no" class="css-maezia" id="iFrameResizer0"></iframe>
      </View>
    )
  }

  render = () => {
    try{
      return this.renderContent()
    }catch(e){
      return null
    }
  }

}
