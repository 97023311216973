/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Image,
  Pressable,
  Text,
  FlatList,
  Switch,
} from 'react-native';

//Modules
// import { WebView } from 'react-native-webview';
// import Image from 'react-native-fast-image'
// import Carousel from 'react-native-snap-carousel';
// import LinearGradient from 'react-native-linear-gradient';
// import CountDown from 'react-native-countdown-component';

//Components
// import Poll from '../polls/poll.js'
import Icon from '../general/icon.js'
// import UserFeedback from '../general/userfeedback.js'
import Match from '../../components/matches/match.js'
import Player from '../../components/general/player.js'
import Button from '../../components/general/button.js'
import { Hoverable } from "react-native-web-hover";
// import DailyPicks from '../../components/fantasy_daily/picksmodule.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

var fantasy_height = 42

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      data:this.props.data,
      index:this.props.index,
      enabled:this.props.data?.enabled || false,
      style:this.props.style || GLOBAL.style,
    }
  }

  async componentDidMount(){
    // if(this.props.data.id === 'heading_1'){
    //   console.log(this.props.data)
    // }
  }

  async componentDidUpdate(prevProps){
    if(prevProps.data !== this.props.data || this.props.index !== prevProps.index){
      this.setState({data:this.props.data, index:this.props.index, enabled:this.props?.data?.enabled})
    }
  }

  async componentWillUnmount(){

  }

  toggleNotifications = async () => {
    var { enabled, data } = this.state
    data.enabled = !enabled

    var onPress = data?.onPress || {}
    onPress.enabled = !enabled

    Functions.onPress(onPress)

    this.setState({
      enabled:!enabled,
      data
    })
  }

  openImage = (onPress) => {
    if(onPress){
      Functions.onPress(onPress)
    }
  }

  open = () => {
    var { data } = this.state

    // if(data.id === 'notifications_switch'){
    //   this.toggleNotifications()
    // }else{
    //   var onPress = data?.onPress
    //   Functions.onPress(onPress)
    // }

    var onPress = data?.onPress
    if(onPress){
      // console.log(onPress)
      if(onPress.screen === 'Player'){
        var player_id = onPress.params.data.id.split(":")[2]
        GLOBAL.navigation.push('Player', {id:player_id})
      }else if(onPress.screen === 'Tournament'){
        GLOBAL.navigation.push('Tournament', {id:onPress.params.id})
      }else if(onPress.url){
        window.open(onPress.url, '_blank')
      }
    }


  }

  openShorts = (index) => {
    var { data } = this.state
    var shorts = data.data || []
    GLOBAL.navigation.navigate('Shorts', {shorts, index})
    // Functions.openURL("https://www.youtube.com/watch?v=0e99QrSFN4s")
  }

  openVideo = (data) => {

    Functions.openVideo(data.onPress.data_player)

    // GLOBAL.navigation.navigate('Story', {shorts, index})
    // Functions.openURL("https://www.youtube.com/watch?v=0e99QrSFN4s")
  }

  //
  renderInside = () => {
    var { data } = this.state
    var id = data?.id
    var id_associated = data?.id_associated || null

    var render = this.renderMissing(data)

    var ids = [
      'separator',
      'heading_1',
      'heading_2',
      'heading_3',
      'match',
      'news',
      'cta',
      'video_carousel',
      'video',
      'button_minor',
      'poll',
      'shorts_list',
      'paragraph',
      'paragraph_secondary',
      'rankings_table',
      'table_header',
      'image',
      'webview',
      'user_feedback',
      'player',
      'fantasy_game_bracket',
      'fantasy_league',
      'notifications_switch',
      'daily_picks_carousel',
      'fantasy_picks_dailypicks',
    ]

    if(id === 'separator'){render = this.renderSeparator()}
    // else if(id === 'section_title'){ render = this.renderSectionTitle(data) }
    else if(id === 'heading_1'){ render = this.renderHeading1(data) }
    else if(id === 'heading_1_small'){ render = this.renderHeading1Small(data) }
    else if(id === 'heading_2'){ render = this.renderHeading2(data) }
    else if(id === 'heading_3'){ render = this.renderHeading3(data) }
    else if(id === 'paragraph'){ render = this.renderParagraph(data) }
    else if(id === 'paragraph_secondary'){ render = this.renderParagraph(data, true) }
    else if(id === 'match'){ render = this.renderMatch(data) }
    // else if(id === 'player'){ render = this.renderPlayer(data) }
    // else if(id === 'webview'){ render = this.renderWebView(data) }
    else if(id === 'news'){ render = this.renderNews(data) }
    // else if(id === 'cta'){ render = this.renderCTA(data) }
    // else if(id === 'video_carousel'){ render = this.renderVideoCarousel(data) }
    // else if(id === 'video'){ render = this.renderVideo(data) }
    // else if(id === 'button_minor'){ render = this.renderButtonMinor(data) }
    // else if(id === 'poll'){ render = this.renderPoll(data) }
    // else if(id === 'image'){ render = this.renderImage(data) }
    // else if(id === 'image_grid'){ render = this.renderImageGrid(data) }
    // else if(id === 'shorts_list'){ render = this.renderShorts(data) }
    // else if(id === 'rankings_table'){ render = this.renderRankingsTable(data) }
    // else if(id === 'table_header'){ render = this.renderTableHeader(data) }
    // else if(id === 'table_row'){ render = this.renderTableRow(data) }
    // else if(id === 'user_feedback'){ render = this.renderUserFeedback(data) }
    // else if(id === 'button'){ render = this.renderButton(data) }
    // else if(id === 'fantasy_game_bracket'){ render = this.renderFantasyBracketGame(data) }
    // else if(id === 'fantasy_league'){ render = this.renderFantasyLeague(data) }
    // else if(id === 'notifications_switch'){ render = this.renderNotificationSwitch(data) }
    // else if(id === 'daily_picks_carousel'){ render = this.renderDailyPicksCarousel(data) }
    // else if(id === 'fantasy_picks_dailypicks'){ render = <DailyPicks data={data}/>}
    // else if(id === 'fantasy_scores'){ render = this.renderFantasyScores(data) }
    // else if(id === 'table'){ render = this.renderTable(data) }
    // else if(id === 'statistics'){ render = this.renderStatistics(data) }
    // else if(id === 'accent_trivia'){ render = this.renderHeadingAccent(data) }
    // else if(id === 'countdown'){ render = this.renderCountdown(data) }
    else if(id === 'search_heading'){ render = this.renderSearchHeading(data) }
    else if(id === 'search_result'){ render = this.renderSearchResult(data) }
    // else if(id === 'empty_state_search'){ render = this.renderSearchEmpty(data) }

    //Check if content is associated with a missing id i.e. not supported in this version
    if(id_associated && !ids.includes(id_associated)){
      //Hide this element
      render = null
    }

    return render
  }

  //Renders
  renderSeparator = () => {
    return <View style={{width:'100%', height:GLOBAL.padding/2, backgroundColor:this.state.style.separator || this.state.style.borderColor, marginBottom:GLOBAL.padding}}/>
  }

  renderSectionTitle = (data) => {
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, marginBottom:GLOBAL.padding/1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {
          !data?.icon?.image ? null :
          <Image
          style={[{width:24, aspectRatio:1, marginRight:GLOBAL.padding/2}, MainStyles.flexCenter]}
          source={{uri:data?.icon?.image}}
          //resizeMode={Image.resizeMode.contain}
          />
        }
        <View style={[{flex:1}, MainStyles.flexStartStart]}>
          <Text style={{fontFamily:GLOBAL.fonts.ultra, textTransform:'uppercase', color:this.state.style.color, fontSize:22, textAlign:'left'}}>
          {data.title}
          </Text>
        </View>
      </View>
    )
  }

  renderHeading1 = (data) => {
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, marginBottom:GLOBAL.padding/1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {
          !data?.icon?.image ? null :
          <Image
          style={[{width:24, aspectRatio:1, marginRight:GLOBAL.padding/2}, MainStyles.flexCenter]}
          source={{uri:data?.icon?.image}}
          //resizeMode={Image.resizeMode.contain}
          />
        }
        <View style={[{flex:1}, MainStyles.flexStartStart]}>
          <Text style={{fontFamily:GLOBAL.fonts.ultra, textTransform:'uppercase', color:this.state.style.color, fontSize:18, textAlign:'left'}}>
          {data.title}
          </Text>
          <View style={{height:4, width:GLOBAL.padding*2, backgroundColor:this.state.style.color, marginTop:GLOBAL.padding/2}}/>
        </View>
      </View>
    )
  }

  renderHeading1Small = (data) => {
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, marginBottom:GLOBAL.padding/1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {
          !data?.icon?.image ? null :
          <Image
          style={[{width:24, aspectRatio:1, marginRight:GLOBAL.padding/2}, MainStyles.flexCenter]}
          source={{uri:data?.icon?.image}}
          //resizeMode={Image.resizeMode.contain}
          />
        }
        <View style={[{flex:1}, MainStyles.flexStartStart]}>
          <Text style={{fontFamily:GLOBAL.fonts.ultra, textTransform:'uppercase', color:this.state.style.color, fontSize:16, textAlign:'left'}}>
          {data.title}
          </Text>
          <View style={{height:2, width:GLOBAL.padding*2, backgroundColor:this.state.style.color, marginTop:GLOBAL.padding/2}}/>
        </View>
      </View>
    )
  }

  renderHeading2 = (data) => {
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, marginBottom:GLOBAL.padding/2}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.medium, color:this.state.style.color, fontSize:14, textAlign:'left'}}>
        {data.title}
        </Text>
      </View>
    )
  }

  renderHeading3 = (data) => {
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, marginBottom:GLOBAL.padding/2}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.medium, color:this.state.style.color_secondary, fontSize:12, textAlign:'left'}}>
        {data.title}
        </Text>
      </View>
    )
  }

  renderHeadingAccent = (data) => {
    return(
      <View style={[{width:'100%', padding:GLOBAL.padding, paddingVertical:GLOBAL.padding/3, marginTop:GLOBAL.padding*0}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.black, color:data.color || this.state.style.color_trivia || this.state.style.color, fontSize:12, textAlign:'left', textTransform:'uppercase'}}>
        {data.title}
        </Text>
      </View>
    )
  }

  renderCountdown = (data) => {
    var countdown = data.timestamp
    return(
      <View style={[MainStyles.flexRow, MainStyles.flexStartCenter, {width:'100%', paddingHorizontal:GLOBAL.padding}]}>
        {
          // <CountDown
          //   until={countdown}
          //   size={20}
          //   // onFinish={this.onFinish}
          //   style={{marginLeft:-4}}
          //   digitStyle={{backgroundColor: 'transparent', padding:0}}
          //   digitTxtStyle={{color: 'white', fontFamily:'ClanOT-News', fontSize:32, fontWeight:'500'}}
          //   timeToShow={['H', 'M', 'S']}
          //   timeLabels={{}}
          //   showSeparator
          //   separatorStyle={{color: 'white', opacity:0.5, fontFamily:GLOBAL.fonts.regular, marginTop:-2.5}}
          // />
        }
      </View>
    )
  }

  renderTableHeader = (data) => {
    return(
      <View style={[{width:'100%', padding:GLOBAL.padding, paddingVertical:GLOBAL.padding/3, marginTop:GLOBAL.padding*0, backgroundColor:this.state.style.header_backgroundColor}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.black, color:this.state.style.color, fontSize:12, textAlign:'left'}}>
        {data.title}
        </Text>
      </View>
    )
  }

  renderTableRow = (data) => {
    return(
      <View style={[{width:'100%', padding:GLOBAL.padding, paddingVertical:GLOBAL.padding/2, borderBottomColor:this.state.style.borderColor, borderBottomWidth:1}, MainStyles.flexRow, MainStyles.flexStartStart]}>
        <Text style={{fontFamily:GLOBAL.fonts.regular, color:this.state.style.color, fontSize:13, textAlign:'left', flex:1, opacity:data.value_1?.opacity || 1}}>
        {data.value_1?.value}
        </Text>
        {
          !data.value_2 ? null :
          <Text style={{fontFamily:GLOBAL.fonts.regular, color:this.state.style.color, fontSize:13, textAlign:'right', opacity:data.value_2?.opacity || 1}}>
          {data.value_2?.value}
          </Text>
        }
      </View>
    )
  }

  renderUserFeedback = (data) => {
    // return <UserFeedback data={data}/>
    return null
  }

  renderParagraph = (data, secondary) => {
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, marginBottom:GLOBAL.padding}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.regular, color:secondary ? this.state.style.color_secondary : this.state.style.color, fontSize:13, textAlign:'left', lineHeight:18}}>
        {data.text}
        </Text>
      </View>
    )
  }

  renderMatch = (data) => {
    return <Match data={data?.data} index={1}/>
  }

  renderPoll = (data) => {
    // var { choices, totalVotes, title, hasBeenVoted, votedChoiceById } = data.data
    // return <Poll data={data.data}/>
    return null
  }

  renderWebView = (data) => {
    var { url } = data
    return(
      <View style={{width:'100%', height:GLOBAL?.dimensions?.height, backgroundColor:this.state.style.backgroundColor}}>
        {
          // <WebView
          // source={{ uri: url }}
          // mediaPlaybackRequiresUserAction={true}
          // />
        }
      </View>
    )
  }

  renderVideoCarousel = (data) => {
    var sliderWidth = GLOBAL.dimensions.width
    var itemWidth = (sliderWidth - (GLOBAL.padding*2)) - GLOBAL.padding * 1
    return(
      <View style={[{width:'100%', paddingBottom:GLOBAL.padding}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {
          // <Carousel
          //   ref={(c) => { this._carousel = c; }}
          //   data={data.data}
          //   contentContainerCustomStyle={{marginLeft:GLOBAL.padding}}
          //   renderItem={({item, index})=>this.renderVideo(item, true)}
          //   sliderWidth={sliderWidth}
          //   itemWidth={itemWidth}
          //   enableMomentum={true}
          //   inactiveSlideScale={1}
          //   inactiveSlideOpacity={0.67}
          //   activeSlideAlignment='start'
          // />
        }
      </View>
    )
  }

  renderVideo = (data, paddingRight) => {
    var borderRadius = GLOBAL.padding / 4
    return(
      <Pressable onPress={()=>this.openVideo(data)} style={[{width:'100%'}, paddingRight ? {paddingRight:GLOBAL.padding/1.5} : null]}>
        <View style={[{width:'100%', borderRadius, backgroundColor:this.state.style.borderColor, overflow:'hidden'}, MainStyles.flexCenter]}>
          <Image
          style={[{width:'100%', aspectRatio:1920/1080}, MainStyles.flexCenter]}
          source={{uri:data?.image}}
          //resizeMode={Image.resizeMode.cover}
          />
          <View style={[{flex:1, width:'100%', padding:GLOBAL.padding / 2}]}>
            <Text style={{fontFamily:GLOBAL.fonts.medium, fontSize:12, color:this.state.style.color, textAlign:'left', flexWrap:'wrap'}} numberOfLines={2}>
            {data.title}
            </Text>
            <View style={[{flex:1, marginTop:GLOBAL.padding / 2}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
              <Image
              style={[{width:14, aspectRatio:1, borderRadius:100, marginRight:GLOBAL.padding / 2, backgroundColor:this.state.style.borderColor}, MainStyles.flexCenter]}
              source={{uri:data?.metadata?.image}}
              //resizeMode={Image.resizeMode.cover}
              />
              <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:11, color:this.state.style.color_secondary, textAlign:'left', flexWrap:'wrap'}} numberOfLines={1}>
              {data?.metadata?.text}
              </Text>
            </View>
          </View>
        </View>
      </Pressable>
    )
  }

  renderCTA = (data) => {
    var borderRadius = GLOBAL.padding / 2
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, marginBottom:data.marginBottom || 0}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        <Image
        style={[{width:'100%', aspectRatio:data.aspectRatio, borderRadius, backgroundColor:this.state.style.borderColor}, MainStyles.flexCenter]}
        source={{uri:data?.image}}
        //resizeMode={Image.resizeMode.cover}
        />
      </View>
    )
  }

  renderImageGrid = (data) => {
    return(
      <View style={[{width:'100%', paddingLeft:GLOBAL.padding, paddingRight:GLOBAL.padding/2, flexWrap:'wrap'}, MainStyles.flexStartStart, MainStyles.flexRow]}>
      {data.data.map((item, index) =>
        <View style={{maxWidth:'50%' || '100%', paddingRight:GLOBAL.padding/2 }}>
        {this.renderImage(item, index, 0.0000001)}
        </View>
      )}
      </View>
    )
  }

  renderImage = (data, index, paddingHorizontal) => {
    var aspectRatio = data.aspectRatio || 1.5
    var borderRadius = data.borderRadius || 4

    return(
      <Pressable onPress={()=>this.openImage(data.onPress)} style={{width:'100%', borderRadius, paddingHorizontal:paddingHorizontal || data.paddingHorizontal || GLOBAL.padding*1, marginBottom:GLOBAL.padding / 2, overflow:'hidden'}}>
      <Image
      style={[{width:'100%', aspectRatio, borderRadius, overflow:'hidden', backgroundColor:this.state.style.borderColor}, MainStyles.flexCenter]}
      source={{uri:data?.image}}
      //resizeMode={Image.resizeMode.cover}
      />
      {
        // !data.text ? null :
        // <View style={{position:'absolute', left:0, bottom:0, width:'100%'}}>
        //   <LinearGradient
        //   // colors={['red', 'green']}
        //   colors={["rgba(0,0,0,0.75)", "rgba(0,0,0,0)"]}
        //   start={{x:0, y:1}} end={{x:0, y:0}} style={{width:'100%', padding:GLOBAL.padding/2, paddingTop:GLOBAL.padding*1.5}}>
        //   <Text style={{fontFamily:GLOBAL.fonts.bold, fontSize:data.fontSize || 16, color:'white'}}>
        //   {data.text}
        //   </Text>
        //   </LinearGradient>
        // </View>
      }
      </Pressable>
    )
  }

  renderNews = (data) => {
    // console.log(data.onPress?.params?.showComments?.onPress)
    var width_small = 100
    var aspectRatio = 130/84
    var borderRadius = GLOBAL.padding / 4

    var comments = data.comments || 0

    var text_render =
    <View style={[{flex:1, width:'100%'}]}>
      <Text style={{fontFamily:GLOBAL.fonts.medium, lineHeight:20, fontSize:14, color:this.state.style.color, textAlign:'left', flexWrap:'wrap'}} numberOfLines={3}>
      {data.title}
      </Text>
      <View style={[{flex:1, marginTop:GLOBAL.padding / 2}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <View style={[{flex:1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          <Image
          style={[{width:14, aspectRatio:1, borderRadius:100, marginRight:GLOBAL.padding / 2, backgroundColor:this.state.style.borderColor}, MainStyles.flexCenter]}
          source={{uri:data?.metadata?.image}}
          //resizeMode={Image.resizeMode.cover}
          />
          <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:11, color:this.state.style.color_secondary, textAlign:'left', flexWrap:'wrap', flexShrink:1}} numberOfLines={1}>
          {data?.metadata?.text}
          </Text>
        </View>

        {
          !comments ? null :
          <View style={[{opacity:0.5}, MainStyles.flexRow, MainStyles.flexCenter]}>
            <Text style={{color:this.state.style.color, fontFamily:GLOBAL.fonts.medium, fontSize:11, marginRight:GLOBAL.padding / 4}}>
            {comments}
            </Text>
            <Icon icon='comments' size={14}/>
          </View>
        }

      </View>
    </View>

    if(data.headline_article){
      return(
        <View style={[{width:'100%', paddingHorizontal:0, marginVertical:GLOBAL.padding / 1.5}, MainStyles.flexStartStart]}>
          {
            <View style={[{width:'100%', aspectRatio:1}, MainStyles.flexCenter]}>
              <Image
              style={[{width:'100%', height:'100%', backgroundColor:this.state.style.borderColor}, MainStyles.flexCenter]}
              source={{uri:data?.image}}
              //resizeMode={Image.resizeMode.cover}
              />
              <View style={[{flex:1, maxWidth:'100%', padding:GLOBAL.padding, position:'absolute', bottom:0, left:0}, MainStyles.flexCenterStart]}>
                <Text style={{fontFamily:GLOBAL.fonts.black, fontSize:24, color:'white', flexWrap:'wrap', textTransform:'uppercase', textShadowOffset: { width: 0, height: 0 }, textShadowRadius: 4, textShadowColor: 'rgba(0,0,0,0.5)'}} numberOfLines={6}>
                {data.title}
                </Text>
              </View>
            </View>
          }
        </View>
      )
    }else{
      return(
        <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding * 0, paddingVertical:GLOBAL.padding / 1, borderBottomColor:GLOBAL.style.background_alternating, borderBottomWidth:1}, MainStyles.flexStartStart]}>
          {
            !data.featured ?
            <View style={[{width:'100%'}, MainStyles.flexRow, MainStyles.flexStartStart]}>
              <Image
              style={[{width:width_small, aspectRatio, borderRadius, backgroundColor:this.state.style.borderColor}, MainStyles.flexCenter]}
              source={{uri:data?.image}}
              //resizeMode={Image.resizeMode.cover}
              />
              <View style={[{flex:1, paddingLeft:GLOBAL.padding}, MainStyles.flexStartStart]}>
                {text_render}
              </View>
            </View>
            :
            <View style={[{width:'100%'}, MainStyles.flexCenter]}>
              <Image
              style={[{width:'100%', aspectRatio, borderRadius, marginBottom:GLOBAL.padding, backgroundColor:this.state.style.borderColor}, MainStyles.flexCenter]}
              source={{uri:data?.image}}
              //resizeMode={Image.resizeMode.cover}
              />
              {text_render}
            </View>
          }
        </View>
      )
    }


  }

  renderButtonMinor = (data) => {
    return(
      <View style={[{width:'100%', borderTopColor:this.state.style.borderColor, borderTopWidth:1, paddingVertical:GLOBAL.padding/1.25}, MainStyles.flexCenter, MainStyles.flexRow]}>
        <Text style={{color:this.state.style.color, fontFamily:GLOBAL.fonts.bold, fontSize:12, marginRight:GLOBAL.padding / 4}}>
        {data.title}
        </Text>
        <Icon icon='chevron-forward' size={12} opacity={0.5}/>
      </View>
    )
  }

  renderShorts = (data) => {
    var shorts = data.data || []

    var sliderWidth = GLOBAL.dimensions.width
    var itemWidth = 140 + GLOBAL.padding/2

    return(
      <View style={[{width:'100%', marginBottom:GLOBAL.padding}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {
          // <Carousel
          //   ref={(c) => { this._carousel = c; }}
          //   data={shorts}
          //   contentContainerCustomStyle={{marginLeft:GLOBAL.padding}}
          //   renderItem={this.renderShort}
          //   sliderWidth={sliderWidth}
          //   itemWidth={itemWidth}
          //   enableSnap={false}
          //   enableMomentum={true}
          //   inactiveSlideScale={1}
          //   inactiveSlideOpacity={1}
          //   activeSlideAlignment='start'
          // />
        }
      </View>
    )
  }

  renderShort = ({item, index}) => {
    var width = 140 + GLOBAL.padding/2
    var aspectRatio = 160/280
    var borderRadius = 4

    var icon_size = 32

    var position = GLOBAL.padding/2

    return(
      <View style={{width, paddingRight:GLOBAL.padding/2}}>
        <Pressable onPress={()=>this.openShorts(index)} style={[{width:'100%', aspectRatio, marginRight:GLOBAL.padding / 2, borderRadius, overflow:'hidden', backgroundColor:this.state.style.borderColor}, MainStyles.flexCenter]}>
          <Image
          style={[{width:'100%', height:'100%'}, MainStyles.flexCenter]}
          source={{uri:item?.image}}
          //resizeMode={Image.resizeMode.cover}
          />
          <Image
          style={[{width:icon_size, aspectRatio:1, position:'absolute', top:position, right:position, borderRadius:icon_size/2}, MainStyles.flexCenter]}
          source={{uri:item?.icon}}
          //resizeMode={Image.resizeMode.cover}
          />
          <View style={[{position:'absolute', bottom:0, left:0, width:'100%', padding:position}, MainStyles.flexCenterStart]}>
            <Text style={[{color:'white', fontFamily:GLOBAL.fonts.medium, fontSize:12, textAlign:'left'}, {
              textShadowOffset: { width: 0, height: 0 },
              textShadowRadius: 4,
              textShadowColor: 'rgba(0,0,0,0.8)',
            }]} numberOfLines={5}>
            {item.title}
            </Text>
          </View>
        </Pressable>
      </View>
    )
  }

  renderRankingsTable = (data) => {
    var rankings = data.data || []
    return(
      <View style={[{width:'100%', padding:0}, MainStyles.flexCenter]}>
      {this.renderRankingsHeader()}
        {rankings.map((item, index) => this.renderPlayer_ranking(item, index))}
      </View>
    )
  }

  renderButton = (data) => {
    return(
      <View style={{width:'100%', paddingHorizontal:GLOBAL.padding / 1, marginBottom:data.marginBottom || GLOBAL.padding}}>
      <Button secondary={data.secondary} disableOnPress title={data.title} backgroundColor={data.backgroundColor} color={data.color}/>
      </View>
    )
  }

  renderRankingsHeader = () => {
    var style = {fontFamily:GLOBAL.fonts.black, color:this.state.style.color, fontSize:12, textTransform:'none'}
    return(
      <View style={[{width:'100%', height:28, backgroundColor:this.state.style.borderColor, paddingHorizontal:GLOBAL.padding}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
        <Text style={[style, {width:50}]}>
        Rank
        </Text>

        <Text style={[style, {flex:1, paddingHorizontal:GLOBAL.padding}]}>
        Player
        </Text>

        <Text style={[style, {}]}>
        Points
        </Text>
      </View>
    )
  }

  renderPlayer = (data, index) => {
    return <Player data={data.data} index={1}/>
  }

  renderPlayer_ranking = (data, index) => {
    return <Player data={data} index={index}/>
  }

  renderDailyPicksCarousel = (data) => {
    var games = data.data || []
    return(
      <View style={{width:'100%'}}>
        <FlatList
          ref={ref => (this.dailyPicksFlatlist = ref)}
          horizontal
          data={games}
          style={{width:'100%'}}
          contentContainerStyle={{paddingLeft:GLOBAL.padding}}
          keyExtractor={(item, index) => index}
          renderItem={this.renderDailyPickGame}
          showsHorizontalScrollIndicator={false}
        />
      </View>
    )
  }

  renderDailyPickGame = ({item, index}) => {
    var data = item
    var onPress = data.onPress || null
    return(
      <Pressable onPress={()=>Functions.onPress(onPress)} style={{marginRight:GLOBAL.padding}}>
      <View style={[{width:220, padding:GLOBAL.padding, borderRadius:data?.style?.borderRadius || 4, backgroundColor:data.style?.backgroundColor || this.state.style.borderColor, marginBottom:GLOBAL.padding}, MainStyles.flexCenterStart]}>
        <View style={[{width:'100%'}, MainStyles.flexCenter, MainStyles.flexRow]}>
          <View style={[{flex:1}, MainStyles.flexCenterStart]}>
            <Text style={{color:data?.style?.color || this.state.style.color, fontSize:16, fontFamily:GLOBAL.fonts.black}}>
            {data.header?.title}
            </Text>
            <Text style={{color:data?.style?.color || this.state.style.color, fontSize:12, fontFamily:GLOBAL.fonts.medium, opacity:0.67, marginTop:GLOBAL.padding/4}}>
            {data.header?.subtitle}
            </Text>
          </View>

        </View>
        {
          !data?.button?.title ? null :
          <>
          <View style={[{width:'100%', height:1, backgroundColor:data.style?.color || this.state.style.color, opacity:0.08, marginVertical:GLOBAL.padding/1}, MainStyles.flexCenterStart]}/>
          <View style={[{flex:1, width:'100%'}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
            {data.info.map((item, index) => this.renderGameInfo(item, index, data.style))}
          </View>
          {
            !data?.button?.title ? null :
            <View style={[{marginTop:GLOBAL.padding,minWidth:60, height:36, paddingHorizontal:data?.button?.paddingHorizontal || GLOBAL.padding*0.8, borderRadius:data?.button?.borderRadius || 4, backgroundColor:data?.button?.backgroundColor || this.state.style.borderColor}, MainStyles.flexCenter]}>
              <Text style={{color:data?.button?.color||this.state.style.color, fontFamily:GLOBAL.fonts.black, fontSize:12, opacity:1}}>
              {data?.button?.title}
              </Text>
            </View>
          }
          </>
        }
      </View>
      </Pressable>
    )
  }

  renderFantasyBracketGame = (data) => {
    // console.log(data.onPress)
    // console.log(data.image)

    var show_notification = true

    return(
      <View style={{width:'100%', paddingHorizontal:GLOBAL.padding}}>
      <View style={[{width:'100%', padding:GLOBAL.padding, borderRadius:data?.style?.borderRadius || 4, backgroundColor:data.style?.backgroundColor || this.state.style.borderColor, marginBottom:GLOBAL.padding}, MainStyles.flexCenter]}>
        <View style={[{width:'100%'}, MainStyles.flexCenter, MainStyles.flexRow]}>
          <View style={[{flex:1}, MainStyles.flexCenterStart]}>
            <Text style={{color:data?.style?.color || this.state.style.color, lineHeight:24,fontSize:16, fontFamily:GLOBAL.fonts.black}}>
            {data.header?.title}
            </Text>
            <Text style={{color:data?.style?.color || this.state.style.color, fontSize:12, fontFamily:GLOBAL.fonts.medium, opacity:0.67, marginTop:GLOBAL.padding/4}}>
            {data.header?.subtitle}
            </Text>
          </View>

          {
            !data?.image?.url ? null :
            <View style={{width:GLOBAL.dimensions.width / 6, maxWidth:60, marginLeft:GLOBAL.padding}}>
              <Image
              style={[{width:'100%', aspectRatio:data?.image?.aspectRatio || 1}, MainStyles.flexCenter]}
              source={{uri:data?.image?.url}}
              //resizeMode={Image.resizeMode.contain}
              tintColor={data?.image?.tintColor}
              />
            </View>
          }

        </View>
        {
          !data?.button?.title ? null :
          <>
          <View style={{width:'100%', height:1, backgroundColor:data.style?.color || this.state.style.color, opacity:0.08, marginVertical:GLOBAL.padding/1}}/>
          <View style={[{width:'100%', marginTop:0}, MainStyles.flexCenterEnd, MainStyles.flexRow]}>
            <View style={[{flex:1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
              {data.info.map((item, index) => this.renderGameInfo(item, index, data.style))}
            </View>
            {
              !data?.button?.title ? null :
              <View style={[{marginLeft:GLOBAL.padding, minWidth:60, height:36, paddingHorizontal:data?.button?.paddingHorizontal || GLOBAL.padding*1, borderRadius:data?.button?.borderRadius || 4, backgroundColor:data?.button?.backgroundColor || this.state.style.borderColor}, MainStyles.flexCenter]}>
                <Text style={{color:data?.button?.color||this.state.style.color, fontFamily:GLOBAL.fonts.black, fontSize:12, opacity:1}}>
                {data?.button?.title}
                </Text>
              </View>
            }
          </View>
          </>
        }
      </View>
      </View>
    )
  }

  renderFantasyScores = (data) => {
    var scores = data?.data || []
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, marginBottom:data.marginBottom || GLOBAL.padding}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {scores.map((item, index) => this.renderFantasyScore(item, index))}
      </View>
    )
  }

  renderFantasyScore = (data, index) => {
    return(
      <View style={[{marginRight:GLOBAL.padding*2}, MainStyles.flexCenterStart]}>
        <Text style={{color:this.state.style.color, fontSize:18, fontFamily:GLOBAL.fonts.black}}>
        {data.value || "-"}
        </Text>
        <Text style={{color:this.state.style.color_secondary, fontSize:12, fontFamily:GLOBAL.fonts.regular}}>
        {data.title}
        </Text>
      </View>
    )
  }

  renderTable = (data) => {
    return(
      <View style={[{width:'100%', paddingHorizontal:0, marginBottom:GLOBAL.padding*0}, MainStyles.flexCenter]}>
        {this.renderNewTableHeader(data.title, data.subtitle)}
        {data.data.map((item, index) => this.renderTableRow(item, index))}
      </View>
    )
  }

  renderNewTableHeader = (title, subtitle) => {
    var style = {fontFamily:GLOBAL.fonts.black, color:this.state.style.header_color, fontSize:12, textTransform:'none'}
    return(
      <View style={[{width:'100%', height:28, backgroundColor:this.state.style.header_backgroundColor, paddingHorizontal:GLOBAL.padding, textTransform:'uppercase'}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
        <Text style={[style, {textAlign:'left', textTransform:"uppercase", flex:1}]}>
        {title}
        </Text>
        {
          !subtitle ? null :
          <Text style={[style, {paddingLeft:GLOBAL.padding, textAlign:'right', textTransform:"uppercase"}]}>
          {subtitle}
          </Text>
        }
      </View>
    )
  }

  renderTableRow = (data, index) => {
    var style = {fontSize:12, fontFamily:GLOBAL.fonts.regular, color:this.state.style.color}
    return(
      <Pressable onPress={!data.onPress ? null : ()=>Functions.onPress(data.onPress)} style={[{width:'100%', paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding/1, borderTopWidth:index ? 1 : 0, borderTopColor:this.state.style.borderColor}, MainStyles.flexStartCenter, MainStyles.flexRow]}>
        <Text style={[style, {flex:1, textAlign:'left', flexWrap:'wrap'}]}>
        {data.title}
        </Text>
        {data.values.map((item, index) => this.renderTableValue(item))}
        {
          // !data.onPress ? null :
          // <View style={{paddingLeft:GLOBAL.padding/2}}>
          // <Icon icon='chevron-forward' size={12} color={this.state.style.color_secondary}/>
          // </View>
        }
      </Pressable>
    )
  }

  renderTableValue = (data) => {
    var style = {fontSize:12, fontFamily:GLOBAL.fonts.regular, color:this.state.style.color}
    return(
      <View style={[{width:100, marginLeft:GLOBAL.padding}, MainStyles.flexCenterEnd]}>
        <Text style={[style]}>
        {data}
        </Text>
      </View>
    )
  }

  renderGameInfo = (data, index, style) => {
    return(
      <View style={[{marginRight:GLOBAL.padding}, MainStyles.flexCenterStart]}>
        <Text style={{color:style?.color || this.state.style.color, fontSize:data.title_fontSize || 14, fontFamily:GLOBAL.fonts.black}}>
        {data.title}
        </Text>
        <Text style={{color:style?.color || this.state.style.color, fontSize:12, fontFamily:GLOBAL.fonts.medium, opacity:0.67, marginTop:GLOBAL.padding/4}}>
        {data.subtitle}
        </Text>
      </View>
    )
  }

  renderFantasyLeague = (data) => {
    return(
      <View style={{width:'100%', paddingHorizontal:GLOBAL.padding*1}}>
        <View style={[{width:'100%', padding:GLOBAL.padding, borderRadius:data?.style?.borderRadius || 4, backgroundColor:data.style?.backgroundColor || this.state.style.background_alternating, marginBottom:GLOBAL.padding}, MainStyles.flexCenter]}>
          <View style={[{width:'100%'}, MainStyles.flexCenter, MainStyles.flexRow]}>
            <View style={[{flex:1}, MainStyles.flexCenterStart]}>
              <Text style={{color:data?.style?.color || this.state.style.color, fontSize:16, fontFamily:GLOBAL.fonts.black}}>
              {data?.title}
              </Text>
              <Text style={{color:data?.style?.color || this.state.style.color, fontSize:12, fontFamily:GLOBAL.fonts.medium, opacity:0.67, marginTop:GLOBAL.padding/4}}>
              {data?.subtitle}
              </Text>
            </View>

            {
              !data?.logo ? null :
              <View style={{width:GLOBAL.dimensions.width / 9, maxWidth:36, marginLeft:GLOBAL.padding}}>
                <Image
                style={[{width:'100%', aspectRatio:1}, MainStyles.flexCenter]}
                source={{uri:data?.logo}}
                //resizeMode={Image.resizeMode.contain}
                />
              </View>
            }

          </View>
          {
            (!data?.ranks || data?.ranks?.length === 0) ? null :
            <>
              <View style={{width:'100%', height:1, backgroundColor:data?.style?.color || this.state.style.color, opacity:0.08, marginVertical:GLOBAL.padding/1}}/>
              <View style={[{width:'100%', marginTop:0}, MainStyles.flexCenterEnd, MainStyles.flexRow]}>
                <View style={[{flex:1}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
                  {data?.ranks.map((item, index) => this.renderGameInfo(item, index))}
                </View>
                {
                  !data?.button?.title ? null :
                  <View style={[{marginLeft:GLOBAL.padding, minWidth:60, height:32, paddingHorizontal:data?.button?.paddingHorizontal || GLOBAL.padding/1.5, borderRadius:data?.button?.borderRadius || 4, backgroundColor:data?.button?.backgroundColor || this.state.style.borderColor}, MainStyles.flexCenter]}>
                    <Text style={{color:data?.button?.color||this.state.style.color, fontFamily:GLOBAL.fonts.black, fontSize:12, opacity:1}}>
                    {data?.button?.title}
                    </Text>
                  </View>
                }
              </View>
            </>
          }
        </View>
      </View>
    )
  }

  renderNotificationSwitch = (data) => {
    return(
      <View style={{width:'100%', paddingHorizontal:16, marginBottom:GLOBAL.padding}}>
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding*0, paddingVertical:GLOBAL.padding/2, backgroundColor:'transparent', borderRadius:4, borderWidth:0, borderColor:this.state.style.borderColor}, MainStyles.flexRow, MainStyles.flexBetweenCenter]}>
        <Text style={{fontFamily:GLOBAL.fonts.regular, fontSize:14, color:data.color || this.state.style.color, flexWrap:'wrap', flex:1, paddingRight:GLOBAL.padding}}>
        {data.title}
        </Text>
        <View style={{transform:[{scale:0.85}], marginRight:-2}}>
          <Switch
          trackColor={{ false: "#767577", true: GLOBAL.colors.teal }}
          thumbColor={"white"}
          ios_backgroundColor={GLOBAL.colors.borderColor}
          onValueChange={this.toggleNotifications}
          value={this.state.enabled}
          />
        </View>
      </View>
      </View>
    )
  }

  renderStatistics = (data) => {
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, marginTop:0}, MainStyles.flexCenterStart]}>
        {
          // <Text style={[{fontFamily:GLOBAL.fonts.bold, color:this.state.style.color, textAlign:'left', textTransform:"uppercase", flex:1}]}>
          // {data.title}
          // </Text>
        }
        <View style={[{width:'100%', flexWrap:'wrap', marginTop:0, marginBottom:GLOBAL.padding}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
        {data.data.map((item, index) => this.renderStatistic(item, index))}
        </View>
      </View>
    )
  }

  renderStatistic = (data, index) => {
    return(
      <View style={[{marginRight:GLOBAL.padding*2, marginTop:GLOBAL.padding}, MainStyles.flexCenterStart]}>
        <Text style={{color:this.state.style.color, fontFamily:GLOBAL.fonts.medium, fontSize:20}}>
        {data.value}
        </Text>
        <Text style={{color:this.state.style.color_secondary, fontFamily:GLOBAL.fonts.regular, fontSize:12, marginTop:GLOBAL.padding/4}}>
        {data.key}
        </Text>
      </View>
      )
  }

  renderMissing = (data) => {
    return(
      <Text style={{color:this.state.style.color_secondary, fontFamily:GLOBAL.fonts.regular, fontSize:12, paddingBottom:GLOBAL.padding}}>
      Update the app to access this new content {data.id}
      </Text>
    )
  }

  renderSearchHeading = (data) => {
    return(
      <View style={{width:'100%', paddingHorizontal:GLOBAL.padding, backgroundColor:GLOBAL.style.header_backgroundColor, paddingVertical:GLOBAL.padding/2, borderTopWidth:0, borderTopColor:GLOBAL.style.backgroundColor, marginTop:GLOBAL.padding}}>
        <Text style={{color:GLOBAL.style.header_color, fontSize:12, fontFamily:GLOBAL.fonts.black, textTransform:'uppercase', width:'100%', textAlign:'left'}}>
        {data.title}
        </Text>
      </View>
    )
  }

  renderSearchResult = (data) => {
    return(
      <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding, paddingVertical:GLOBAL.padding/1, borderBottomWidth:1, borderBottomColor:GLOBAL.style.background_alternating}, MainStyles.flexBetweenCenter, MainStyles.flexRow]}>
        <View style={[{flex:1}, MainStyles.flexCenterStart]}>
          <Text style={{fontSize:14, color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.regular, textAlign:'left'}}>
          {data.title}
          </Text>
          {
            !data.subtitle ? null :
            <Text style={{fontSize:12, color:GLOBAL.style.color_secondary, fontFamily:GLOBAL.fonts.regular, textAlign:'left', marginTop:GLOBAL.padding/4}}>
            {data.subtitle}
            </Text>
          }
        </View>

        {
          !data.image ? null :
          <Image
          style={[{width:40, aspectRatio:data.image.aspectRatio || 1.5, borderRadius:data.image.borderRadius || 4}, MainStyles.flexCenter]}
          source={{uri:data?.image?.url}}
          //resizeMode={data.image.contain ? Image.resizeMode.contain : Image.resizeMode.cover}
          />
        }
      </View>
    )
  }

  renderSearchEmpty = (data) => {
    return(
      <View style={[{width:'100%', flex:1, padding:GLOBAL.padding}, MainStyles.flexStartCenter]}>
        <Text style={{color:GLOBAL.style.color, fontSize:14, fontFamily:GLOBAL.fonts.bold, marginTop:GLOBAL.padding*3}}>
        {data.title}
        </Text>
        <Text style={{color:GLOBAL.style.color_secondary, fontSize:14, fontFamily:GLOBAL.fonts.regular, marginTop:GLOBAL.padding/2}}>
        {data.subtitle}
        </Text>
      </View>
    )
  }

  //
  render = () => {
    var { data } = this.state
    try{
      if(!data?.onPress){
        return(
          <View key={this.state.index} onPress={this.open} style={[{width:'100%'}, MainStyles.flexCenter]}>
            {
              this.renderInside()
            }
          </View>
        )
      }else{
        return(
          <Hoverable style={{width:'100%'}}>
          {({ hovered }) => (
            <Pressable key={this.state.index} onPress={this.open} style={[{width:'100%', backgroundColor:hovered ? GLOBAL.style.borderColor : 'transparent'}, MainStyles.flexCenter]}>
              {
                this.renderInside()
              }
            </Pressable>
          )}
          </Hoverable>
        )
      }
    }catch(e){
      console.log(e)
      return null
    }
  }

}
