/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Pressable,
  Image,
  SectionList,
} from 'react-native';

//Modules

//Components
import Loading from '../../components/general/loading.js'
import Tabs from '../../components/general/tabs.js'
import Player from '../../components/general/player.js'
import SectionHeader from '../../components/general/sectionheader.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      sections:[],
      loading:true,
      refreshing:true,
      format:'all',
    }
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {

  }

  setup = async (params) => {
    var { id, formats } = this.props
    if(this.state['format_'+params?.format]){
      var { formats, header, sections, format } = this.state['format_'+params.format]
      this.setState({formats:formats || this.props.formats || this.state.formats, header:header || null, sections, id, submode:this.props.submode, loading:false, refreshing:false, format})
    }else{
      this.setState({refreshing:true})

      if(!params){
        params = {}
      }

      params.id = id
      params.mode = 'tournament_players'
      // params.formats = this.props.formats || []

      var data_r = await Functions.tnnsAPI(params)
      var data = data_r?.data || {}
      var { formats, header, sections, format } = data
      this.setState({formats:formats || this.props.formats || this.state.formats, header:header || null, sections, id, submode:this.props.submode, loading:false, refreshing:false, format})
      this.setState({['format_'+format]:data})
    }
  }

  changeFormat = async (format_data) => {
    var format = format_data.id
    if(format !== this.state.format){
      await this.setState({format})
      this.setup({format})
    }
  }

  renderContent = () => {
    var { sections } = this.state
    return(
      <View style={{width:'100%', flex:1}} animation='fadeIn' duration={240}>
        <SectionList
          style={{width:'100%', flex:1}}
          contentContainerStyle={{width:'100%', paddingBottom:32}}
          sections={sections || []}
          keyExtractor={(item, index) => index}
          renderItem={this.renderItem}
          renderSectionHeader={this.renderSectionHeader}
          ListHeaderComponent={this.renderFormats}
        />
      </View>
    )
  }

  renderSectionHeader = ({section}) => {
    var { title } = section
    return(
      <SectionHeader title={title} />
    )
  }

  renderItem = ({item, index}) => {
    var backgroundColor = index%2 ? GLOBAL.style.background_alternating : 'transparent'


    return(
      <Player data={item} index={index}/>
    )
  }

  renderFormats = () => {
    var { formats } = this.state
    var tabs = formats || []

    return tabs.length > 0 ? <Tabs tabs={tabs} onChange={this.changeFormat} active_tab={this.state.format}/> : null
  }

  render = () => {
    var { loading } = this.state

    try{
      return (
        <View style={[{flex:1, width:'100%'}, MainStyles.flexStartCenter]}>
          {loading ? <Loading/> : this.renderContent()}
        </View>
      );
    }catch(e){
      return null
    }
  }

}
