/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  SectionList,
  Pressable,
  RefreshControl,
  Image,
} from 'react-native';

//Modules
// import { WebView } from 'react-native-webview';

//Components
import Icon from '../../components/general/icon.js'


//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      sections:[],
      loading:true,
      play:this.props.data?.play,
      data:this.props.data,
    }
  }

  componentDidMount = async () => {

  }

  componentDidUpdate = async (prevProps) => {
    if(prevProps.data !== this.props.data){
      this.setState({data:this.props.data})
    }
  }

  componentWillUnmount = async () => {

  }

  play = () => {
    var { data } = this.state
    var { webview, url } = data
    if(webview){
      //Trigger the webview to start playing the stream
      this.setState({play:true})
    }else{
      //Open the URL for the stream
      Functions.openVideo(data)
    }
  }

  renderVideo = () => {
    var { play, data } = this.state
    var width = GLOBAL.dimensions.width * 1
    var height = width / 1920 * 1080
    var size = 60
    return(
      <Pressable onPress={this.play} style={{width:'100%', height, backgroundColor:GLOBAL.style.borderColor}}>
      {
        play ?
        <View style={[{height:100, width:0, backgroundColor:GLOBAL.style.backgroundColor}, MainStyles.flexCenter]}>
        <Text>Stream</Text>
        </View>
        :
        <Image
        style={[{height, width}, MainStyles.flexCenter]}
        source={{uri:data?.thumbnail}}
        >
        <View style={[{height:size, width:size, borderRadius:100, backgroundColor:'rgba(0,0,0,0.75)'}, MainStyles.flexCenter]}>
          <Icon icon='play' color='white' size={size/2.5}/>
        </View>
        </Image>
      }
      </Pressable>
    )
  }

  render = () => {
    try{
      return this.renderVideo()
    }catch(e){
      return null
    }
  }

}
