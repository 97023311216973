/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  SectionList,
  Dimensions,
  TouchableOpacity,
  Image,
  ActivityIndicator
} from 'react-native';

//Modules


//Components
import Match from '../../components/matches/match.js'
import Tabs from '../../components/general/tabs.js'
import MatchList from '../../components/matches/matchlist.js'
import EmptyState from '../../components/general/emptystate.js'

//Helpers
import MainStyles from '../../helpers/mainstyles.js'
import Functions from '../../helpers/functions.js'
import GLOBAL from '../../helpers/global.js'

var moment = require('moment-timezone');
var CircularJSON = require('circular-json')

const dimensions = {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
}

export default class MyComponent extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      tournaments: [],
      activeView:GLOBAL.todayActiveTitle || "ATP",
    }
  }

  async componentDidMount(){
    setTimeout(async () => {
      this.getScheduledMatches_sr()
      GLOBAL.updateUpNext = this.setup
    }, 50)
  }

  async componentDidUpdate(prevProps){
    // if(CircularJSON.stringify(prevProps.data) !== CircularJSON.stringify(this.props.data)){
    //   this.setup()
    // }
    if(prevProps.appState === 'background' && this.props.appState === 'active'){
      this.getScheduledMatches_sr()
    }
  }

  getScheduledMatches_sr = async () => {
    var { activeView } = this.state

    var doc = 'scheduled'
    if(activeView === 'ATP'){
      doc = 'scheduled_atp'
    }
    if(activeView === 'WTA'){
      doc = 'scheduled_wta'
    }
    if(activeView === 'Challenger'){
      doc = 'scheduled_challenger'
    }
    if(activeView === 'ITF'){
      doc = 'scheduled_itf'
    }
    if(activeView === 'Other'){
      doc = 'scheduled_other'
    }

    // await firebase.firestore()
    // .collection('summaries_sr').doc(doc)
    // .get().then(this.handleScheduledMatches_sr)

    var url = "https://us-central1-tennis-lwts.cloudfunctions.net/api_getTourMatches?="+activeView.toLowerCase()+"?=scheduled?="+GLOBAL.matchCardStyle_scheduled
    var tournaments = await Functions.firestoreAPI(url)
    this.setState({tournaments, loading:false})
  }

  handleScheduledMatches_sr = async (doc) => {
    var scheduledMatches = {}

    //Get object from Snapshot
    scheduledMatches = doc.data().matches || {}

    // GLOBAL.scheduledMatches = scheduledMatches
    await this.setState({scheduledMatches})
    await this.setup()
  }

  setup = async () => {
    var matchesRaw = this.state.scheduledMatches || {}
    var tournamentsObj = {}
    var tournaments = []

    for (let [key, value] of Object.entries(matchesRaw)) {
      //Tournament category (e.g. ATP, ITF)
      var category = value.category
      var categoryRank = 10

      if(category === 'ATP'){categoryRank = 1}
      if(category === 'WTA'){categoryRank = 2}
      if(category === 'Challenger'){categoryRank = 3}
      if(category === 'ITF Men'){categoryRank = 4}
      if(category === 'ITF Women'){categoryRank = 5}

      //Add to obj
      if(!tournamentsObj[category]){
        //Get the ranking for tournament (ATP, WTA, Challenger, ITF), try get level as well
        //Setup
        tournamentsObj[category] = {
          title:'Next On Court',
          category,
          categoryRank,
          data:[],
        }
      }

      //Push match to data
      tournamentsObj[category].data.push(value)
    }

    //Within each tournament, sort matches by courtRanking and then push to final array
    for (let [key, value] of Object.entries(tournamentsObj)) {
      //Sort matches by start_time_timestamp
      await value.data.sort((a, b) => (a.start_time_timestamp > b.start_time_timestamp) ? 1 : -1)

      //Push to tournaments
      tournaments.push(value)
    }

    //Sort tournaments by categoryRank
    await tournaments.sort((a, b) => (a.categoryRank > b.categoryRank) ? 1 : -1)

    this.setState({tournaments, loading:false})
  }

  //Renders
  changeTab = async (activeView) => {
    GLOBAL.todayActiveTitle = activeView
    this.setState({loading:true})
    setTimeout(async () => {
      await this.setState({activeView})
      this.getScheduledMatches_sr()
    }, 50)
  }

  changeTabIndex = async (activeIndex) => {
    GLOBAL.todayActiveIndex = activeIndex
  }

  renderTabs = () => {
    var tabs = [
      {title:'ATP'},
      {title:'WTA'},
      {title:'Challenger'},
      {title:'ITF'},
      {title:'Other'},
    ]

    return <Tabs data={tabs} small lite onTapTitle={this.changeTab} onTapIndex={this.changeTabIndex} startingIndex={GLOBAL.todayActiveIndex}/>
  }

  renderCategories = () => {
    var { tournaments, activeView } = this.state

    if(tournaments[0]?.data?.length === 0 || tournaments?.length === 0){
      return <EmptyState text='No matches scheduled at the moment'/>
    }else{
      return <MatchList data={tournaments} mode='scheduled' TNNSPro={true}  showTournament enableCarousel={true}  small={false}/>
    }
  }


  render() {
    var { loading } = this.state
    return(
      <View style={{width:'100%', marginTop:0}}>
      {this.renderTabs()}
      {!loading ? this.renderCategories() : <ActivityIndicator size='small' color='white'/>}
      </View>
    )
  }

}
